@import 'src/tokens';
@import 'src/mixins';

.CreativePreview {
  $this: &;
  // add new styles to make the creative preview items the same width and height so the box would be stable, not jarring
  &__modal,
  .Modal__scrollable-content,
  &__image-wrap {
    position: relative;
  }
  &__modal {
    .Modal__scrollable-content {
      display: flex;
      padding: 1.25rem;
      gap: rem(20);
      max-width: rem(950);
      overflow-x: auto;
      img {
        height: auto;
        min-width: rem(160);
        max-width: rem(290);
      }
    }
    &:active,
    &:focus,
    &:hover {
      #{$this}__close-button {
        visibility: visible;
      }
    }
  }
  &__image-wrap {
    width: auto;
  }
  &__image {
    margin: 0;
  }
  &__full-image {
    max-width: 90vw;
    max-height: 80vh;
    object-fit: contain;
  }
  &__close-button {
    position: absolute;
    top: -16px;
    right: -16px;
    visibility: hidden;
  }
  &__dimensions {
    color: $base-text;
    padding: 0.5rem 0;
    text-align: center;
    width: 100%;
  }
}
