@import 'src/tokens';

$curve: cubic-bezier(0.65, 0, 0.45, 1);

.SuccessPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: clamp(30rem, 100%, 90vh);
  .Success__image,
  .Success__message,
  a {
    transition: all 0.5s ease-in-out;
  }
  .Success__image,
  .Success__message {
    animation-iteration-count: 1;
    opacity: 1;
  }
  .Success__image {
    animation-name: fadeInRight;
    animation-duration: 1.8s;
    margin-bottom: 8rem;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    transform: translate(0) scale(1);
    img {
      height: auto;
      width: 15rem;
    }
  }
  .Success__message {
    animation-duration: 0.8s;
    animation-name: fadeInLeft;
    color: $color-grey-200;
    margin-top: 0.5rem;
    transform: translate(0) scale(1);
    .Text {
      text-align: center;
    }
    h1.Text {
      color: $color-grey-200;
    }
  }
  p.Text {
    margin-top: 0.5rem;
    + .LinkBox {
      &.Box {
        margin-top: 1rem;
      }
    }
  }
  .LinkBox {
    .Box {
      padding: 0.5rem 1rem;
    }
    .LinkItem {
      font-size: 1.3rem;
      color: $color-primary;
      text-decoration: none;
      &.UpperCase {
        text-transform: uppercase;
      }
      &:hover {
        color: $color-primary-800;
      }
      &:focus {
        color: $color-primary-600;
      }
    }
  }
  .Checkmark {
    right: 0;
    top: 52%;
    animation: fill 0.5s ease-in-out forwards 1.4s,
      scale 0.6s ease-in-out forwards 1.9s;
  }
  .Checkmark__circle {
    animation: stroke 1.1s $curve forwards 1s;
  }
  .Checkmark__check {
    animation: stroke 1s $curve forwards 1.5s;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate(200%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(0) scale(1);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate(-200%) scale(0);
  }
  to {
    opacity: 1;
    transform: translate(0) scale(1);
  }
}
