@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

$headerfooter: 3.938rem;
$alertBanner: 5.125rem;
$fixedspace: calc(#{$headerfooter} * 2);
$sidebar-width-collapsed: 3.75rem;
$sidebar-width: 13.75rem;
$toggle-pos: calc(#{$sidebar-width} - 0.5rem);
// full content height minus header and footer
$content-only: calc(100vh - #{$fixedspace});

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translateX(-200%);
  }
  to {
    opacity: 1;
    transform: translateX(#{$toggle-pos});
  }
}

%scrollY {
  overflow-x: clip;
  overflow-y: auto;
  height: 100%;
}

.AuthorizedRoutesContent {
  overflow: clip;
}

.MainAppContainer {
  background-color: $base-gray;
  > .MaxHeightContainer__header,
  > .MaxHeightContainer__footer {
    background-color: $base-bg;
    position: fixed;
    height: $headerfooter;
    width: 100vw;
  }
  > .MaxHeightContainer__header {
    z-index: 5;
  }
  > .MaxHeightContainer__content {
    position: relative;
    z-index: 1;
  }
  > .MaxHeightContainer__footer {
    z-index: 1;
  }
  .MainAppContent {
    display: flex;
    bottom: 0;
    height: 100vh;
    overflow-x: clip;
    overflow-y: auto;
    padding-bottom: $headerfooter;
    padding-top: $headerfooter;
    top: 0;
  }
  .AuthorizedRoutesContainer {
    height: calc(100% - #{$alertBanner});
    &:only-child {
      height: 100%;
    }
  }

  %SideMenuHover {
    box-shadow: var(--box-shadow-500-shift-up);
    width: $sidebar-width;
    z-index: 3;
    .SideMenu__left-menu {
      border-color: transparent;
    }
  }
}

// wrapper container include sidemenu and main app content to allow sidemenu collapse/expand
.SideMainContent {
  .SideMenuItem {
    margin-inline: 0.25rem;
    width: calc(100% - 0.5rem);
  }
  .SideMenu__left-menu {
    row-gap: 0.25rem;
    &-wrap {
      position: relative;
      .SideMenuOrgDetails {
        min-width: $sidebar-width-collapsed;
      }
    }
  }
  .SideMenuToggle {
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 6.25rem;
    transform: translateX(-200%);
    z-index: 20;
    .SideMenuToggle__icon,
    .fa {
      color: $color-primary;
      margin: 0;
      padding: 0;
      &::before {
        transform: scale(0.9);
      }
    }
    &::after,
    &::before {
      visibility: hidden;
    }
    &:active,
    &:focus,
    &:hover {
      .SideMenuToggle__icon,
      .fa {
        color: $base-bg;
      }
    }
  }
  .SideMenu__left-menu,
  .AuthorizedRoutes__main,
  .fa,
  .SideMenu,
  .SideMenuHeader__title,
  .SideMenuOrgDetails,
  .SideMenuOrgDetails__main,
  .SideMenuOrgDetails_logo-orgname-cat,
  .SideMenuItem__label,
  .SideMenuToggle {
    transition: all 0.45s ease;
  }
  .AuhorizedRoutes__left-menu {
    transition: border 0.1s ease;
  }
  .SideMenuOrgDetails__main-txt {
    text-align: left;
  }
  .SideMenu,
  .SideMenu__left-menu {
    min-width: $sidebar-width-collapsed;
    width: $sidebar-width-collapsed;
  }
  .SideMenu {
    background-color: $base-bg;
    box-shadow: $box-shadow-100-border;
    position: fixed;
    height: 100%;
    top: $headerfooter;
    z-index: 1;
    .SideMenu__left-menu {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-height: calc(100vh - (#{$headerfooter} * 2));
      .LoadingBar {
        max-width: 70%;
        width: 5vw;
      }
    }
    .IconOnCollapsed {
      display: none;
      justify-content: center;
      margin: 0 auto;
      opacity: 0;
      svg {
        max-height: 4rem;
      }
    }
  }
  .SideMenuHeader {
    min-height: 2.5rem;
    padding-bottom: 0.125rem;
    .SideMenuHeader__title,
    .SideMenuHeader__icon {
      align-items: center;
      display: flex;
      height: 2.125rem;
      padding: 0;
      pointer-events: none;
    }
  }
  .AuthorizedRoutes__main {
    position: relative;
    padding-left: $sidebar-width;
    z-index: 0;
  }
  .AuthorizedRoutesContent > .PageContent,
  .DocumentationPage {
    @extend %scrollY;
  }
  .DocumentationPage {
    padding-right: 3.9vw;
    .swagger-ui {
      .scheme-container,
      section.models .model-container,
      .markdown code {
        background-color: $tint-gray-100;
      }
      .markdown code,
      .renderedMarkdown code {
        color: $color-success-500;
      }
      .property.primitive {
        color: $tint-gray-600;
      }
      .info .title small {
        background-color: $color-success-500;
        padding-inline: 0.5rem 0.65rem;
      }
      .opblock .opblock-section-header,
      .operation-tag-content {
        background-color: $tint-light-100;
      }
      .opblock .opblock-section-header,
      .operation-tag-content,
      h1,
      h2,
      h3,
      h4,
      .title,
      .btn,
      p,
      .tab,
      button.tablinks,
      .tab li,
      .response-col_status,
      .info {
        color: $base-text;
      }
      svg {
        fill: $base-text;
      }
      .btn {
        background-color: $tint-khaki;
        border-color: $tint-khaki;
        &:hover {
          background-color: $base-info;
          border-color: $base-info;
        }
      }
      .info .base-url {
        color: $base-info-400;
        font-size: $text-size-l;
      }
      input,
      select,
      textarea,
      .dialog-ux .modal-ux {
        background-color: $base-bg;
      }
      .modal-ux-inner,
      .auth-container,
      .modal-ux-content,
      .modal-dialog-ux {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
      .dialog-ux .modal-ux {
        min-height: 55vh;
      }
      .prop-type {
        color: $color-success-500;
      }
      .title small {
        color: $color-success-500;
      }
      .dialog-ux {
        .modal-ux-header,
        .modal-ux {
          border-color: $tint-gray-500;
        }
      }
      section.models,
      .opblock-tag {
        background-color: $tint-light-050;
        border-color: $tint-gray-500;
      }
      .opblock .opblock-summary .view-line-link.copy-to-clipboard {
        margin-inline: 4px;
        width: 26px;
        &:hover {
          background-color: $base-info;
        }
      }
    }
    * {
      font-size: 100%;
      color: $base-text;
      &:not(code) {
        font-family: 'Source Sans 3', sans-serif;
        line-height: 1.3;
      }
    }
    .base-url {
      padding-block: 0.25rem;
    }
    .main h2 {
      font-size: 3rem;
      font-weight: 900;
    }
    h1,
    h2 {
      margin-bottom: 0.125rem;
      padding-top: 1rem;
    }
    h1 {
      font-size: 2rem;
      font-weight: 900;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  .AuthorizedRoutesContent {
    > .PageContent {
      .MainWithSide {
        align-items: center;
        overflow: clip;
      }
      &.AudienceLibraryPage,
      &.CreativesLibraryPage {
        padding-top: 0;
        padding-left: 2.5vw;
        padding-right: 0;
        > .MainWithSide > .MainWithSide__main {
          padding-bottom: 3.5vh;
          padding-right: 3vw;
        }
      }
      &:not(.Dashboard) {
        width: clamp(
          calc(100vw - 3.45rem),
          calc(100vw - 5.5rem),
          calc(100vw - 4.125rem)
        );
      }
      &.AudienceUpload {
        width: clamp(
          calc(100vw - 3.45rem),
          calc(100vw - 4rem),
          calc(100vw - 4.125rem)
        );
      }
      .OrderlineTable__table-wrapper {
        padding-bottom: 1rem;
      }
    }
  }
  &.Hover__expanded,
  &.SideMenu__expanded:not(.SideMainContent__mobile),
  .SideMenu__left-menu-wrap:hover {
    .SideMenuHeader__title {
      padding-left: 1rem;
    }
    .SideMenuOrgDetails_logo-orgname-cat {
      padding-left: 0.75rem;
    }
    .SideMenuToggle {
      animation: fadeRight 0.35s ease;
      animation-delay: 250ms;
      transform: translateX(#{$toggle-pos});
    }
  }
  &.Hover__expanded,
  &.SideMenu__expanded:not(.SideMainContent__mobile) .SideMenu:hover,
  .SideMenu__left-menu-wrap:hover {
    .SideMenuToggle {
      opacity: 1;
    }
  }
  &.SideMenu__expanded:not(.SideMainContent__mobile) {
    .SideMenuToggle {
      transform: translateX(#{$toggle-pos});
    }
  }
  &.SideMenu__expanded:not(.SideMainContent__mobile) {
    .SideMenuOrgDetails,
    .SideMenu,
    .SideMenu__left-menu {
      width: $sidebar-width;
    }
    .AuthorizedRoutesContent > .PageContent {
      width: clamp(
        calc(100vw - 14rem),
        calc(100vw - 14.25rem),
        calc(100vw - 14.75rem)
      );
      &.Dashboard {
        width: clamp(
          calc(100vw - 13.95rem),
          calc(100vw - 14.25rem),
          calc(100vw - 14.75rem)
        );
      }
      &.AudienceUpload {
        padding-right: 4.75vw;
      }
      &.CreateCampaignPage {
        padding-right: 1rem;
      }
    }
  }
  &.SideMainContent__mobile,
  &.SideMenu__collapsed {
    .SideMenu__left-menu {
      align-items: center;
    }
    .AuthorizedRoutes__main {
      padding-left: $sidebar-width-collapsed;
    }
    .Avatar {
      margin-left: -1px;
      transform: scale(0.88);
    }
    .SideMenu .SideMenuItem > .fa {
      margin-inline: auto;
      transform: scale(1.25);
    }
    .SideMenuOrgDetails__main,
    .SideMenuItem__label {
      display: none;
      transform: scale(0);
      opacity: 0;
    }
    .SideMenuOrgDetails__menu-button .SideMenuItem > .fa,
    .SideMenuHeader__title {
      display: none;
    }
    .IconOnCollapsed {
      display: flex;
      opacity: 1;
    }
    .SideMenuItem {
      max-width: 2.625rem;
    }
    .SideMenuOrgDetails_logo-orgname-cat {
      align-items: center;
      justify-content: center;
    }
    .SideMenuToggle .Button__icon-wrapper {
      transform: rotate(180deg);
      z-index: 2;
      &::before {
        height: auto;
        transform: translateY(50%) rotate(180deg);
      }
    }
    .fa-ellipsis-v {
      display: none;
      visibility: hidden;
    }
    .AuthorizedRoutesContent > .PageContent {
      &.Dashboard {
        padding-left: 2.6vw;
        padding-right: 2.3vw;
        width: clamp(
          calc(100vw - 3.95rem),
          calc(100vw - 4.5rem),
          calc(100vw - 4.85rem)
        );
      }
    }

    // show sidemenu text elements on hover
    &.Hover__expanded,
    .SideMenu__left-menu-wrap:hover {
      .SideMenuToggle .Button__icon-wrapper,
      .fa-ellipsis-v {
        visibility: visible;
      }
      .fa-ellipsis-v {
        display: flex;
      }
      .SideMenuItem {
        padding-left: 1.25rem;
        max-width: none;
      }
      .SideMenu {
        @extend %SideMenuHover;
        .Theme__dark &,
        .Theme__darkBlue & {
          filter: drop-shadow(1px 1px 8px hsla(0, 0%, 0%, 0.25))
            drop-shadow(1px 1px 16px hsla(0, 0%, 0%, 0.55));
          outline: 1px solid hsla(0, 0%, 75%, 0.25);
        }
      }
      .SideMenuOrgDetails,
      .SideMenu,
      .SideMenu__left-menu {
        min-width: $sidebar-width;
        width: $sidebar-width;
      }
      .SideMenu__left-menu {
        align-items: flex-start;
        border-color: transparent;
      }

      // popover moves the menu off position when hover collapsed
      .fa {
        display: flex;
      }
      .IconOnCollapsed {
        display: none;
      }
      .Avatar,
      .SideMenuItem > .fa {
        transform: scale(1);
      }
      .SideMenuItem > .fa {
        margin-inline: 0;
      }
      .SideMenuHeader__title {
        font-size: $text-size-ml;
        display: flex;
        opacity: 1;
      }
      .SideMenuOrgDetails__main,
      .SideMenuItem__label,
      .fa-ellipsis-v {
        @include animate-css(fadeInRight, $duration: 160ms, $delay: 200ms);
      }
      .SideMenuOrgDetails__main,
      .SideMenuItem__label {
        display: flex;
        transform: scale(1);
        opacity: 1;
      }
    }

    @media screen and (min-width: 480px) {
      .AuthorizedRoutesContent > {
        .PageContent {
          &.CreateCampaignPage {
            width: clamp(
              calc(100vw - 3.125rem),
              calc(100vw - 3.5rem),
              calc(100vw - 3.125rem)
            );
          }
        }
      }
    }
  }
}

.CreativeGen-Sidebar__body {
  @include animate-css(slideInLeft);
  background-color: $tint-light-700;
  box-shadow: $box-shadow-300;
  padding-bottom: 1rem;
  padding-top: 1.75rem;
  padding-inline: 1.5rem;
  z-index: 0;
  .Theme__dark &,
  .Theme__darkBlue & {
    background-color: $tint-dark-700;
  }
}

.CreateCampaignPage__buttons-modal {
  background: #fff1;
  height: $headerfooter;
}

@media screen and (max-width: 500px) {
  .SideMenuOrgDetail__dropdown {
    top: auto !important;
    margin-top: 1.25rem;
    left: 25vw !important;
  }
}
// todo popover's refactor end
.SideMainContent__mobile,
.SideMainContent__tablet {
  svg.ETIcon:not(.ETIcon--full) {
    max-width: clamp(1.7rem, 3vw, 6rem);
  }
}

.SideMainContent__mobile,
.SideMainContent__tablet .CreateCampaignPage {
  .CreateCampaignPage__stepper-button:not(.is-selected):not(:hover) {
    gap: 0;
  }
  .OptionalFilter:not(.is-selected) .OptionalFilterLabel,
  .CreateCampaignPage__stepper-button:disabled:hover .StepButtonText,
  .CreateCampaignPage__stepper-button:disabled
    .FaCircle:hover
    + .StepButtonText,
  .CreateCampaignPage__stepper-button:not(.is-selected):not(:hover)
    .StepButtonText {
    transform: scaleX(0);
    width: 0;
  }
  .OptionalFilterLabel {
    white-space: nowrap;
  }
  .OptionalFilters {
    #tw & {
      gap: 0;
    }
    .OptionalFilter {
      padding-right: 1.9vw;
    }
    .OptionalFilter {
      &:hover,
      &:focus,
      &:active,
      &.is-selected {
        i {
          + .OptionalFilterLabel {
            transform: scaleX(1);
            visibility: visible;
            width: auto;
          }
        }
      }
    }
  }
}

#tw {
  .SideMainContent__mobile {
    .SideMenuToggle,
    .CampaignHeader--text .Button__text {
      display: none;
    }
    .Button {
      &.CampaignHeader--text {
        min-width: unset;
        padding-inline: 0;
      }
    }
  }
  .Tabs .BannerGenerator__button {
    border: none;
    gap: 0;
    margin: 0 0 0 auto;
    max-width: 23%;
    min-width: 12.5rem;
    position: relative;
    height: auto;
    right: 0;
    top: 0;
    width: auto;
  }
  .MainAppContainer__desktop,
  .MainAppContainer__tabletL {
    .IconButton.BannerGenerator__button {
      position: absolute;
    }
    .BannerAdForm {
      .Tab__nav {
        padding-right: 13.5rem;
      }
      .Tab-GetFromLibrary .Tab__label {
        padding: 0;
      }
      .Tab-GetFromLibrary {
        margin-right: 1vw;
      }
    }
  }
  .MainAppContainer__tablet,
  .MainAppContainer__tablet {
    .AdSetDisplay__BannerPreview__Header {
      flex-direction: column;
      gap: 0;
    }
    .AdSetDisplay__BannerPreview__Info {
      padding-bottom: 0.5rem;
    }
    .Tab-GetFromLibrary {
      margin-right: auto;
      position: relative;
      z-index: 20;
    }
  }
  .MainAppContainer__landscape {
    .CampaignSplash_Header-text {
      margin: 0;
    }
    .CreateCampaignPage .CampaignSplash_Campaign-Buttons {
      margin: 0;
    }
  }
}
