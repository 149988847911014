@import 'tokens';
@import 'mixins';

.SelectCountry {
  background-color: $base-bg;
  border-radius: var(--border-radius-m);
  appearance: none;
  outline: none;
  &:active {
    box-shadow: $focus-ring;
  }
  &:focus-within {
    box-shadow: $focus-ring;
    &:active {
      box-shadow: $focus-ring;
    }
  }
}
