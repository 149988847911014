@import 'src/tokens';
@import 'src/mixins';

.CreativeUploader {
  .CreativeUploader__ContentTitle-button,
  .Uploader {
    transition: all 0.5s ease-in-out;
  }
  &__Requirements {
    visibility: visible;
    &__UploadText {
      color: $color-grey-400;
      font-size: $text-size-s;
      opacity: 1;
      pointer-events: none;
      position: relative;
      line-height: 1.1;
      text-align: center;
    }
    .BannerForm__Buttons &,
    .VideoForm__Buttons & {
      .fa-ul,
      .fa-ul li {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
      }
      .fa-ul {
        column-gap: 1.5rem;
        li {
          font-size: $text-size-ml;
          font-weight: $text-weight-light;
          &:not(:last-child) {
            &::after {
              content: ', ';
            }
          }
        }
      }
      h6 {
        padding: 0.5rem 0 0.125rem !important;
      }
    }
    &--OTTtext,
    &--HTML5Text,
    &--BannerText {
      ul.fa-ul {
        margin: 0;
      }
    }
    &--OTTtext {
      padding-inline: 1.5rem;
      .fa-li {
        display: none;
      }
      ul.fa-ul {
        padding: 0;
      }
    }
    &--HTML5Text {
      padding-block: 0.5rem;
    }
  }
  &__ValidationError {
    transition: all 0.5s ease-in-out;
    background-color: $color-danger-050;
  }
}

div:not([class*='--disabled']) {
  .Uploader {
    transition: all 0.45s ease-in-out;
    &:hover {
      background-color: $color-primary-050;
      border: 4px dashed $color-primary-200;
    }
  }
}

#tw {
  div[class*='--disabled'] {
    .Uploader {
      background-color: transparent;
    }
  }
}
