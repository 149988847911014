@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

$headerfooter: 3.938rem;
$fixedspace: calc(#{$headerfooter} * 2);
$content-only: calc(100vh - #{$fixedspace});

#tw {
  .OrgSelect {
    display: flex;
    flex-direction: column;
    width: 17rem;
    max-height: $content-only;
    overflow-y: auto;
    &__org-list {
      margin-top: 0.2rem;
      .Pagination {
        background-color: $color-primary-100;
        margin-top: 1rem;
        padding-block: 0.65rem 1rem;
        .Button--disabled {
          visibility: hidden;
        }
      }
      .OrgSelect__org-button {
        @extend %text-color-states;
        align-items: center;
        column-gap: 0.75rem;
        display: flex;
        padding-left: 1.125rem;
        &:hover {
          padding-left: 1.125rem;
        }
        &.OrgSelect__org-button--active {
          @extend %text-color-hover;
        }
        .Avatar {
          border: none;
          font-size: $text-size-xs;
        }
      }
    }
    &__loading {
      background-color: $base-bg;
    }
    &__search {
      .TextInput__input {
        padding-right: 2rem;
      }
      button {
        left: 81%;
        position: absolute;
      }
    }
  }
  .MainAppContainer__mobile {
    .OrgSelect {
      max-height: 55vh;
    }
  }
}

@media (max-height: 774px) {
  #tw {
    .OrgSelect {
      max-height: 60vh;
    }
  }
}
