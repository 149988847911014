@import 'src/tokens';
@import 'src/mixins';

$curve: cubic-bezier(0.65, 0, 0.45, 1);

.Notification {
  display: flex;
  align-items: center;
  gap: 2vh;
  justify-content: center;
  padding: rem(20) 0;
  height: clamp(30rem, 100%, 90vh);
  .Card--simple & {
    height: auto;
  }
  &__column {
    flex-direction: column;
    .Notification__message {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
  .Notification__image {
    display: flex;
    flex-direction: column;
  }
  .Notification__message {
    max-width: 75%;
    .TextHeader {
      padding-bottom: 0.25rem;
    }
  }
  &__image,
  &__message,
  a {
    transition: all 0.5s ease-in-out;
  }
  &__image,
  &__message {
    animation-iteration-count: 1;
    opacity: 1;
  }
  &__image {
    @include animate-css(zoomInRight, $duration: 1s);
    margin: 0;
    max-width: 13rem;
    transform: translate(0) scale(1);
    img {
      height: auto;
      width: 100%;
    }
  }
  &__message {
    @include animate-css(bounceInLeft, $duration: 0.75s);
    margin-top: 0.5rem;
    transform: translate(0) scale(1);
  }
  p {
    + .LinkBox {
      &.Box {
        margin-top: 1rem;
      }
    }
  }
  &__buttons {
    display: flex;
    align-items: flex-end;
    gap: rem(12);
    margin-top: auto;
    padding: rem(15) 0;
  }
  .LinkBox {
    .Box {
      padding: 0.5rem 1rem;
    }
    .LinkItem {
      font-size: rem(14);
      color: $color-primary;
      text-decoration: none;
      &.UpperCase {
        text-transform: uppercase;
      }
      &:hover {
        color: $color-primary-800;
      }
      &:focus {
        color: $color-primary-600;
      }
    }
  }
  .Checkmark {
    right: 2%;
    top: 50%;
    animation: fill 0.5s ease-in-out forwards 1s,
      scale 0.6s ease-in-out forwards 1.5s;
  }
  .Checkmark__circle {
    animation: stroke 1.1s $curve forwards 1s;
  }
  .Checkmark__check {
    animation: stroke 1s $curve forwards 1.5s;
  }
  &[class*='Icon-'] {
    .fa {
      color: $color-grey-300;
      display: inline-block;
      margin: auto;
    }
  }
}

.Card--confetti {
  background-color: $primer;
  .Notification {
    gap: 1vh;
    &__column {
      .MessageBody {
        p {
          font-size: $text-size-xl;
          padding-inline: 2rem;
        }
      }
    }
    .Notification__message {
      max-width: 50%;
    }
    &__image,
    &__message,
    a {
      transition: all 0.75s ease-in-out;
    }
    &__image {
      @include animate-css(zoomInRight, $duration: 2s);
      max-width: 20rem;
    }
    &__message {
      @include animate-css(bounceInLeft, $duration: 2.5s);
    }
    .Checkmark {
      right: 2.5%;
      top: 59%;
      animation: fill 0.75s ease-in-out forwards 1s,
        scale 0.6s ease-in-out forwards 1.5s;
    }
  }
}
