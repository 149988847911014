@import 'src/tokens';
@import 'src/mixins';

.ServeStatsTable {
  td,
  th {
    border-right: $border-width-thin solid transparent;
    text-align: left;
    padding-block: 0.05rem;
    padding-inline: 0.45rem;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  #tw & thead {
    border-bottom: 1px solid $color-grey-100;
    tr {
      td,
      th {
        background-color: $tint-khaki-100;
        border-color: $base-bg;
        padding-bottom: 0.3rem;
        padding-top: 0.38rem;
      }
    }
  }
  tr {
    th {
      font-size: $text-size-m;
      font-weight: $text-weight-bold;
    }
    &:nth-child(odd) {
      td,
      th {
        border-color: $base-gray;
      }
    }
    &:nth-child(even) {
      td,
      th {
        background-color: $base-gray;
        border-color: $base-bg;
      }
    }
  }
  &__movement-indicator {
    padding: 0 0.2rem;
    display: flex;
    align-items: center;
    align-content: center;
    font-size: $text-size-s;
    font-weight: $text-weight-bold;
    .fa {
      padding-top: 0.1rem;
      margin-right: 0.2rem;
      color: inherit;
    }
    &--orange {
      color: #ff8c19;
      background-color: #fdf9f4;
      border: 1px solid #ff8c19;

      @include animate-css(fadeInDown, 0.6s);
    }
    &--blue {
      color: $base-info-500;
      background-color: $base-info-050;
      border: 1px solid $base-info-500;

      @include animate-css(fadeInUp, 0.6s);
    }
  }
}
