@import 'tokens.scss';
@import 'src/mixins';

$height: 1320px;
$width: 1020px;

#tw {
  #MainAppContainer {
    // using px rather than rem so px are not converted into decimals in pdf
    .Modal {
      .flightDates-campaignInfo,
      .progressBar-flightDates {
        align-items: center;
        justify-content: center;
      }
      .progressBar-flightDates {
        display: flex;
        flex-direction: column;
        min-width: 60%;
        max-width: $width;
      }
      .ReportModal__modal-container {
        min-width: $width;
        padding: 0;
        width: $width;
      }
      .ReportModal__modal-content {
        height: 100%;
        max-height: $height;
        max-width: $width;
        min-height: calc(100vh - 5rem);
        min-width: $width;
        padding: 0;
        position: relative;
        width: $width;
        .HighestPerformingCreative-data,
        .basicData-info {
          display: flex;
          flex-direction: column;
          > * {
            padding-bottom: 2px;
            padding-top: 2px;
            width: 100%;
          }
        }
        .ReportModal__content-wrap--pdf,
        .ReportModal__content-wrap--online {
          min-height: 1240px;
        }
        .ReportModal__content-wrap--pdf {
          background-color: #fff;
        }
        .ReportModal__content-wrap--online {
          background-color: $primer;
          padding: 2vh 1.825vw 3.125vh;
          position: relative;
          z-index: 100;

          img {
            margin-inline: auto;
            max-height: 200px; // prevent tall skyscraper ads from pushing content under fold
          }
        }
      }

      .ReportModal__content-wrap--online {
        background-color: $primer;
      }
      .ReportModal {
        &__loadingBlocker {
          cursor: wait;
          * {
            pointer-events: none !important;
          }
        }
        .DownloadButton,
        &__Loading-container,
        &__Loading-container svg {
          opacity: 1;
        }
        &__headerOptions {
          .DownloadButton {
            background-color: $tint-khaki-200;
            border-color: $tint-khaki-200;
            border-radius: $border-radius-2xl;
            color: $base-text;
            opacity: 1;
            transition: all 0.5s ease-in-out;
            &:hover {
              background-color: $color-primary-500;
              border-color: $color-primary-500;
              border-radius: $border-radius-2xl;
              color: $tint-light;
              opacity: 1;
            }
            &:disabled {
              background-color: $color-success-100;
              border-color: $color-success-100;
              border-radius: $border-radius-2xl;
              color: $color-success-800;
              opacity: 1;
              &:hover {
                background-color: $color-success-100;
                border-color: $color-success-100;
                border-radius: $border-radius-2xl;
                color: $color-success-800;
                opacity: 1;
              }
            }
            .LoadingIcons {
              color: $color-success-900;
              opacity: 1;
              transform: scale(2.75);
            }
          }
        }
      }
      .DailyBreakdown__controls {
        padding-top: 18px;
      }
    }

    // styles targeting PDF only
    .ExportToPrint,
    .text-pdf {
      div,
      span,
      text {
        color: #000;
      }
    }
    .ExportToPrint {
      background-color: #fff;
      margin: 0;
      padding: 15px 30px 25px;
      max-height: 100%;
      min-height: $height;
      max-width: 100%;
      min-width: $width;
      height: 100%;
      width: 100%;
      .Video-thumb {
        align-items: center;
        border-radius: 2pt;
        display: flex;
        background: $tint-dark-600;
        justify-content: center;
        margin-top: -12px;
        min-height: 45px;
        height: 100%;
        &-icon {
          color: $tint-khaki-300;
          margin-top: -5px;
        }
      }
      // .ExportToPrint__content {
      //   min-height: $height;
      //   min-width: $width;
      // }

      // styles to show elements' class with purple outline
      // * {
      //   border: 1px solid purple;
      //   &::before {
      //     align-items: center;
      //     position: absolute;
      //     content: attr(class);
      //     color: darkmagenta;
      //     text-align: center;
      //     font-size: 8px;
      //     line-height: 1;
      //     top: 0;
      //     bottom: 0;
      //     z-index: 110;
      //   }
      // }
      .Export2Print {
        &:not(.ExportToPrint__header) {
          min-height: 1225px;
        }
        min-width: 960px;
        width: 100%;
        height: 100%;
      }
      .start-flightDates,
      .stop-flightDates,
      .DaysRemain {
        position: relative;
        top: -5px;
      }
      .stop-flightDates {
        right: 0;
      }
      .ExportToPrint__header {
        align-items: flex-end;
        margin-bottom: 5px;
        max-width: 100%;
        min-width: 100%;
        padding-bottom: 5px;
      }
      .TopLevelStat {
        align-items: flex-start;
        padding-bottom: 18px;
      }
      .week-content {
        .upperStats-container {
          min-width: 90px;
          width: 100%;
        }
        .DayContainer__clicks {
          .container-flexContainer {
            background-color: $tint-dark-100;
          }
        }
        .DayContainer__impressions {
          .container-flexContainer {
            background-color: $tint-khaki-100;
          }
        }
        .DayContainer__ctr {
          .container-flexContainer {
            background-color: $base-info-400;
            * {
              color: #fff;
            }
          }
        }
      }
      .ReportModal__content-wrap--pdf {
        position: absolute;
        top: 0;
        flex: 1 100%;
        margin: auto;
        min-width: 960px;
        max-width: 100%;
        width: 100%;
        .ReportModal__report--pdf {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .DaysRemain,
        .FlightDate {
          padding-block: 8px 18px;
        }
        .TableText,
        .Creatives.isPDF *,
        .CreativesDisplay__gallery *,
        .title-HighestPerformingCreative,
        .Creative__stats.isPDF {
          font-size: 12px;
        }
        .TableText {
          padding-block: 4px 8px;
        }
        .status-campaignInfo {
          padding-top: 0;
          .fa {
            transform: scale(0.75);
          }
          .OrderlineStateIndicator {
            margin-top: -5px;
          }
        }
        .DonutChart__text--key {
          align-items: center;
          height: 14px;
          span {
            display: inline-block;
            height: 14px;
            line-height: 14px;
            .label,
            .value-data {
              margin-top: -4px;
            }
          }
        }
        .colorIcon-data {
          margin-top: 10px;
        }

        .HighestPerformingCreative--item {
          img {
            margin-inline: auto;
            max-height: 120px; // prevent skyscraper ad from being cropped off on PDF
            width: auto;
          }
        }
        .CreativesInfo {
          margin-bottom: 12px;
        }
        .gallery-CampaignCreatives {
          .HighestPerformingCreative--header.isPDF {
            display: none; // hide header if there's no content
          }
          &:has(.HighestPerformingCreative--item) {
            border-right: 1pt solid #0003;
            margin-right: 10px;
            padding-right: 10px;
            max-width: 20%;
            gap: 5px;
            // show header if there's content
            .HighestPerformingCreative--header.isPDF {
              display: block;
            }
            .CampaignCreatives--content {
              max-width: 80%;
            }
          }
        }
        .DayContainer {
          .container-flexContainer {
            min-width: 128px;
          }
          .flexContainer-DayContainer {
            align-items: center;
            justify-content: center;
            .upperStats-container {
              align-items: flex-start;
              gap: 0;
            }
          }
          .grouping-upperStats {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
        .Report__CampaignCreatives.isPDF {
          border-top: $border-width-thin solid $color-primary;
          margin-block: 15px 0;
          padding-top: 5px;
          .basicData-info {
            flex-direction: column;
          }
        }
        .Creatives.isPDF {
          .Creative {
            margin-block: 5px 0;
            img {
              margin-bottom: 5px;
              margin-inline: auto;
              max-height: 150px;
              width: auto;
            }
          }
          .Creative__img {
            display: block;
            margin-block: 0;
            padding: 0;
            max-height: 150px;
            width: auto;
          }
          .Creative__name {
            font-size: 9px;
            line-height: 1;
            margin-top: -6px;
            overflow-wrap: break-word;
            padding-top: 0;
            width: 100%;
            word-break: break-word;
          }
        }
        .ImageButton {
          padding: 4px;
        }
        .Report__CampaignCreatives {
          .gallery-CampaignCreatives {
            .Creatives {
              background: $tint-gray-050;
              border: 1pt solid $tint-gray-100;
              padding-block: 2px;
              padding-inline: 7px;
            }
          }
          .HighestPerformingCreative--content {
            max-width: 20%;
          }
          .CampaignCreatives--content {
            flex: 1 78%;
            width: 78%;
            .Creatives--content {
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(9%, 70px));
              gap: 2px;
            }
            .imgContainer {
              width: 249px;
            }
          }
          &:has(.HighestPerformingCreative--content) {
            .CampaignCreatives--content {
              flex: 1 100%;
              max-width: 100%;
              width: 100%;
            }
          }
        }
      }
      .flightDates-campaignInfo {
        width: 100%;
      }
      .topLevel-Stats {
        display: flex;
        justify-content: space-between;
      }
      .PieChartSVG__text {
        fill: #000;
        transform: translate(0.45px, -1px);
        &.served {
          fill: #fff;
          -webkit-filter: drop-shadow(0 0 0.2pt #000);
          filter: drop-shadow(0 0 0.2pt #000);
        }
      }
      .name-campaignInfo {
        padding-top: 11px;
        span {
          font-size: 26px;
        }
      }
      .CreativesDisplay__gallery span,
      .title-HighestPerformingCreative {
        display: inline-block;
        padding-bottom: 16px;
      }
      .daysRemaining-progressBar {
        background-color: $tint-khaki-050;
        left: 0;
        background-image: linear-gradient(
          90deg,
          $tint-khaki-400 70%,
          $tint-khaki-100 90%,
          $tint-khaki-050 100%
        );
      }
      .title-ImageButton {
        padding-top: 8px;
      }
    }

    // safari-only PDF styles
    &.Browser__Safari {
      .ExportToPrint {
        .basicData-info span,
        .DaysRemain,
        .day-date,
        .date-date,
        .FlightDate,
        .label,
        .label-grouping,
        .topStatLabel,
        .title-type,
        .title-HighestPerformingCreative span,
        .title-ImageButton span,
        .value-data,
        .value-grouping {
          font-size: 2px;
        }
        .title-type,
        .stat-TopLevelStat {
          width: auto;
        }
        .DonutChart__text--key {
          align-items: flex-start;

          .label,
          .value-data {
            position: relative;
            top: -15px;
          }
        }
      }
      .PieChartSVG__text {
        font-size: 2px;
        &.served {
          -webkit-filter: drop-shadow(0 0 1.5pt #000);
          filter: drop-shadow(0 0 1.5pt #000);
        }
      }
    }
    //  end of safari-only styles
  }
  .DayContainer__impressions {
    .container-flexContainer {
      background-color: $tint-khaki-400;
    }
  }
  .Theme__default,
  .Theme__blue,
  .Theme__red,
  .Theme__light {
    .daysRemaining-progressBar {
      background-image: linear-gradient(
        90deg,
        $tint-khaki-500 70%,
        $tint-khaki-300 90%,
        $tint-khaki-050 100%
      );
    }
    .DayContainer__clicks {
      .container-flexContainer {
        background-color: $tint-dark-100;
      }
    }
    .DayContainer__ctr {
      .container-flexContainer {
        background-color: $base-info-300;
      }
    }
  }
  .Theme__dark,
  .Theme__darkBlue {
    .daysRemaining-progressBar {
      background-image: linear-gradient(
        90deg,
        $tint-khaki-500 70%,
        $tint-khaki-300 90%,
        $tint-khaki-050 100%
      );
    }
    .DayContainer__clicks {
      .container-flexContainer {
        background-color: $tint-light-400;
      }
    }
  }
}
