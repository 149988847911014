@import 'src/tokens';

.ConfettiWrapper {
  $colors: (
    $color-primary,
    $color-secondary-300,
    $color-warning-400,
    $color-danger-600,
    $color-secondary,
    $color-tertiary,
    $color-success-400
  );
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  height: 100%;
  left: -2%;
  right: 0;
  top: 0;
  width: 99%;
  z-index: 200;
  [class|='confetti'] {
    position: absolute;
  }

  @for $i from 0 through 150 {
    $w: random(12);
    $l: random(100);
    .confetti-#{$i} {
      width: #{$w}px;
      height: #{$w * 0.4}px;
      background-color: nth($colors, random(6));
      top: -5%;
      left: unquote($l + '%');
      opacity: random() + 0.5;
      transform: rotate(#{random() * 360}deg);
      animation: drop-#{$i}
        unquote(4 + random() + 's')
        unquote(random() + 's')
        5;
    }

    @keyframes drop-#{$i} {
      100% {
        top: 108%;
        left: unquote($l + random(15) + '%');
      }
    }
  }
}
