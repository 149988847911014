@use "sass:math";

@import './Assets/mixins/animate.scss';

// Focus rings

@mixin focus-ring-inset {
  &:focus {
    box-shadow: $focus-ring-inset;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: $focus-ring-inset;
  }
}

@mixin focus-ring {
  &:focus {
    box-shadow: $focus-ring;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: $focus-ring;
  }
}

// Text

@mixin text-header($type) {
  $fontSize: var(--text-size-xxxl);
  $lineHeight: var(--line-height-xxl);

  @if $type == 2 {
    $fontSize: var(--text-size-xl);
    $lineHeight: var(--line-height-xl);
  }

  @if $type == 3 {
    $fontSize: var(--text-size-m);
    $lineHeight: var(--line-height-l);
  }
  font-weight: var(--text-weight-bold);
  text-align: start;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin text-body($type) {
  $fontSize: var(--text-size-m);
  $lineHeight: var(--line-height-m);

  @if $type == 2 {
    $fontSize: var(--text-size-s);
    $lineHeight: var(--line-height-xs);
  }
  font-weight: var(--text-weight-light);
  text-align: start;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin text-detail {
  font-weight: var(--text-weight-light);
  font-size: var(--text-size-m);
  line-height: var(--line-height-s);
  text-align: start;
  text-transform: uppercase;
}

// sass mixin/func to convert px to rem
// usage:
// .component {
//    font-size: rem(14px); // or rem(14) // outputs font-size: 0.875rem;
//    padding: rem(14px) rem(20);  // outputs padding: 0.875rem 1.25rem;
// }

// this would be the html base point-size set
$html-base: 16px;

// remove px from value
@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}
// sass function divide px number by base font size
// add 'rem' to the result

@function to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem(), which is not a number.';

    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0 {
    $value: 0;
  }

  @return $value;
}

@function rem($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: $html-base;
  }

  // If the base font size is a %, then multiply it by 12px
  // This is because 100% font size = 12px set in base font for html
  @if unit($base) == '%' {
    $base: math.div($base, 100%) * 12px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 12px;
  }

  @if $count == 1 {
    @return to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}
// this mixin makes it easier and styles reusable whenever text needs to be truncated. Has a default width, 22ch (https://drafts.csswg.org/css-values-3/#ch), roughly 22 "O" character units. Call it by @include truncate() or with custom width, @include truncate(10rem)
@mixin truncate($width: 22ch) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: $width;
}

@mixin cardgrid($rowheight: 5rem, $cardwidth: 16.95%) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax($cardwidth, 1fr));
  grid-auto-rows: minmax($rowheight, auto);
}
// usage add "@include ripples();" to any element to give it a ripples effect

@mixin ripples($duration: 2s, $delay: 0s, $count: 12, $animation: 'ripple') {
  position: relative;
  z-index: 1;
  &::after,
  &::before {
    isolation: isolate;
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 10px solid $tint-khaki-500;
    border-radius: 50px;
    animation-name: #{$animation};
    animation-duration: #{$duration};
    animation-iteration-count: #{$count};
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
  }
  &::after {
    animation-delay: #{$delay};
  }
  &::before {
    animation-delay: 0.5s;
  }

  @keyframes #{$animation} {
    from {
      opacity: 1;
      transform: scale3d(0.3, 0.6, 0.6);
    }
    to {
      opacity: 0;
      transform: scale3d(1.75, 1.75, 1.3);
    }
  }
}
