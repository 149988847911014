@import 'src/tokens';

.AdSetDisplay__BannerPreview__Body,
.BannerChecklist__Body {
  height: clamp(21rem, 26vh, 32vh);
}

.AdSetDisplay {
  transition: all 0.5s ease-in-out;
  &__preview-container {
    display: flex;
    gap: 1.125rem;

    @media (max-width: 860px) {
      flex-wrap: wrap;
    }
  }
  &__BannerPreview {
    flex-basis: 70%;
    &__Empty {
      height: 21rem;
    }
    &__Body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      gap: 0.75rem;
      grid-auto-rows: minmax(21rem, auto);
      margin-top: auto;
      overflow-y: auto;
    }
    &:only-child {
      .AdSetDisplay__BannerPreview__Body {
        height: auto;
      }
    }
    .AdSetDisplay__BannerPreview__Header,
    .BannerChecklist__Header {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      height: 2.8rem;
    }

    @media (min-width: 861px) {
      .AdSetDisplay__BannerPreview__Header {
        flex-direction: row;
      }
    }
  }
}
