@import 'src/tokens';
@import 'src/mixins';

.Card {
  @include animate-css(slideDown);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  margin: auto;
  transition: all 0.5s ease-in-out;
  &Basic {
    background: $base-bg;
    border-radius: $border-radius-s;
    box-shadow: $box-shadow-200;
    height: clamp(35rem, 95%, 86vh);
    padding: 1rem;
    p {
      color: $base-text;
    }
  }
  &:not(.Card--simple):not(.Basic) {
    padding: 1rem;
    margin-top: rem(24);
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    width: 100%;
  }
  &--confetti {
    #tw & {
      height: calc(
        100vh - 116px
      ); // height of content area without header/footer
      margin: 0;
    }
  }
}
