@import 'src/tokens';
@import 'src/mixins';

#tw .MaxHeightContainer__content .AudienceLibraryPage {
  > .MainWithSide > .MainWithSide__main {
    padding-right: 2.25vw;
  }
}

.NoAudienceFound {
  background: $base-bg;
  color: $text-on-color-white--subdued;
  padding: 1rem;
  border-radius: $border-radius-m;
  box-shadow: $box-shadow-300-dark;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
  margin: 2rem auto;
}

.TruncateFileName {
  @include truncate();
}
