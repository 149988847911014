@import 'src/tokens';
@import 'src/mixins';

.CreativeThumbnail {
  &__thumbnail {
    border: $border-width-default solid transparent;
    height: 100%;
    margin: auto;
    max-height: 15vh;
    max-width: 100%;
    object-fit: contain;
    transition: all 0.4s ease;
    width: auto;
  }
  &__thumbnail {
    &-button,
    &-div {
      margin: 0 auto;
      display: block;
      padding: 0;
      outline: none;
      border: none;
      background: transparent;

      @include focus-ring;
      img {
        min-width: 1rem;
        .Modal & {
          max-height: 12vh;
          min-width: 106px;
        }
        .Popover & {
          max-height: 36vh;
        }
        .ChangeCreativeMenu__creative-button & {
          max-height: 2rem;
        }
      }
    }
    &-div {
      img {
        border: $border-width-thin solid $base-bg;
        max-height: 7vw;
        max-width: 6vw;
        height: auto;
        width: auto;
        .Theme__dark &,
        .Theme__darkBlue & {
          border: $border-width-thin solid $tint-light-300;
        }
        .Popover & {
          max-width: 100%;
        }
        .ChangeCreativeMenu__creative-button & {
          max-width: 2rem;
        }
      }
    }
    &-button {
      img {
        height: 100%;
      }
    }
  }
  &__popover {
    #tw & .CreativeRowCarousel__button-cancel {
      margin-bottom: 0.5rem;
      margin-top: 0;
      z-index: 30;
    }
    .CreativeRowCarousel__brandUrl,
    .CreativeSpecBrandUrl {
      .InfoPopover {
        position: absolute;
        left: -5px;
        top: -12px;
      }
    }
    .CreativeSpecBrandUrl {
      .InfoPopover {
        top: -24px;
      }
    }
    .CreativeRowCarouselPopover__content {
      .CreativeChangeButtonIcon__wrap .Colon,
      .Optional,
      .CreativeSpecBrandUrl .InfoPopover,
      .CreativeRowCarousel__brandUrl .InfoPopover {
        display: none;
      }
      .CreativeSpecBrandUrl {
        .BrandURLPop,
        .Popover__label {
          font-weight: $text-weight-bold;
          width: 100%;
        }
      }
      .CreativeChangeButtonIcon__wrap {
        .CreativeChangeButtonIcon {
          min-width: 4.5rem;
          padding: 0 0.5rem;
          .IconCenter .fa {
            color: $color-primary;
          }
          .Button {
            color: $color-primary;
            .IconCenter {
              background-color: transparent;
            }
          }
          &.active,
          &:active,
          &:hover,
          &:focus {
            .Button {
              color: $primer;
              .IconCenter {
                background-color: $color-primary;
                .fa {
                  color: $primer;
                }
              }
            }
          }
        }
        .InfoPopover {
          position: absolute;
          right: 3px;
          top: -14px;
        }
        #tw & .BrandURLPop span {
          color: $color-primary-600;
        }
        .StackedButtonIcon {
          max-width: 4.5rem;
        }
      }
    }
  }
  &__thumbnail--cover {
    object-fit: cover;
  }
}

#tw {
  .CreativeThumbnail {
    &--size-xs {
      width: 2rem;
    }
    &--size-s {
      width: 4rem;
    }
    &--size-m {
      width: 6rem;
    }
    &--size-l {
      width: 8rem;
    }
  }
}

.ChangeCreativeMenu__creative-button:nth-child(odd) {
  background-color: $tint-gray-050;
}

.ChangeCreativeMenu__creative-button img,
#tw .ChangeCreativeMenu__creative-button .CreativeThumbnail__thumbnail,
.Popover .ChangeCreativeMenu__creative-button svg.ETIcon {
  margin: 0;
  height: 2rem;
  width: 2rem;
}

.Thumbnail__details {
  padding: 0.75rem 0 1.5rem;
  span {
    line-height: 1;
  }
  .ThumbnailLabel {
    text-align: right;
    width: 4.5rem;
  }
  .ThumbnailText {
    width: calc(100% - 5rem);
  }
}

#tw {
  .ThumbnailLabel {
    font-weight: $text-weight-bold;
    padding-right: 0.125rem;
  }
  .ThumbnailText {
    display: inline-block;
    word-break: break-all;
  }
}

.CreativeRowCarousel {
  gap: 0.25rem;
  &Popover__thumbnail {
    min-width: 2rem;
    padding-right: 1rem;
  }
}

.CreativesCardItem__popover-content,
.CreativeCardItem__thumbs .Popover__body {
  max-height: 80vh;
  overflow-x: auto;
}

.CreativesCardItem__thumb-item {
  max-width: 6rem;
  min-width: 6rem;
}

.CreativeCardItem__thumbs {
  box-shadow: $box-shadow-600;
  .WarningMsg {
    min-width: 100%;
    width: 0;
  }
}

.CreativeCardItem__thumbs .CreativesCardItem__thumb-container {
  img {
    max-height: 100%;
  }
}

.CreativeCardItem__thumbs {
  .CreativesCardItem__popover-content,
  .Popover__body {
    overflow: visible;
    bottom: 0 !important; // todo: fix js, seems to throw it off-screen, this forces it to stay inside parent
  }
  .Popover__body {
    background: $primer;
  }
  .CreativesCardItem__popover-content {
    padding: 0.75rem;
  }
}
