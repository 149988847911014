@import 'src/tokens';
@import 'src/mixins';

.CreativeCard {
  @media screen and (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    div:empty {
      display: none;
    }
    .CreativeCard__remove-btn {
      margin-left: auto;
    }
    .Box {
      align-items: center;
    }
  }
  &__remove-btn {
    margin: -0.5rem 0 0 -0.6rem;
  }
  &__name-input {
    display: flex;
    align-items: center;
    max-width: 11rem;
  }
  &__name-wrapper {
    max-width: 100%;
  }
}
