@import 'src/tokens';
@import 'src/mixins';

.QuestionInfo,
.FileRequirements {
  .fas,
  [class*='fa-'] {
    color: $color-primary;
  }
}

.FileRequirements .Button--text,
.QuestionInfo.Button--text {
  height: auto;
  [class*='fa-'] {
    transition: all 0.5s ease;
  }
  &:hover {
    border-color: transparent;
    .fas,
    [class*='fa-'] {
      color: $color-primary;
    }
  }
}

.FileRequirements__card {
  background-color: $base-bg;
  position: absolute;
  color: $base-text;
  height: auto;
  margin: 0 0 rem(40) rem(-208);
  width: 28rem;
  top: 2rem;
  z-index: 10;
  &.is-open {
    @include animate-css(slideDown);
    filter: drop-shadow(1px 3px 0.75rem $tint-dark-100);
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: -0.49em;
    left: 49%;
    transform: translate3d(-50%, 0, 0);
    border-width: 0 0.5em 0.5em 0.5em;
    border-color: transparent transparent $base-bg transparent;
    -webkit-filter: drop-shadow(1px 3px 0.75rem $tint-dark-100);
    filter: drop-shadow(1px 3px 0.75rem $tint-dark-100);
  }
}

.QuestionInfo {
  display: inline-block;
  position: relative;
  > .fa-times-circle {
    color: $color-primary;
  }
}

.FileRequirements {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__text {
    .Text--list {
      margin-left: 1.5rem;
      .ListItem {
        font-size: rem(14);
        color: $tint-gray-900;
        line-height: 1.1;
        margin-bottom: 0.25rem;
      }
    }
    img {
      max-width: 100%;
    }
    .Text {
      padding-top: 0.5rem;
    }
  }
}
