@import 'src/tokens';
@import 'src/mixins';

#tw {
  .MainWithSide {
    display: flex;
    flex-grow: 1;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 10;

    $this: &;
    .VerticalRule {
      border-right: border-width-thin solid $color-grey-100;
    }
    .MainWithSide {
      height: unset;
      padding-bottom: 0;
    }
    &__main {
      flex-grow: 1;
      #{$this}__main {
        #tw & {
          height: 100%;
          overflow-y: hidden;
          padding-block: 0.5rem;
        }
      }
    }
    &__side {
      &.MainWithSide__side--is-modal {
        position: relative;
        right: 0.5vw;
        z-index: 500;
      }
      .PreviewPanel__inner-wrap {
        background-color: $base-bg;
        overflow: clip;
        overflow-y: auto;
        padding: 1.5rem 2rem 2rem 1.25rem;
        position: fixed;
      }
    }

    @media only screen and (min-width: 1366px) {
      &__main {
        min-width: calc(100% - 25.5vw);
      }
    }
  }
  .AuthorizedRoutesContent > .PageContent > .MainWithSide > .MainWithSide__main,
  .MainWithSide__side--is-modal {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  .PreviewPanelModal .Modal__content,
  .RightColumn {
    @include animate-css(slideInRight);
  }
  .MainWithSide__side--is-modal,
  .RightColumn {
    max-width: 29vw;
    min-width: 20rem;
  }
  .Modal__content {
    &.EditOLCreatives {
      // add these styles so that preview panel stays put while the left column scrolls
      overflow: clip;
      .LibraryTableWrapper,
      .RightColumn {
        height: calc(100vh - 2rem);
        overflow-y: auto;
      }
      .LibraryTableWrapper,
      .PreviewPanel__inner-wrap {
        margin-right: -1.5rem;
        padding-bottom: 15vh;
        padding-right: 1.25rem;
      }
    }
    .MainWithSide {
      .RightColumn {
        margin-left: 1.5rem;
        min-width: 23%;
        padding-bottom: 100vh;
        width: 23%;
      }
      &:has(.RightColumn) {
        .MainWithSide__main {
          max-width: 77%;
          min-width: calc(100% - 23%);
        }
      }
    }
    .TableRow--selected {
      background-color: $tint-khaki-100;
      border: 2px solid $tint-khaki-700;
    }

    .PreviewPanel__inner-wrap {
      background: none;
      padding-inline: 1.25rem 0.25rem;
      min-height: 74vh;
      height: 100%;
      width: 100%;
    }
    .PreviewPanel__close-button-wrap {
      right: 1.75rem;
    }
  }
}
