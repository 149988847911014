@import 'tokens.scss';

.ReportModal__modal-container {
  .CampaignCreatives {
    .gallery-CampaignCreatives {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(15%, 100px));
      gap: 0.5px;
    }
  }

  .ImageButton {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    gap: 4px;
    box-shadow: $box-shadow-100;
    text-align: center;
    transition: all 0.4s ease-in;
    &:active,
    &:hover,
    &:focus {
      box-shadow: $box-shadow-100;
      &:not([disabled]) {
        box-shadow: $box-shadow-400;
      }
    }

    .dimensions-ImageButton {
      font-size: $text-size-s;
    }
    .image-ImageButton {
      padding: 2px 8px 5px;
      max-height: 200px;
      img,
      video {
        display: block;
        max-width: 100%;
        max-height: 190px;
        width: auto;
      }
    }
    .title-ImageButton {
      font-size: $text-size-s;
      line-height: 1;
    }
  }

  .DetailsImage {
    border-radius: $border-radius-s;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .header-DetailsImage {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .text-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 85%;
        .title-text {
          font-size: 12px;
          padding: 0;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-wrap: nowrap;
        }
      }
      .closeBtn-DetailsImage {
        border: none;
        padding: 0;
      }
    }
    .imageContainer-DetailsImage {
      border-radius: $border-radius-s;
    }
    .imageContainer-DetailsImage--img {
      margin-inline: auto;
    }
    .loading-DetailsImage {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 60px 0;
    }
  }
  .DetailsImage__modal {
    .Modal__scrollable-content {
      padding: 0;
    }
  }
}
.header-CampaignCreatives {
  align-items: center;
  display: flex;
  font-size: $text-size-ml;
  font-weight: $text-weight-bold;
  justify-content: space-between;
  line-height: 0.95;
  padding-block: 15px 10px;
  text-transform: uppercase;
}
