@import 'src/tokens';
@import 'src/mixins';

.CreateCampaignPage {
  &.PageContent {
    padding: 0;
  }
  &__top-navigation {
    position: relative;
    z-index: 2;
  }
  .CampaignHeader--text,
  &__stepper {
    max-height: 4.5rem;
    min-height: 3.5rem;
  }
  &__stepper {
    &-button {
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      border: $border-width-thin solid $tint-gray-200;
      width: 98%;
      left: 0;
      top: 52%;
      z-index: 0;
    }
  }
  &__content {
    position: relative;
    transition: all 0.5s ease-in-out;
    z-index: 1;
  }
  &__content > *,
  .CreateCampaignPage__splash-container {
    margin-inline: 2.75vw;
    width: calc(100% - 6.5vw);
  }
  .CreateCampaignPage__splash-container {
    display: flex;
    flex-direction: column;
    margin-block: auto;
  }
  &__content > .CreateCampaignPage__steps {
    width: calc(100% - 5.825vw);
  }
  &__steps {
    box-shadow: $box-shadow-200;
    position: relative;
    z-index: 2; // need to watch this so it wouldn't overlap the campaign header dropdown; stepper below needs to be higher
  }
  &__stepper {
    position: relative;
    z-index: 3;
  }
  .OptionalFilterLabel,
  .StepButtonText {
    border-bottom: 2px solid transparent;
    color: $tint-gray-500;
    line-height: 1;
    margin: 0 0.25rem 0 0;
    padding: 0.125rem 0;
    text-align: left;
    transition: all 0.25s ease;
    transform-origin: center left;
    white-space: nowrap;
  }
  .CampaignSplash_Campaign-Details {
    height: 100%;
    min-height: 40vh;
  }
  .CampaignSplash__political-prepay-msg {
    bottom: -0.5rem;
  }
  .CampaignSplash_name-details {
    align-self: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-block: 2rem 4vh;
    row-gap: 3vh;
    transition: all 0.5s ease-in-out;
    .MainAppContainer__desktop & {
      align-self: flex-start;
      margin-top: 7rem;
      padding-right: 6rem;
    }
  }
  .MainAppContainer__mobile &,
  .MainAppContainer__tablet & {
    .CampaignSplash__support-link a,
    .CampaignSplash__support-link a * {
      display: inline-block;
    }
    .more-info-link {
      padding-left: 0.25rem;
    }
  }
  #tw & {
    .is-selected {
      .OptionalFilterLabel,
      .StepButtonText {
        border-bottom: 2px solid $color-secondary-300;
        color: $tint-gray-800;
      }
    }
  }
  .CreateCampaignHeader {
    height: 3.25rem;
    button.CampaignHeader--text {
      gap: 0.125rem;
      position: relative;
      &::after,
      &::before {
        border-radius: 1rem 1rem 0 0;
        position: absolute;
        content: '';
        left: 8px;
        margin-left: -8px;
        top: -1%;
        transition: all 0.5s ease-in;
        width: 100%;
        z-index: -1;
      }
    }
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      button.CampaignHeader--text {
        background: none;
        position: relative;
        &::before {
          box-shadow: $box-shadow-200;
          height: 100%;
        }
        &::after {
          background-color: $base-bg;
          height: 120%;
        }
      }
    }
  }
  .CreateCampaignPage__stepper {
    .Stepper-button {
      background-color: $base-gray;
      z-index: 20;
      #tw & {
        .CreateCampaignPage__stepper-button:disabled .fa,
        .CreateCampaignPage__stepper-button:disabled:hover .fa {
          color: $primer;
        }
      }
    }
  }
  .CreateCampaignPage__stepper-button {
    gap: 0.25rem;
    margin-bottom: -0.1rem;
    padding: 0.625rem 1.5vw;
    transition: all 0.4s ease-in;
    &.is-selected {
      border-radius: $border-radius-xl $border-radius-xl 0 0;
      position: relative;
      z-index: 9;
      &::after,
      &::before {
        border-radius: $border-radius-xl $border-radius-xl 0 0;
        content: '';
        left: 0;
        position: absolute;
        top: -1%;
        transition: background 0.5s ease-in;
        width: 100%;
      }
      &::before {
        box-shadow: $box-shadow-300;
        height: 80%;
        width: 100%;
        z-index: -1;
      }
      &::after {
        background-color: $base-bg;
        height: 118%;
        z-index: 1;
      }
    }
    .FaCircle,
    .StepButtonText {
      position: relative;
      z-index: 5;
    }
  }
  .MainAppContainer__desktop & {
    .CreateCampaignPage__splash-container {
      display: flex;
      min-height: 90%;
    }
    .CampaignSplash {
      flex: 1 auto;
      height: 100%;
    }
    .CampaignSplash_Header {
      margin-top: 2vh;
    }
    .CampaignSplash_Header-text {
      margin-top: 1vh;
    }
  }
  .MainAppContainer__mobileL &,
  .MainAppContainer__tablet & {
    .CampaignSplash {
      background-color: transparent;
    }
  }
  .MainAppContainer__mobileL &,
  .MainAppContainer__landscape & {
    .CampaignSplash {
      grid-template-rows: 3vh auto 12vh;
      margin-top: 1vh;
    }
  }
  .AudienceLibraryPage {
    position: relative;
    z-index: 0;
  }
  .IconButtons {
    position: relative;
    z-index: 1;
  }
  .PageContent.AudienceLibraryPage {
    overflow: visible;
  }
  #tw & {
    .ReviewStep {
      margin-right: 0.75rem;
    }
  }
}

.Theme__dark,
.Theme__darkBlue {
  #advocacy-use {
    filter: brightness(220%);
  }
}
