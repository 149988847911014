@import 'src/tokens';

.PriceTextInput {
  display: flex;
  align-items: center;
  border: $border-width-thin solid $color-grey-300;
  border-radius: $border-radius-s;
  background: $base-bg;
  min-height: 1.6rem;
  i {
    color: $color-grey-400;
    padding: 0;
    padding-left: 0.25rem;
    min-width: unset;
  }
  input {
    min-width: 4.2rem;
    padding-inline: 0.2rem 0.1rem;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  &:focus-within {
    box-shadow: $focus-ring;
    &:active {
      box-shadow: $focus-ring;
    }
  }
}
