@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

.OrderlineCreativeDisplay {
  border-left: $border-width-thin dotted $tint-gray-300;
  display: flex;
  flex-direction: column;
  grid-area: display;
  height: 100%;
  max-width: 97.825%;
  div:empty {
    display: none;
  }
  &__header span {
    font-size: $text-size-ml;
  }
  .OrderlineCreativeDisplay__full-creative-details {
    a {
      font-size: $text-size-m;
    }
    h4,
    span {
      font-size: $text-size-xs;
    }
    .OrderlineCreativeDisplay__fields {
      .Button:not(.UTMButton):not(.UTMFormFields__button) {
        max-height: 2rem;
        max-width: 2rem;
        min-width: 2rem;
        min-height: 2rem;
        height: 2rem;
        width: 2rem;
      }
      .ClickThruLabel,
      .InfoPopover span {
        font-weight: $text-weight-semibold;
      }
      .OrderLine__brand-url {
        flex: 1;
        margin-left: -0.5rem;
      }
      .ClickThruLabel,
      .BrandURLPop {
        padding-top: 0.9rem;
        width: auto;
      }
      .ClickThruLabel {
        min-width: 132px;
      }
    }
  }
  &__inner-container {
    height: 100%;
    &:has(.OrderlineCreativeDisplay__loading-empty-state) {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  @include animate-css(fadeIn);
  &__loading-empty-state {
    margin: auto;
    i {
      color: $color-grey-200;

      @include animate-css(spin, 0s, 2s, infinite);
    }
  }
  &__thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    min-height: 5vh;
    margin: 1rem 0;
    background-color: $color-grey-050;
    padding: 1rem;
  }
  &__caption {
    display: flex;
    justify-content: space-between;
    font-size: $text-size-m;
    column-gap: 2.5vw;
    padding-bottom: 0.25rem;
    > * {
      @include animate-css(fadeIn, 0s, 0.2s);
    }
    &-description {
      flex: 1 auto;
      gap: 0.325rem;
      > * {
        @extend %StatsWithRules;
      }
      .FileSize_wrap,
      .Spec_wrap {
        .InfoPopover {
          margin-right: -0.35rem;
        }
      }
    }
  }
  &__creative-change {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    min-width: 16%;
    button {
      gap: 0.25rem;
    }
    &-links {
      .Button {
        padding-left: 0.65rem;
        margin-left: -0.65rem;
        .Button__icon-wrapper {
          width: 1.65rem;
        }
      }
    }
  }
  &__arrow-btn {
    color: $color-grey-300;
    border: 0;
    background: none;
    cursor: pointer;
    margin: 0 0.5rem;
    transition: color 0.2s ease-in-out;

    @include focus-ring;
    &--hide {
      opacity: 0;
    }
  }
  &__thumbnail-wrapper {
    &--animate-right {
      @include animate-css(fadeIn);
    }
    &--animate-left {
      @include animate-css(fadeIn);
    }
  }
  &__full-creative-details {
    min-height: 2.5rem;
    > * {
      @include animate-css(fadeIn, 0s, 0.2s);
    }
  }
  &__creative-error {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#tw .Modal__nonscrolling-content .OrderlineCreativeDisplay__fields {
  .OrderLine__click-through-url,
  .OrderLine__brand-url {
    padding-top: 0.8rem;
  }
  .ClickThruLabel {
    min-width: 137px;
  }
}
