@import 'tokens.scss';
@import 'src/GlobalStyles/placeholders';

.PercChangeBadge {
  padding: 5px 10px;
  min-width: 105px;
  background: $color-primary-200;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-right: 0.5rem;
    font-size: 1rem;
    color: $primer;
  }
  &.positive {
    background: $color-success-200;
    color: $primer;
  }
  &.negative {
    background: $color-warning-200;
    color: $primer;
  }
  &.NA {
    background: $color-grey-200;
    color: $primer;
  }
}

.CreativeStats {
  margin-top: 1rem;
  .infoCircle-CreativeStats {
    margin-left: 0.5rem;
  }
  .dataRow-CreativeStats {
    @extend %StatsWithRules;
    font-size: $text-size-s;
  }
}
