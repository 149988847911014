@import 'src/tokens';
@import 'src/mixins';

.ChildOrgsPage {
  padding: 2rem;
  &__section-wrapper {
    background-color: $base-bg;
    padding: 1rem;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-200;
    flex-grow: 1;
  }
  .ChildOrgTable {
    background-color: $base-bg;
  }
}

.EmptyChildOrgsSVG {
  min-width: 29vw;
}

#tw .AddChildOrgModal__layout .FormItem__wrap {
  flex-direction: column;
}
