@import 'tokens.scss';

.DonutChart {
  &Container {
    .topStatLabel {
      margin-bottom: 1rem;
    }
  }
  &__text {
    &-chart {
      .DonutChart__text .donut-container {
        margin-left: 1rem;
        margin-top: 1rem;
      }
    }
    &--details {
      display: flex;
      flex-direction: column;
      gap: 5px;
      span {
        display: inline-block;
        font-size: 14px;
        line-height: 1;
      }
      .colorIcon-data {
        height: 14px;
        width: 14px;
        border-radius: 50%;
      }
    }
    &--key {
      align-items: flex-end;
      display: flex;
      gap: 5px;
      justify-content: flex-start;
      line-height: 20px;
      &.color,
      &.data {
        height: 20px;
      }
    }
  }
}
