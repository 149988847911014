@import 'src/tokens';

.ErrorBoundary {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  * {
    line-height: 1.2;
  }
  &__graphic {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 480px) {
    text-align: center;
    > div {
      padding-inline: 5vw;
    }
  }
}
