@import 'src/tokens';
@import 'src/mixins';

.LibraryTableWrapper {
  $this: &;

  #{$this}__folders {
    min-height: 9rem;
  }
  &__folder-list {
    display: grid;
    gap: 0;
    grid-template-columns: repeat(auto-fill, minmax(9vw, 1fr));
  }
  #{$this}__sort-options {
    background-color: $base-bg;
    padding: 0.5rem;
    border-radius: $border-radius-m;
    display: flex;
    flex-direction: column;
  }
  #{$this}__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    #{$this}__pagination-footer {
      &:not(.folder-pagination) {
        padding-top: 0.625rem;
      }
    }
  }
  #{$this}__body {
    &--loading {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  #{$this}__pagination-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;
  }
  #{$this}__table-options {
    display: flex;
    .Button {
      margin: 0 0.25rem;
    }
  }
  #{$this}_child-org-toggle {
    padding-left: 0.75rem;
  }
  .TableCell--right {
    max-width: 20ch;
  }
  .Button--has-left-icon .Button__text {
    padding-right: rem(5);
  }
}

.App {
  @media only screen and (max-width: 1366px) {
    .TableRow {
      button {
        max-width: 10rem;
      }
    }
  }
}
