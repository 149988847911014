@import 'src/tokens';

.HeaderLogo {
  display: flex;
  align-items: center;
  min-width: 7rem;
  padding: 0 0.5vw;
  &:focus {
    box-shadow: $focus-ring-inset;
    outline: none;
  }
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
  &:focus-visible {
    box-shadow: $focus-ring-inset;
  }
  &__image {
    max-height: 2.75rem;
    min-height: 2.75rem;
  }
}
