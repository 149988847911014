@import 'src/tokens';

.BannerChecklist {
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  &__Header {
    font-size: $text-size-l;
  }
  &__Subheader {
    font-size: $text-size-s;
    margin-bottom: 0.45rem;
  }
  &__Body {
    display: flex;
    flex-direction: column;
    // overflow: auto, scroll would only show when there's overflow
    overflow-y: auto;
    // thinner border is better here, not just subjective. Thick rules are like hot sauce, the border stands out more than needed
    border: $border-width-thin solid $tint-gray-400;
    padding: 0.5rem;
    // padding-top not needed, already in above
    margin-top: auto; // align the preview and the checklist boxes at the bottom
    &--item {
      min-height: 2rem;
      font-size: $text-size-s;
      padding: 0.5rem;
      display: grid;
      grid-template-columns: 90% 10%;
      margin: 0.1rem;
      align-items: center;
      background-color: $color-grey-050;
      white-space: nowrap;
      &:nth-child(even) {
        background-color: $base-bg;
      }
    }
  }
  &__ExampleShape {
    padding: 0.2rem;
    width: 2rem;
    height: 1.3rem;
    margin: 0 0.5rem;
    background-color: $color-grey-100;
  }
  &__ExampleShapeItem {
    background-color: $tint-gray-400;
  }
}

.Select__option:hover {
  .BannerChecklist__ExampleShape {
    background-color: $base-bg;
  }
}
