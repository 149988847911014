@import 'src/tokens';
@import 'src/mixins';

.OrderLineEditWrapper {
  &__modal {
    .Select__label {
      white-space: nowrap;
    }
    &Content {
      &.ModalContent__creatives {
        #tw & {
          .CreativesLibraryPage {
            width: 100%;
          }
        }
      }
      &.ModalContent__audiences {
        #tw & {
          .AudienceLibraryPage {
            width: 100%;
          }
        }
      }
    }
    .GoBackButton__wrap:not(.Library--goback) {
      margin-top: 0.5rem;
      margin-right: 1.75rem;
      &.CREATIVE_TYPE_BANNER {
        margin-top: -2.45rem;
        margin-right: 0;
      }
      &.CREATIVE_TYPE_HTML5,
      &.CREATIVE_TYPE_OTT,
      &.CREATIVE_TYPE_VIDEO {
        margin-top: -2.5rem;
        margin-right: 0;
      }
      &.native,
      &.polygon,
      &.venue-replay {
        margin-right: 1.35rem;
      }
    }
    .Modal__content {
      max-height: 96vh;
      max-width: 98vw;
      min-width: 85vw;
      overflow: visible;
      .CreativeUpload--card {
        margin-top: -17px;
      }
    }
    .Modal__close {
      margin-right: 0.25rem;
    }
    .CreativesLibraryPage.CreativeEdit {
      max-width: 100%;
      min-width: 80%;
    }
    .EditOLRowItem__ol-brand-tracking {
      max-width: 100%;
      button,
      div {
        width: 100%;
      }
      small {
        display: inline-block;
        line-height: 1;
        word-break: break-all;
      }
    }
    .AudienceEdit__upload-header {
      padding-right: 0.125rem;
      .GoBackButton__wrap {
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }
    }
    .AudienceUploadStepper {
      padding-top: 1.125rem;
    }
    .OrderLineName {
      font-weight: $text-weight-bold;
    }
    .EditOLTable__header {
      align-items: flex-end;
      border-bottom: $border-width-thin solid $tint-gray-200;
      padding: 1rem 0 0;
    }
    .EditOLRowItem__count {
      padding-inline: 1vw;
      white-space: nowrap;
      width: 7ch;
    }
    .EditOLRowItem__ol-utm-tracking > div {
      width: 9ch;
    }
    .EditOLRowItem__remove-audience {
      flex-direction: column;
    }
    .EditOLRowItem {
      transition: 0.5s ease;
      &:hover {
        background-color: $tint-khaki-050;
      }
      .EditOLRowItem__item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-left: 1rem;
        padding-right: 0.25rem;
        + .EditOLRowItem__item {
          border-left: $border-width-default solid $tint-gray-050;
        }
        &.EditOLRowItem__ol-urls {
          padding-right: 1rem;
        }
        > span {
          font-weight: $text-weight-bold;
          line-height: 1;
          word-break: break-all;
        }
        span:not(.DateTargetToggle__date) {
          text-align: left;
        }

        @media screen and (min-width: 860px) {
          padding-block: 1rem;
        }

        @media screen and (max-width: 1080px) {
          justify-content: flex-start;
          #tw & .StackedButtonIcon button {
            justify-content: flex-start;
          }
        }
      }
      &:nth-child(odd) {
        background-color: $tint-gray-050;
        .EditOLRowItem__item + .EditOLRowItem__item {
          border-left: $border-width-default solid $base-bg;
        }
      }
      &:nth-child(even) {
        hr {
          border-color: $tint-gray-050 !important;
        }
      }

      @media screen and (max-width: 860px) {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem 0.25rem;
        justify-content: space-between;
        padding-block: 1rem;
        .EditOLRowItem__item {
          min-width: 45%;
          padding-block: 0.5rem;
          &.EditOLRowItem__ol-urls {
            width: 100%;
          }
        }
        .EditOLTable__TabletHeader {
          padding-bottom: 0.25rem;
        }
      }
    }
    .CreativeEdit__wrap,
    .AudienceEdit__wrap {
      margin-top: 0.5rem;
      position: relative;
    }
    .Modal__header {
      padding: 0;
    }
    .EditOLTable__TableGrid {
      display: grid;
      gap: 0.5rem;

      @media screen and (max-width: 860px) {
        display: flex;
        align-items: flex-start;
      }
    }
  }
  .OrderLines__ol-section-titles {
    &.auto-grid-order-lines-no-checkbox {
      padding-inline: 1.5rem 3.5rem;
    }
    &.auto-grid-order-lines {
      padding-inline: 1.35rem;
    }
  }
  .CreateCampaignPage__steps & {
    .OrderLines__ol-section-titles,
    .OrderLineEditWrapper__OLCard-wrapper {
      margin-inline: -1.5rem;
    }
    .OrderLines__edit-ribbon {
      margin-inline: -1.5rem;
    }
    .OrderLines__ols-selected-count {
      padding-left: 0.25rem;
    }
    .OrderLines__ol-section-titles {
      &.auto-grid-order-lines {
        padding-inline: 1.25rem 1.75rem;
      }
    }
  }
  .OrderLineEditWrapper__OLCard-wrapper.auto-grid-order-lines {
    .auto-grid-order-lines-no-checkbox {
      grid-template-columns: 6.75rem 1fr 0.77fr 1fr 0.75fr 1.81fr;
    }
  }
  &__OLCard-wrapper {
    .Modal__content.DashboardPage__view-container & {
      margin-inline: -0.7rem;
      padding-inline: 2rem;
    }
    + .OrderLineEditWrapper__OLCard-wrapper {
      margin-top: -2px;
    }
    &.Row--checked {
      background-color: $color-primary-050;
      border-color: $color-primary-400;
    }
    &:hover {
      background-color: $tint-khaki-100 !important;
    }
    .Theme__dark &,
    .Theme__darkBlue & {
      &:hover {
        background-color: $tint-khaki-050 !important;
      }
    }
  }
  &__audiences {
    .EditOLTable__TableGrid {
      align-items: center;
      grid-template-columns: 1.75fr 1.25fr 1fr 0.25fr 2fr 1.5fr 0.75fr;
    }
    .AudienceLibraryPage,
    #tw & .AudienceEdit__wrap {
      padding-inline: 0;
    }
    #tw & .AudienceEdit__wrap {
      padding-top: 2.35rem;
    }
    .EditOLRowItem__retargeting-tags {
      grid-column: 1/-1;
      grid-column-start: 1;
      width: 100%;
    }
  }
  &__creatives {
    .EditOLTable__TableGrid {
      grid-template-columns: 1.25fr 0.7fr 1.5fr 3fr;
    }
  }

  .OrderLines__edit-row {
    &--body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(5.85rem, 1fr));
      grid-auto-rows: minmax(7.25vh, auto);
      margin-left: -0.25rem;
      margin-right: 0;
      margin-top: 0.75vh;
      width: 100%;
    }
    .Edit-Col {
      font-weight: $text-weight-light;
      height: 100%;
    }
  }
}

#MainAppContainer > .MaxHeightContainer__content {
  .OrderLinesByCategory__labels.auto-grid-order-lines-no-checkbox {
    padding-inline: 0.5rem 0.75rem;
  }
  .OLCard {
    margin-inline: -1rem;
    padding-inline: 1.3rem 1.5rem;
    width: calc(100% + 2rem);
  }
}

.CollapsibleContainer__BodyRef > .OLCard,
.OrderLineEditWrapper__OLCard-wrapper {
  border: $border-width-default solid transparent;
  margin-inline: 0.08rem;
  padding-block: 1.5rem;
  padding-inline: 1.125rem;
  transition: all 0.3s ease;
  &:nth-child(odd):not(.Row--checked) {
    background-color: $tint-gray-050;
  }
  .OrderLineName__button {
    opacity: 0.3;
    transition: all 0.3s ease;
  }
  .OLCard__Name {
    &:hover {
      .OrderLineName__button {
        opacity: 1;
      }
    }
  }
}

.OLCard {
  &__row {
    grid-auto-rows: minmax(4.75rem, auto);
    .OLCardItem {
      max-height: 4.75rem;
      min-height: 4.75rem;
    }
    .CreativesCardItem {
      margin-right: 0.5rem;
    }
    .CreativesCardItem__thumbnail-wrapper {
      flex: 1;
      min-height: 4.5rem;
      min-width: 100%;
      width: 100%;
    }
    .CreativeThumbnail {
      background-color: $tint-dark-900;
      border: $border-width-thin solid $color-primary;
      max-height: 4.75rem;
      min-height: 4.75rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      #tw & {
        video,
        img {
          min-height: auto;
          min-width: 100%;
          width: 100%;
        }
      }
    }
    .CreativesCardItem {
      max-height: 4.5rem;
    }
  }
  .CreativesCardItem__missing-creative-placeholder[aria-label] {
    text-align: center;
    .fa.text-warning {
      color: $color-warning;
    }
  }
  &:last-child .CreativesCardItem__missing-creative-placeholder[aria-label] {
    &::after {
      border-width: 0.45rem;
      border-color: transparent;
      border-right-color: $primer;
      bottom: 50%;
      left: 92%;
      transform: translateY(50%);
      top: 10%;
    }
    &::before {
      right: -110%;
      top: 0;
    }
  }
}

.CreativeThumbnail__thumbnail-button,
.CreativeThumbnail__thumbnail-div {
  height: auto;
  width: 100%;
}

.CreativeThumbnail__thumbnail-button {
  .CreativeThumbnail__thumbnail {
    outline: $border-width-thin solid $color-primary;
  }
}

.CampaignContainer__orderline-details--OLRowContainer {
  .CollapsibleContainer {
    padding: 0;
  }
}

.EditingCancelUpdateButtons {
  margin-top: 1.125rem;
  margin-inline: 0;
  padding-block: 1rem;
}

.EditOLTable__header-text {
  color: $tint-gray-500;
  font-size: $text-size-m;
  font-weight: $text-weight-semibold;
  line-height: 0.86;
  padding-block: 0.25rem;
  text-transform: uppercase;
  &:not(.EditOLTable__TabletHeader) {
    padding-left: 1rem;
  }
  .FlightDatesEdit__dates-wrap & {
    text-align: center;
  }
  .CollapsibleContainer & {
    padding-left: 0;
    padding-top: 2rem;
  }
}

.FlightDatesEdit__dates-wrap {
  .EditOLTable__header-text {
    padding-inline: 1rem;
  }
  width: 26%;
}
// todo: move styles to respective components
.CreateCampaignPage__steps {
  .OrderLines__title-text {
    margin-left: -0.35rem;
  }
}

.Modal__content:not(.Modal__content--has-no-background),
.Popover {
  background-color: $base-bg;
  border: $border-width-thin solid $tint-gray-200;
  max-width: 50%;
  .Theme__dark &,
  .Theme__darkBlue & {
    filter: drop-shadow(1px 1px 0.5rem var(--tint-dark-900));
  }
}

.HTML5AdForm__library-empty-state {
  color: $tint-light;
}

.CardCardUploader p,
.AdTagCreateForm__header-text {
  width: 60%;
}

.AdTagCreate__info-link {
  white-space: nowrap;
}

#tw {
  .Modal__content {
    &.DashboardPage__view-container .CampaignContainer,
    &.DashboardPage__view-container .Modal__nonscrolling-content,
    &.DashboardPage__view-container .OrderlineContainer,
    &.CreativePreview__modal .Modal__nonscrolling-content {
      padding: 0;
    }
    &.DashboardPage__view-container {
      overflow-x: hidden;
      overflow-y: auto;
      padding-block: 1rem;
      .OrderLines__edit-ribbon,
      .OrderLines__ol-section-titles {
        margin-inline: -1.25rem;
        padding-inline: 3rem 1.85rem;
      }
      .Modal__nonscrolling-content,
      .OrderlineContainer {
        border-top-color: transparent;
      }
      .CampaignContainer {
        box-shadow: none;
      }
    }
  }
  button.Button.AdSetPreview__Button:hover {
    background-color: $color-danger;
  }
  .AdSetPreview__detail {
    color: $base-text;
  }
  .Theme__dark,
  .Theme__darkBlue {
    .Popover__label,
    .UTMFormLabel {
      color: $tint-gray-700;
    }
    .Modal__content:not(.CreateCampaignPage__footer_buttons) {
      background-color: $color-grey-800;
    }
    .CreativePreview__modal,
    .CreativesCardItem__popover-content,
    .CreativesCardItem__thumb-item {
      background-color: $color-grey-700;
    }
  }
  .PreviewPanelModal .Modal__shade,
  .PreviewPanelModal {
    z-index: 89;
  }
  .OrderLineEditWrapper__modal {
    &.OrderLineEditWrapper__flight_dates {
      .OrderLineEditWrapper__modalContent {
        overflow: visible;
      }
    }
    .EditOLTable__body {
      position: relative;
      z-index: 51;
    }
    .EditOLTable__header.StickyHeader {
      box-shadow: none;
      filter: drop-shadow(0 2px 4px #0003);
      z-index: 52;
    }
    .Modal__content {
      padding-bottom: 7.5vh;
    }
    .EditOLTable__body {
      height: 99%;
      margin-right: -1.5rem;
      max-height: calc(100vh - 16.75rem);
      overflow: clip;
      overflow-y: auto;
      padding-right: 1.5rem;
    }
    .FlightDatesOLRow {
      &:nth-last-child(n + 6) {
        ~ .FlightDatesOLRow {
          &:nth-last-child(3),
          &:nth-last-child(2),
          &:last-child {
            .CalendarInput__container {
              bottom: 2.5rem;
            }
          }
        }
      }
    }
    + .PreviewPanelModal {
      pointer-events: none; // prevent preview panel modal from blocking the table's pointer events
      right: 0;
      width: clamp(22rem, 32%, 35rem);
      .Modal__content {
        pointer-events: all; // restore previwe panel ponter events to only content
        width: 70%;
      }
      .Modal__shade {
        display: none;
      }
    }
    &:not(:last-child) {
      &.OrderLineEditWrapper__audiences,
      &.OrderLineEditWrapper__creatives {
        justify-content: flex-start;
        z-index: 88;
        margin-left: 1vw;
        .Modal__content {
          min-width: 78%;
          width: 78%;
        }
        .MainAppContainer__tablet & {
          .Modal__content {
            min-width: 78%;
            width: 78%;
          }
        }
      }
    }
  }
  .MainAppContainer__tablet .OrderLineEditWrapper__modal {
    &:not(:last-child) {
      &.OrderLineEditWrapper__audiences,
      &.OrderLineEditWrapper__creatives {
        .Modal__content {
          min-width: 56%;
          width: 56%;
        }
      }
    }
  }
  .Button__upload-edit,
  .AssetEditHeader__asset-type-select .Select__select {
    border: $border-width-thin solid $tint-gray-500;
    color: $tint-gray-800;
    text-transform: unset;
    height: 30px;
    &:active,
    &:focus,
    &:hover {
      background-color: $color-primary-600;
      color: $tint-light;
    }
  }
  .CreativesCardItem__count-badge {
    border: $border-width-thin solid $tint-gray-400;
    outline: $border-width-default solid $tint-gray-200;
    bottom: -2px;
    z-index: 105;
  }
}

.CreateCampaignHeader .Button__text {
  max-width: 45ch;
  span > span:last-child {
    @include truncate(36ch);
  }
}

.EditOLCreatives {
  .CreativesLibraryPage {
    padding: 0;
  }
}

// ribbons
.top-ribbon {
  --d: 0.6em; /* the depth */
  background-color: $color-success-700; /* the main color */
  border-bottom: var(--d) solid $color-success-900;
  color: $tint-light;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--d)),
    calc(100% - var(--d)) 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    var(--d) 100%,
    0 calc(100% - var(--d))
  );
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  line-height: 1.8;
  inset-inline: calc(-1 * var(--d));
  top: -21px;
}

/* HTML: <div class="ribbon">Your text content</div> */
.bottom-ribbon {
  --h: 1.565rem;
  --r: 0.45rem; /* control the ribbon shape (the radius) */
  --g: 0 / var(--r) calc(2 * var(--r)) no-repeat;
  background: radial-gradient(100% 50% at right, #0005 58%, #0002 101%) 0
      var(--g),
    radial-gradient(100% 50% at left, #0005 58%, #0002 101%) 100% var(--g),
    conic-gradient(at var(--r) calc(2 * var(--r)), #0000 25%, $color-success 0)
      0 0 / calc(100% - var(--r)) 100%;
  border-radius: var(--r);
  color: $tint-light;
  bottom: -24px;
  font-size: 50px;
  font-weight: bold;
  padding: calc(2 * var(--r)) 0.5em 0;
  position: absolute;
  inset-inline: calc(-1 * var(--r));
  line-height: var(--h);
  text-align: center;
  z-index: 1;
  #tw & .fa {
    color: inherit;
  }
  .gradient {
    height: var(--h);
    margin-inline: -1.2rem;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: var(--h);
      width: 9px;
      bottom: 1px;
    }
    &::after {
      right: 0;
      border-radius: 0 8px 8px 0;
      background: linear-gradient(
        90deg,
        $color-success 0%,
        rgba(190, 254, 189, 0.85) 80%,
        $color-success 0%
      );
    }
    &::before {
      left: 0;
      border-radius: 8px 0 0 8px;
      background: linear-gradient(
        270deg,
        $color-success 0%,
        rgba(190, 254, 189, 0.85) 80%,
        $color-success 0%
      );
    }
  }
}

.Theme__dark,
.Theme__darkBlue {
  .bottom-ribbon {
    background: radial-gradient(100% 50% at right, #0009 98%, #0005 101%) 0
        var(--g),
      radial-gradient(100% 50% at left, #0009 98%, #0005 101%) 100% var(--g),
      conic-gradient(
          at var(--r) calc(2 * var(--r)),
          #0000 25%,
          $color-success-700 0
        )
        0 0 / calc(100% - var(--r)) 100%;
  }
  .gradient {
    height: var(--h);
    margin-inline: -1.2rem;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: var(--h);
      width: 9px;
      bottom: 1px;
    }
    &::after {
      background: linear-gradient(
        90deg,
        $color-success-700 0%,
        rgba(190, 254, 189, 0.65) 80%,
        $color-success-700 0%
      );
    }
    &::before {
      background: linear-gradient(
        270deg,
        $color-success-700 0%,
        rgba(190, 254, 189, 0.45) 80%,
        $color-success-700 0%
      );
    }
  }
}
@media screen and (max-width: 850px) {
  #tw {
    .OrderLines__ol-section-titles,
    .OrderLineEditWrapper__OLCard-wrapper {
      margin-inline: 0;
    }
    .OrderLines__CardBox {
      overflow-x: auto;
    }
    .OrderLineEditWrapper__OLCard-wrapper {
      min-width: 50rem;
    }
  }
}
@media screen and (min-width: 570px) {
  .OrderLineNotes__popover-wrap {
    margin-left: -12rem; // right position doesn't work without left-auto which make popover displayed off view port on left in small screen
    .Popover__body {
      left: auto !important;
    }
  }
}
