// -----------------------------------------------------------------------
//     SCSS mixin based on Animate.css
//     Animate.css https://daneden.github.io/animate.css
//     get css code: https://github.com/animate-css/animate.css/tree/main/source
// -----------------------------------------------------------------------
//
//     How to use:
//     Requires $type (animation name) is required, if using
//     commas in values, put in single quote,
//     @import 'src/mixins';
//     @include animate-css($type, $duration,
//     $repeat, $direction, $node);
//
// -----------------------------------------------------------------------
@mixin animate-css(
  $type,
  $delay: null,
  $duration: null,
  $repeat: null,
  $direction: normal,
  $mode: both
) {
  // Animation name prefix
  $prefix: '';

  // Type - name of animation in your mixin call (required)
  animation-name: unquote($prefix + $type);

  // Delay - how long to delay animation starts, default to none
  animation-delay: $delay or 0s;

  // Duration - how long an animation lasts, default to a sec
  animation-duration: $duration or 0.35s;

  // Direction - set whether animation should play forward/backwards or alternate
  animation-direction: $direction;

  // Repeat - default to off (once)
  animation-iteration-count: if($repeat == infinite, infinite, $repeat);

  // Fill mode - defaults to styles applied before AND after animation, similar to 'forwards'
  animation-fill-mode: $mode;

  // Keyframes --- check to make sure keyframes is rendered once
  $KeyframeRenderOnce: if(
    global-variable-exists(keyframerenderonce),
    $KeyframeRenderOnce,
    ()
  ) !global;

  @if not index($KeyframeRenderOnce, $type) {
    $KeyframeRenderOnce: append($KeyframeRenderOnce, $type) !global;

    @keyframes #{$prefix + $type} {
      // entrance - from left with a bounce
      @if $type == bounceInLeft {
        from,
        60%,
        75%,
        90%,
        to {
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        0% {
          opacity: 0;
          transform: translate3d(-3000px, 0, 0) scaleX(3);
        }
        60% {
          opacity: 1;
          transform: translate3d(25px, 0, 0) scaleX(1);
        }
        75% {
          transform: translate3d(-10px, 0, 0) scaleX(0.98);
        }
        90% {
          transform: translate3d(5px, 0, 0) scaleX(0.995);
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }
      // fade in
      @if $type == fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      // fade in from left
      @if $type == fadeInLeft {
        from {
          opacity: 0;
          transform: translate3d(-40%, 0, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }

      // fade in from left
      @if $type == fadeInRight {
        from {
          opacity: 0;
          transform: translate3d(40%, 0, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }

      // fade in up
      @if $type == fadeInUp {
        from {
          opacity: 0;
          transform: translate3d(0, 40%, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      // fade in up
      @if $type == fadeInDown {
        from {
          opacity: 0;
          transform: translate3d(0, -40%, 0);
        }
        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      // entrance - fade out
      @if $type == fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
      // Bottom Up
      @if $type == slideUp {
        from {
          transform: translate3d(0, 50%, 0);
          visibility: visible;
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }
      // top slide down
      @if $type == slideDown {
        from,
        to {
          animation-timing-function: cubic-bezier(0.45, 0.23, 0.36, 1.05);
        }
        from {
          opacity: 0;
          transform: translate3d(0, -5rem, 0);
        }
        to,
        100% {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      // shake horizontal -- ala Apple's shaking ele when password was wrong
      @if $type == shakeX {
        from,
        to {
          transform: translate3d(0, 0, 0);
        }
        10%,
        30%,
        50%,
        70%,
        90% {
          transform: translate3d(-10px, 0, 0);
        }
        20%,
        40%,
        60%,
        80% {
          transform: translate3d(10px, 0, 0);
        }
      }
      // entrance -slide in from left
      @if $type == slideInLeft {
        from {
          transform: translate3d(-100%, 0, 0);
          visibility: visible;
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }
      // entrance - slide in from right
      @if $type == slideInRight {
        from {
          transform: translate3d(100%, 0, 0);
          visibility: visible;
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }
      // exit - slide out from right
      @if $type == slideOutRight {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(100%, 0, 0);
          visibility: visible;
        }
      }
      //Zoom Entrance
      @if $type == zoomIn {
        from {
          opacity: 0;
          transform: scale3d(0.3, 0.3, 0.3);
        }
        50% {
          opacity: 1;
        }
      }
      // exit from 100% to small
      @if $type == zoomOut {
        from {
          opacity: 1;
        }
        50% {
          opacity: 0;
          transform: scale3d(0.3, 0.3, 0.3);
        }
        to {
          opacity: 0;
        }
      }

      @if $type == zoomInRight {
        from {
          opacity: 0;
          transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
          animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        60% {
          opacity: 1;
          transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
          animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
        }
      }
      // spinning
      @if $type == spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }
      // drop in
      @if $type == boop {
        to {
          filter: blur(0);
          transform: scale(1);
          opacity: 1;
        }
      }
      // exit - fade out and up
      @if $type == fadeOutUp {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
          transform: translate3d(0, -50%, 0);
        }
      }
    }
  }
}
