@import 'src/tokens';
@import 'src/mixins';

.CreditCardManager {
  h3.TextHeader {
    line-height: 0.8;
  }
  &__empty-state {
    padding: 1vh 1vw;
    text-align: center;
  }
}

.CCListItem__card-detail {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: minmax(7rem, 28%) 0.35fr 0.35fr 0.35fr;
}

@media screen and (min-width: 1541px) {
  .CCListItem__cardName,
  .CCListItem__signed,
  .CCListItem__cardAuth {
    border-left: $border-width-thin solid $tint-gray-300;
    margin-left: 0.125rem;
    padding-left: 0.75rem;
    height: 100%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1540px) {
  #tw .CCListItem__credit-card--wrap .Default-label.ribbon {
    top: -30px;
  }
  .CCListItem__card-detail {
    display: flex;
    flex-direction: column;

    .CCListItem__cc-detail {
      width: 100%;
    }
    .CCListItem__cardInfo,
    .CCListItem__cardName,
    .CCListItem__signed,
    .CCListItem__cardAuth {
      flex-direction: row;
      gap: 1ch;
      justify-content: space-between;

      span:last-child {
        text-align: right;
        width: auto;
      }
    }
    .CCListItem__cardName,
    .CCListItem__signed,
    .CCListItem__cardAuth {
      border-top: $border-width-thin solid $tint-gray-300;
      flex-direction: row-reverse;
      padding-top: 0.35rem;
      width: 100%;
    }
    .CCListItem__cardInfo {
      width: 90%;
    }
    .CCListItem__cardInfo-number {
      max-width: 100%;
      width: auto;
    }
  }
}

.CreditCard__list-wrap {
  row-gap: 0.5rem;
  .EmptyCCList {
    padding-block: 1vh;
  }
}

.CCListItem__credit-card--wrap {
  border: $border-width-thin solid $tint-gray-200;
  border-radius: 5px;
  filter: drop-shadow(-1px 2px 2px #0004);
  min-width: 100%;
  transition: all 0.3s ease-in-out;
  &.Default-card {
    #tw & {
      border-color: $color-success-200;
      border-width: 2px;
    }
  }
  &:hover {
    border-color: $color-primary;
    filter: drop-shadow(-1px 6px 8px #0003);
  }
  &:active,
  &:focus {
    filter: drop-shadow(-1px 1px 1px #0004);
  }
  &:nth-child(odd) {
    .CCListItem__card-detail-wrap {
      background-color: $base-gray;
    }
  }
  &:nth-child(even) {
    border-color: $color-primary-100;
    .CCListItem__card-detail-wrap {
      background-color: $primer;
    }
  }
  .CCListItem__card-detail-wrap,
  .CCListItem__card-detail {
    border-radius: 5px;
  }
  .CCListItem__remove-wrap {
    right: 0.25rem;
    top: 0.25rem;
    .fa-times {
      margin-top: 1px;
    }
  }
  .Button.CCListItem__remove {
    height: 1.5rem;
    margin: 0;
    max-height: 1.5rem;
    min-height: 1.5rem;
    max-width: 1.5rem;
    min-width: 1.5rem;
    width: 1.5rem;
  }

  span {
    font-size: $text-size-s;
    text-align: left;
  }
  &.Default-card {
    .CCListItem__credit-card--icon {
      margin-top: 0.825rem;
    }
  }
  .Default-label {
    background-color: $color-success-600;
    color: $primer;
    font-weight: $text-weight-semibold;
    position: absolute;
    z-index: 50;
    #tw & {
      height: 25px;
      left: -71px;
      text-align: right;
      text-transform: uppercase;
      top: -10px;
      width: 73px;
    }
  }
}

.CCListItem__cardInfo-number {
  max-width: 5rem;
}

.AddNewCardButton {
  .Button {
    min-width: 2rem;
    padding-inline: 1vw;
  }
}

$arrow-size: 15px;

.CCListItem__auth-msg {
  margin-bottom: -3px;
  position: relative;
  top: -2px;
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    left: 50%;
    margin-left: -$arrow-size;
    margin-top: -$arrow-size * 2;
    border: $arrow-size solid transparent;
  }
  &--warning {
    &:before {
      border-bottom: $arrow-size solid $color-warning-300;
    }
  }
  &--error {
    &:before {
      border-bottom: $arrow-size solid $color-danger-300;
    }
  }
}

@media screen and (max-width: 1024px) {
  #tw .CCListItem__credit-card--wrap .Default-label {
    left: -66px;
  }
}
