.ThemeSelectionPage {
  min-height: 100vh;
  overflow-y: auto;
  padding: 2rem;
  .Tooltip__tip {
    &.bg-tertiary.right {
      top: -38px !important;
    }
    &.bg-secondary.left {
      top: -130px !important;
    }
    &.bg-primary.top {
      top: -102px !important;
    }
  }
}
