@import 'src/tokens';
@import 'src/GlobalStyles/placeholders';

.AdSetPreview {
  background-color: $base-bg;
  border-radius: $border-radius-m;
  box-shadow: $box-shadow-100;
  color: $text-on-color-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0.65rem 0.5rem 0.5rem 0.65rem;
  &:only-child {
    max-width: 30%;
  }
  &__info {
    padding: 0.125rem 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__detail {
    @extend %StatsWithRules;
    display: flex;
    font-weight: normal;
    font-size: $text-size-s;
    color: $text-on-color-white--subdued;
    line-height: 1;
  }
  &__Button {
    position: absolute;
    top: 0;
    right: 0;
    #tw & {
      background-color: $base-bg;
    }
  }
}
