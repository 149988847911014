.CloneMap {
  .mapbox {
    max-height: 65vh;
  }
}

.CloneAudience__modal {
  .Modal__header {
    padding-bottom: 0;
  }
}
