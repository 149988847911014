@import 'src/tokens';

.ShoppingCart {
  &__sub-total {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: $text-size-s;
    > span {
      font-weight: bold;
      &:first-of-type {
        color: $color-grey-400;
      }
    }
    div {
      display: flex;
      flex-direction: column;
      font-weight: bold;
    }
  }
}
