@import 'src/tokens';

.LinkButton {
  font-size: $text-size-s;
  color: $color-secondary-500;
  transition: all 0.5s ease;
  &:hover {
    cursor: pointer;
    color: $color-secondary-800;
  }
  &--on-dark {
    color: $base-bg;
  }
  &--disabled {
    pointer-events: none;
    color: $color-grey-300;
  }
  &[data-tooltip][aria-label]::before {
    width: clamp(9rem, 9vw, 10vw);
  }
}

.Theme__dark,
.Theme__darkBlue {
  .LinkButton {
    color: $color-secondary-300;
    &:hover {
      color: $color-secondary-400;
    }
  }
}
