@import 'src/tokens';
@import 'src/mixins';

//  leaving some styles here because they're custom units and the post-css build script could choke on the [], causing build errors, e.g. rendering the styles as w- and empty css attributes
.VideoForm {
  &__Buttons {
    @include cardgrid();
    grid-gap: 0.825vw;
  }
}
