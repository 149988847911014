@import 'src/tokens';

.VerticalStepper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 25vh;
  position: relative;
  &::before {
    background-color: $color-grey-200;
    content: '';
    display: block;
    position: absolute;
    height: 98%;
    left: 50%;
    margin-block: auto;
    top: auto;
    width: $border-width-thin;
    z-index: -1;
  }
  $this: &;
  button#{$this}__button {
    border: 0.125rem solid $base-bg;
    background-color: $color-primary-400;
    border-radius: 50%;
    color: $tint-light;
    cursor: pointer;
    font-size: $text-size-l;
    font-weight: $text-weight-bold;
    height: 2.75rem;
    position: relative;
    transition: all 0.4s ease-in-out;
    width: 2.75rem;
    &:not(:disabled) {
      &:hover {
        background-color: $color-primary-600;
        border-color: $color-primary-600;
      }
    }
    &--completed {
      background-color: $color-success-400;
      &:hover {
        background-color: $color-success-400;
      }
    }
    &--errored {
      background-color: $color-danger-600;
      &:hover {
        background-color: $color-danger-700;
      }
    }
    &--disabled {
      background-color: $color-grey-200;
      border-color: $color-grey-200;
      color: $base-text;
      &:hover {
        background-color: $color-grey-200 !important;
        border-color: $color-grey-200 !important;
        color: $base-text !important;
      }
    }
  }
}
