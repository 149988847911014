@import 'tokens.scss';

.TransparencyInfoInput {
  max-width: 100%;
}

.AddInfoRow {
  column-gap: 1.5rem;
}

.Theme__default,
.Theme__light {
  .TransparencyContainer {
    .TextInput,
    .FormItem__msg-wrapper {
      width: 100%;
    }
  }
}
.PoliticalTransparencyForm {
  &.PoliticalTransparencyForm__error .FormItem__required {
    .TextInput,
    select:has(option:empty) {
      outline: $border-width-thin solid $color-warning-400;
    }
  }
}
#tw {
  .PoliticalTransparencySelect {
    &:not(:has(option:empty)):not(.PaymentMethod__select) {
      outline: $border-width-thin solid $color-success-400;
    }
    option:checked {
      display: none;
    }
  }
}
.Transparency-InputContainer {
  display: flex;
  padding-bottom: 1rem;
  padding-inline: 2rem;
  padding-top: 1.65rem;
  &:nth-child(even) {
    background-color: $tint-gray-050;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: 1199px) {
    flex-direction: column;
  }
}

#tw {
  .PoliticalTransparency__uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;
    width: 95%;
  }
}

.TransparencyContainer {
  .FormItem__label,
  .TextInput {
    max-height: 2.5rem;
  }
  .FormItem__label {
    font-size: $text-size-m;
    line-height: 0.9;
  }
  .FormItem {
    column-gap: 0.5rem;
  }
  .FormItem__label {
    display: flex;
    align-items: center;
    color: $tint-gray-700;
  }
  .Checkbox__fecID--wrap {
    height: 2.5rem;
    margin-left: auto;
    padding-inline: 2rem;
    width: auto;
    .FormItem__label {
      justify-content: flex-start;
      text-align: left;
      max-width: 100%;
      width: 100%;
    }
    .FormItem__wrap {
      flex-direction: row;
    }

    @media screen and (max-width: 1199px) {
      padding-right: 0;
    }
  }
  .Transparency-disclosure {
    p {
      line-height: 1.4;
      padding-bottom: 1vh;
    }
  }

  @media screen and (min-width: 1025px) {
    .FormItem__label {
      justify-content: flex-end;
      max-width: 9vw;
      min-width: 9vw;
      text-align: right;
    }
  }
}

@media screen and (min-width: 1200px) {
  .Transparency-InputContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .TransparencyInfoInput__subTitle {
    flex-basis: 100%;
  }
  .TransparencyContainer .FormItem {
    display: flex;
    flex: 1 1;
    &.col-half {
      flex-basis: 49%;
      flex-grow: inherit;
    }
    &.col-quarter {
      flex-basis: 24.7%;
      flex-grow: inherit;
      &.zipcode {
        flex-basis: 26%;
        .FormItem__label {
          max-width: 5vw;
          min-width: 3vw;
        }
      }
      &.SelectWrap {
        flex-basis: 22%;
      }
    }
    .FormItem__wrap {
      width: 100%;
    }
    &.col-quarter {
      &.zipcode .FormItem__label {
        margin-left: 1rem;
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  .TransparencyContainer .FormItem {
    &.col-quarter {
      &.zipcode {
        flex-basis: 25.9%;
      }
    }
  }
}

@media screen and (min-width: 1890px) {
  .TransparencyContainer .FormItem {
    &.col-quarter {
      &.zipcode {
        flex-basis: 26.4%;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .TransparencyContainer .FormItem {
    &.col-quarter.SelectWrap,
    &.FormItem__submitterName {
      margin-bottom: 1rem;
    }
  }
}
