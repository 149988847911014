@import 'src/GlobalStyles/placeholders';

.UTMFormFields {
  width: 100%;
  #tw & {
    .FormItem__label {
      align-items: flex-start;
      font-weight: $text-weight-normal;
      padding-top: 0.75rem;
      min-width: 7.45rem;
    }
    .FormItem__textinput-message {
      min-width: 6.5rem;
    }
    .UTMFormFields__button,
    .UTMFormFields__buttons {
      margin-left: auto;
      justify-content: flex-end;
    }
    .UTMFormFields__button,
    .UTMFormFields__buttons button {
      display: flex;
      padding: 0;
      span {
        color: $base-info-500;
        transition: color 0.5s ease;
      }
      &:active,
      &:focus,
      &:hover {
        background-color: transparent;
        .Button__text {
          color: $base-info-600;
        }
      }
    }
  }
}

.ClickThroughWithUTM {
  min-width: 25ch;
  .UTM-form {
    @include animate-css(slideDown, $duration: 200ms, $delay: 40ms);
    padding-block: 1rem;
    padding-left: 1.125rem;
  }
  .ClickThroughWithUTM__input-button {
    .TextInputWithError-input-wrap {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      margin-top: 0;
    }
  }
  .UTMButton {
    padding-inline: 0.5rem;
    .Button__icon-wrapper {
      transition: all 0.3s ease-out;
    }
    &.is-open {
      .Button__icon-wrapper {
        transform: rotate(180deg);
      }
    }
    span {
      white-space: nowrap;
      &.Button__text {
        padding-top: 0.125rem;
      }
    }
    &:not(.OrderLineUTM) {
      background-color: $color-primary-100;
      border-color: transparent;
      border-bottom-left-radius: 0;
      color: $color-primary-600;
      border-top-left-radius: 0;
      height: 2.55rem;
      max-height: 2.55rem;
      min-width: auto;
      padding-left: 1rem;
    }
    &.OrderLineUTM {
      padding-inline: 1rem;
    }
  }
}

.OrderLineEditWrapper__modalContent {
  margin-right: -0.65rem;
  padding-right: 0.65rem;
  .ClickThroughWithUTM .UTM-form {
    padding-bottom: 0;
    padding-left: 0;
  }
}

.Button {
  &.UTMEditorButton {
    min-width: unset;
  }
}

.UTMEditorTooltip {
  align-self: flex-start;
}

.OrderlineCreativeDisplay {
  .ClickThroughWithUTM__input-button,
  .UTMButton,
  .OrderLine__click-through-url {
    height: 2.625rem;
    min-width: auto;
  }
  .ClickThruLabel,
  .BrandURLLabel {
    padding-top: 0.92rem;
  }
  .OrderLine__click-through-url {
    max-width: 99ch;
    padding: 0.88rem 0 0 0.6rem;
  }
  .ClickThruButton,
  .BrandButton {
    height: 2.5rem;
    padding-top: 0.35rem;
    width: 2.125rem;
    .Button {
      margin-inline: auto;
    }
  }
  .OrderlineCreativeDisplay__fields {
    .OrderLine__click-through-url,
    .OrderLine__brand-url,
    .TextInput__input {
      font-size: $text-size-sm;
    }
    .OrderLine__brand-url {
      min-width: 50%;
      padding-left: 0.6rem;
      padding-top: 0.9rem;
    }

    @media (max-width: 860px) {
      .OrderLine__click-through-url,
      .OrderLine__brand-url {
        min-width: 49vw;
      }
    }
  }
}
