@import 'src/tokens';
@import 'src/mixins';

.AudienceUploadStepper {
  column-gap: clamp(5px, calc(0.25rem + ((1vw - 1.4px) * 1)), 10px);
  $this: &;
  &__upload-click-wrapper {
    display: flex;
    flex: 1;
    gap: 1vw;
    min-height: 100%;
    &--disabled {
      #tw & {
        .Uploader {
          background-color: $base-bg;
          background-image: none;
        }
        cursor: initial;
        code [class*='group-hover'] {
          background-color: $base-bg;
          background-image: none;
        }
        * {
          opacity: 0.95;
        }
      }
    }
  }
  &__step-header {
    span {
      display: flex;
      flex-direction: column;
    }
  }
  &__uploader-container {
    display: flex;
    gap: 1vw;
    width: 100%;
    &--disabled {
      pointer-events: none;
      opacity: 0.75;
    }
  }
  &__format-example {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: $text-size-s;
    font-weight: $text-weight-light;
    padding: 1.5rem 0.25rem 0;
    text-align: center;
  }
  .IdentifyListHeader {
    font-size: $text-size-ml;
    font-weight: $text-weight-normal;
    line-height: 1;
  }
  &__job-type-container {
    &--highlight {
      @include animate-css(shakeX);
      background-color: $color-warning-050;
      border: 2px solid $color-warning-400;
      border-radius: $border-radius-s;
      margin-top: 0.75rem;
      h6,
      .RadioButtonGroup {
        padding-block: 0.5rem;
      }
      h6,
      .LabelMain {
        .Theme__dark &,
        .Theme__darkBlue & {
          color: $tint-light;
        }
      }
      .RadioButtonGroup {
        padding-inline: 0.6rem;
      }
      h6 {
        background-color: $color-warning-400;
        color: $base-text;
        padding-inline: 1rem;
        #tw & {
          font-weight: $text-weight-bold;
        }
      }
    }
  }
  &__content--upload {
    gap: clamp(5px, calc(0.3125rem + ((1vw - 1.4px) * 1.1905)), 20px);
    #{$this}__step-info-container {
      max-width: 7%;
      min-width: 0;
    }
    &.Upload--address {
      #{$this}__step-info-container {
        max-width: 20%;
        min-width: 5rem;
      }
    }
    &.Upload--custom {
      #{$this}__step-info-container {
        max-width: 20%;
        min-width: 5rem;
      }
    }
  }
  .RadioButton {
    flex: 1 auto;
  }
  .TableWrapper {
    overflow-x: inherit;
  }
  .AudienceUpload__text-header {
    padding-block: 0.25rem;
    width: auto;
  }
  .VerticalStepper {
    .VerticalStepper__button {
      border: 0.25rem solid var(--base-bg) !important;
      &:hover {
        background-color: $color-primary-400;
        border: 0.25rem solid var(--base-bg) !important;
        cursor: initial;
        color: $base-bg;
      }
    }
  }

  @media (min-width: 861px) and (max-width: 1180px) {
    .TableWrapper {
      overflow-x: auto;
    }
  }

  @media (max-width: 860px) {
    .TableWrapper {
      overflow-x: auto;
    }
  }
}

.MainAppContainer__mobile,
.MainAppContainer__mobileL,
.MainAppContainer__landscape,
.MainAppContainer__tablet {
  .AudienceUploadStepper {
    $this: &;
    &__content--upload {
      gap: 1.25vw;
      &.Upload--address {
        .AudienceUploadStepper__step-info-container {
          max-width: 100%;
          min-width: 100%;
        }
      }
    }
  }
  .Tab__label {
    .CreativeTypeSelector__tabs--text,
    .AudienceTypeSelector__tabs--text {
      display: none;
    }
  }
  .AudienceUpload__selection {
    margin-inline: clamp(
      -10px,
      calc(0.625rem + ((1vw - 1.4px) * 2.381) * -1),
      -40px
    );
  }
  .AudienceUploadStepper,
  .AudienceUploadStepper__content--upload {
    flex-direction: column;
  }
  .AudienceUploadStepper__step-info-container,
  .AudienceUploadStepper__step,
  .RadioButtonGroup--vertical,
  .VerticalStepper {
    column-gap: 1.25vw;
  }
  .RadioButtonGroup--vertical,
  .AudienceUploadStepper__step-header span,
  .VerticalStepper {
    display: flex;
    flex-direction: row;
  }
  .RadioButtonGroup--vertical,
  .AudienceUploadStepper__step-header,
  .AudienceUploadStepper__job-type-container,
  .AudienceUploadStepper__step-info-container {
    max-width: 100%;
  }
  .VerticalStepper {
    &::before {
      height: 1px;
      left: 0;
      top: 1.35rem;
      width: 100%;
    }
  }
  .CollapseButton__back-link {
    margin: 0 !important;
  }
  .headerSub .CollapseButton,
  .headerSub .CollapseButton__icon {
    flex: 0 !important;
    padding: 0 !important;
  }
  .headerSub .CollapseButton {
    margin-left: auto;
  }
}
