@import 'src/tokens';

.NativeBannerForm {
  transition: all 0.5s ease-in-out;
  .FormItem__label {
    font-weight: $text-weight-medium;
  }
}

.NativeBannerForm__form,
.NativeBannerForm__form-fields {
  flex: 1 1 68%;
  row-gap: 1.5rem;
}

.NativeBannerForm__preview {
  flex: 1 1 30%;
}

.MainAppContainer__desktop .NativeBanner__form--image-icon {
  max-width: 40%;
  .FormItem__textinput-message {
    max-width: 30.25rem;
  }
  .NativeBannerForm__icon {
    padding-block: 2rem;
  }
}
.MainAppContainer__mobile,
.MainAppContainer__tablet {
  .NativeBanner__form--image-icon {
    max-width: 100%;
  }
}
.SuccessButtonWrap {
  .Button {
    &:last-child {
      background-color: $color-primary;
      color: $base-bg;
    }
    &:hover {
      background-color: $color-primary-600;
    }
  }
}

.NativeBannerCreate__success-msg {
  height: 100%;
  margin: 0;
  padding-inline: 10vw;
  position: relative;
  width: 100%;
  z-index: 25;
  .Card--simple {
    margin-top: 10vh;
  }
}
.Tabs__content,
.CardUploader.nativeBanner {
  &:has(.NativeBannerCreate__success-msg) {
    .GoBackButton__wrap,
    .NativeBannerCreate {
      display: none;
    }
  }

  .Tabs__content {
    &:has(.NativeBannerCreate__success-msg) {
      .Card--simple {
        margin: 0;
      }
    }
  }
}

.FormItem__logo,
.FormItem__icon {
  .Required-asterisk {
    display: none;
  }
  .NativeBanner__button-remove {
    border: none;
    margin-right: -0.5rem;
    margin-top: -0.5rem;
    max-width: 4rem;
    width: 4rem;
    .Button__icon-wrapper,
    .Button__icon-wrapper > span {
      width: 100%;
    }
    &:focus,
    &:hover {
      #tw & {
        background-color: $color-danger-600;
      }
    }
  }
}
