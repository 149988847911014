@import 'src/tokens';

.LocationSection {
  .mapbox {
    height: 12rem;
    .leaflet-bottom {
      display: none;
    }
  }
}

#expanded-map {
  height: calc(100vh - 10rem);
  width: calc(100vw - 10rem);
  margin: 1rem;
  margin-top: unset;
}
