@import 'src/GlobalStyles/placeholders';
@import 'src/tokens';
@import 'src/mixins';

.MainHeader {
  &.Header {
    align-items: center;
    border-bottom: rem(3px) solid $color-primary-500;
    gap: clamp(5px, calc(0.325rem + ((1vw - 1.4px) * 1.2)), 20px);
    height: 100%;
    .Theme__dark & {
      border-color: $color-primary-600;
    }
  }
  &__branding {
    flex: 1;
    display: flex;
    margin-right: auto;
    padding-left: 0.75vw;
  }
  &__branding,
  &__nav,
  &__nav-items {
    align-items: center;
    display: flex;
    height: 100%;
  }
  &__nav,
  &__nav-items {
    justify-content: center;
  }
  &__nav {
    gap: 0.5rem;
    margin-left: auto;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  // styles make long names truncate, and entire avatar wrapper to be clickable, not just the avatar circle button
  &__avatar {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: auto;
    .AvatarWrapper {
      align-items: center;
      display: flex;
      min-height: rem(50);
      width: 100%;
    }
    &-wrap {
      align-items: center;
      display: flex;
      margin-left: auto;
      position: relative;
    }
    span {
      @include truncate(20ch);
      font-weight: $text-weight-bold;
      color: $color-grey-500;
      pointer-events: none;
      position: absolute;
      left: rem(30);
    }
  }
  &__dropdown {
    background: $base-bg;
    border-radius: $border-radius-m;
    flex-direction: column;
    margin-top: -0.29rem;
    min-width: 15.5rem;
    padding-block: 0.75rem;
    padding-inline: 0.125rem;
    justify-content: flex-start;
    display: flex;
    gap: 0.5rem;
    .Button__icon-wrapper {
      margin-left: -0.125rem;
      max-width: 1.75rem;
    }
  }
  &__search {
    display: flex;
    align-items: center;
    i {
      color: $color-primary;
    }
    .TextInput {
      border: $border-width-thin solid $tint-gray-400;
      flex: 1 100%;
      min-height: rem(35);
      min-width: 30vw;
    }
    .TextInput__input {
      background: transparent;
      font-size: $text-size-l;
      min-height: 100%;
    }
  }
  &__cart-icon-indicator {
    font-size: 0.85rem !important;
  }
  &__shopping-cart-container {
    @include animate-css(slideInRight);
    &--hidden {
      @include animate-css(slideOutRight);
    }
  }
  &__avatar-wrap {
    width: rem(180);
  }
  &.MainHeaderMobile,
  &.MainHeaderTablet {
    .MainHeader__nav {
      gap: 0;
    }
    .AvatarWrapper {
      padding-inline: 0.75rem;
    }
    .MainHeader__avatar-wrap {
      margin: 0;
      width: auto;
      .MainHeader__avatar-name {
        display: none;
      }
    }
  }
  &.MainHeaderMobile {
    .MainHeader__nav-items-wrap {
      position: absolute;
    }
  }
  .HeaderDropdown__options {
    z-index: 5;
    .HeaderItem {
      margin: 0;
    }
  }
  .MainAppContainer__landscape & {
    .HeaderDropdown__options {
      max-height: 55vh;
    }
  }
  &__dropdown .Button,
  &__nav-items > div:not(.HeaderNotification__wrap) .HeaderItem {
    @extend %text-color-states;
  }
  &__nav-items {
    > .HeaderItem,
    .HeaderDropdown__button {
      align-items: center;
      background-color: transparent;
      color: $color-grey-400;
      display: flex;
      justify-content: center;
      min-width: 3.625rem;
      transition: all 0.3s ease;
      &:active,
      &:focus,
      &:hover {
        background-color: $color-grey-050;
        color: $base-info-500;
      }
    }
    &.isMobile {
      @include animate-css(slideDown);
      align-items: flex-start;
      background-color: $base-bg;
      box-shadow: $box-shadow-400;
      flex-direction: column;
      position: relative;
      top: 8.45rem;
      > div,
      > button {
        height: 100%;
        margin: 0;
        min-height: 3.375rem;
        min-width: 3.625rem;
        padding: 0;
        &:last-child {
          padding-bottom: 0.5rem;
        }
      }
      .HeaderDropdown--position-left .HeaderDropdown__options {
        right: 3.655rem;
      }
    }
    &.isDesktop {
      .HeaderDropdown__options {
        margin-top: 0.545rem;
        min-width: 13rem;
        padding-bottom: 0.5rem;
      }
    }
  }
}

.AlertNumberBadge {
  height: 0.88rem;
  right: 0.8rem;
  top: 0.95rem;
  width: 0.88rem;
  span {
    letter-spacing: -0.02rem;
  }
}

.HamburgerMenu {
  flex-direction: column;
  gap: 0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 1.5rem;
  z-index: 100;
}

.HamburgerMenu__wrap {
  height: 3.125rem;
  position: relative;
  right: 0;
  width: 3.75rem;
  &.isMobile {
    .HamburgerMenu {
      display: flex;
      transform: translate(-50%, -50%);
      width: 1.5rem;
      &::before,
      &::after,
      .bar {
        background: $color-primary;
        content: '';
        display: block;
        height: 0.25rem;
        border-radius: 3px;
        transition: 0.5s;
      }
    }
    &:hover,
    &.mobileNavOpen {
      .HamburgerMenu {
        &::before {
          transform: translateY(9px) rotate(135deg);
        }
        &::after {
          transform: translateY(-7px) rotate(-135deg);
        }
        .bar {
          transform: scale(0);
        }
      }
    }
    &.mobileNavClosed {
      .HamburgerMenu {
        &::before,
        &::after {
          transform: translateY(0) rotate(0);
        }
        .bar {
          transform: scale(1);
        }
      }
    }
  }
}

#MainHeader {
  &.MainHeaderMobile,
  &.MainHeaderMobileL {
    .Modal {
      align-items: flex-start;
      padding-top: 5rem;
    }
    .MainHeader__nav {
      flex-grow: 0;
    }
    .Button {
      &:active,
      &:focus,
      &:hover {
        i {
          color: $base-bg;
        }
      }
    }
    .MainHeader__search {
      &-mobile {
        background: none;
        margin-inline: 1.5vw;
        padding: 0;
        width: 97vw;
      }
    }
  }
}
