@import 'src/tokens';
@import 'src/mixins';

$iconButtonTransition: all 0.4s ease-in-out;

@mixin h-row {
  .IconButton__itemContainer {
    align-items: center;
    gap: 0.35rem;
  }
  .IconButton__text-wrap,
  .IconButton__text-wrap .IconButton__title,
  .IconButton__text-wrap .IconButton__subTitle {
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
  }
}
%h-row {
  @include h-row;
}

.IconButton {
  height: 100%;
  width: auto;
  z-index: 25;
  .IconButton__title,
  .IconButton__subTitle {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .Theme__dark &,
  .Theme__darkBlue & {
    .IconButton__tag-label,
    .IconButton__subTitle {
      color: $tint-light;
    }
  }
  $this: &;
  &__wrap--small {
    &[aria-label]::before {
      min-width: clamp(2.5rem, 4.5vw, 7rem);
    }
  }
  #{$this}WrapHover {
    border: 0;
    transform-origin: center;
    transform: translateY(0);
    &::after {
      position: absolute;
      border-radius: 0.5rem;
      content: '';
      z-index: 100;
    }
  }
  #{$this}__itemContainer {
    position: relative;
    height: auto;
    &.IconButton__itemContainer-horizontal {
      gap: 0.25rem;
    }
  }
  svg {
    height: auto;
    object-fit: cover;
    width: 100%;
  }
  &:focus-visible {
    box-shadow: $focus-ring;
  }
  &__ComingSoon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  #{$this}WrapHover,
  #{$this}__itemContainer,
  #{$this}__title,
  #{$this}__subTitle {
    transition: $iconButtonTransition;
  }

  #{$this}__title,
  #{$this}__subTitle {
    line-height: 1.01;
    text-align: left;
    width: 100%;
  }

  #{$this}__tag-label {
    display: block;
    font-size: $text-size-vs;
    font-weight: $text-weight-light;
    text-transform: uppercase;
    line-height: 0.95;
  }
  #{$this}__title {
    font-size: $text-size-l;
    letter-spacing: -0.01rem;
    &--hover {
      position: absolute;
      visibility: hidden;
    }
  }
  &:not(.small) {
    border-radius: 0.5rem;
    border: $border-width-thin solid $tint-gray-300;
    max-width: 16.25vw;
    min-height: 7rem;
    .IconButton__text-wrap {
      line-height: 1;
      min-width: auto;
      text-align: left;
    }
    .IconButton__icon-wrap {
      min-width: 2.75rem;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      width: 15%;
      .fa,
      svg {
        max-width: 90%;
      }
    }
    #{$this}__subTitle {
      &:not(:only-child) {
        font-size: $text-size-ml;
        font-weight: $text-weight-light;
        position: absolute;
        transform: translateY(15vh);
      }
    }
    #{$this}__subTitle {
      padding-top: 0.125rem;
      visibility: hidden;
    }
    #{$this}WrapHover {
      height: 100%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      &::after {
        height: 100%;
        width: 100%;
      }
    }
    #{$this}__itemContainer {
      padding-block: 2.125vh;
      padding-inline: 1vw;
    }
    &:not(:disabled) {
      #{$this}WrapHover {
        background-color: $base-bg;
      }
    }
    &:not(:disabled) {
      &.is-selected,
      &:active,
      &:focus,
      &:hover {
        border: $border-width-thin solid transparent;
        z-index: 80;

        #{$this}WrapHover {
          background-color: $color-primary-100;
          border: $border-width-thin solid $tint-gray-300;
          box-shadow: $box-shadow-long;
          height: auto;
          max-height: 150%;
          max-width: 100%;
          min-height: 108%;
          transform-origin: center;
          transform: scale(1.05) translateY(-5%);
          z-index: 100;
          .fa,
          svg {
            transform: scale(1.1) translateY(-0.125rem);
          }
          #{$this}__subTitle {
            @include animate-css(fadeInUp, $duration: 360ms, $delay: 100ms);
            position: relative;
            transform: translateY(0);
            visibility: visible;
            .Browser__Safari & {
              @include animate-css(fadeInUp, $duration: 5ms, $delay: 0s);
              position: relative;
              transform: translateY(0);
            }
          }
          .AudienceLibraryPage & {
            max-width: 100%;
            max-height: 150%;
            transform-origin: center;
            transform: scale(1) translateY(-9%);
          }
        }
        #{$this}__itemContainer {
          @include animate-css(slideUp);
        }
        &:focus {
          @include focus-ring;
        }
      }
    }
  }
  &.small {
    border: $border-width-thin solid transparent;
    span {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      width: 100%;
    }
    .IconButton__icon-wrap {
      height: 5.25rem;
    }
    .fa,
    svg {
      filter: drop-shadow(1px 1px 1px #0005);
      opacity: 0.6;
      transform: scale(0.5);
    }
    #{$this}__text-wrap {
      position: relative;
    }

    #{$this}__title {
      font-size: $text-size-l;
      font-weight: $text-weight-light;
      span {
        line-height: 1.05;
        padding-bottom: 1.625rem;
        padding-inline: 0.75rem;
      }
      &--default {
        font-size: clamp(0.9rem, calc(0.9rem + 0.165vw), calc(1rem + 0.2vw));
        position: relative;
      }
    }
    #{$this}__subTitle {
      top: -3rem;
      color: $base-bg;
      height: 3.35rem;
      margin-right: -3rem;
      position: absolute;
      transform: scale(0.5);
      width: 5rem;
      z-index: 5;
      span.text {
        font-size: $text-size-4xl;
        font-weight: $text-weight-bold;
        position: relative;
        width: 100%;
        z-index: 30;
      }
      &::before {
        background-color: $color-secondary-300;
        border-radius: 50%;
        display: block;
        filter: drop-shadow(-1px 1px 1px #0006);
        content: '';
        height: 3.35rem;
        width: 3.35rem;
        position: absolute;
        z-index: 1;
      }
    }
    &.is-selected,
    &:active,
    &:focus,
    &:hover {
      #{$this}WrapHover {
        background-color: $base-gray;
        border-color: $base-gray;
        filter: drop-shadow(-1px 1px 2px #0003);
      }
      #{$this}__icon-wrap {
        .fa,
        svg {
          filter: drop-shadow(-1px 1px 2px #0003);
          opacity: 1;
          transform: translate(0, 0.5rem) scale(0.9);
        }
      }
      #{$this}__title {
        font-weight: $text-weight-semibold;
        &--default {
          @include animate-css(fadeOutUp, $duration: 80ms, $delay: 0ms);
          position: absolute;
          z-index: 5;
        }
        &--hover {
          @include animate-css(fadeInUp, $duration: 460ms, $delay: 100ms);
          font-size: clamp(0.8rem, calc(0.8rem + 0.1vw), calc(0.9rem + 0.19vw));
          position: relative;
          visibility: visible;
          top: 1rem;
          z-index: 8;
        }
      }
      #{$this}__subTitle {
        transform: translate(-1.45rem, -0.5rem) scale(0.9);
      }
    }
  }
}

.IconButton__wrap--small {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: auto;
  min-width: calc(100% / 5.75);
  width: 100%;
}

#tw {
  .NativeBannerForm,
  .HTML5AdForm,
  .AdTagForm {
    .IconButton {
      align-items: center;
      justify-content: center;
      .IconButton__itemContainer {
        gap: 0.25rem;
      }
      .IconButtonWrapHover {
        margin: 0.5rem;
        padding: 0.25rem;
      }
    }
  }
  .CreateCampaignPage {
    .IconButton {
      .Icon_button.IconButton--horizontal {
        @extend %h-row;
      }
    }
  }
  .CreativeTypeSelector {
    .BannerGenerator__button {
      background: none;
      min-height: 3.25rem;
      .IconButton__itemContainer {
        flex-direction: row;
        padding-block: 0.75vh;
        flex-wrap: nowrap;
        line-height: 1;
      }
      .IconButton__text-wrap {
        gap: 0;
        width: auto;
      }
      &:not(.is-selected) .IconButtonWrapHover {
        background: none;
      }
      &:hover {
        .IconButtonWrapHover {
          background-color: $color-primary-100;
        }
      }
    }
  }
}

@media screen and (min-width: 1080px) {
  #tw {
    .IconButton:not(.small) {
      @include h-row;
    }
  }
}

body {
  .IconButton {
    &:disabled {
      background: none;
      .IconButtonWrapHover,
      .IconButtonWrapHover:hover {
        background-color: $tint-gray-050;
        border-color: $tint-gray-300;
        color: $tint-gray-400;
        .IconButton__title,
        h3,
        span,
        .fa {
          color: $tint-gray-400;
        }
        .fa,
        svg {
          filter: saturate(20%) grayscale(100%) brightness(60%);
        }
      }
    }
    &.BannerGenerator__button {
      .IconButton__title {
        color: $tint-gray-600;
      }
      &:active,
      &:focus,
      &:hover {
        .IconButton__title {
          color: $tint-gray-900;
        }
      }
    }
  }
  .Tabs .IconButton:not(.small) {
    position: relative;
    margin-left: -34%;
    top: 0.25rem;
    max-width: 33%;
    min-width: 13.5rem;
    width: 30%;
    .IconButtonWrapHover {
      width: 100%;
      &::after {
        box-shadow: none;
      }
    }
    .IconButton__icon-wrap {
      min-width: 2rem;
    }
    .IconButton__title,
    .IconButton__subTitle {
      text-align: left;
    }
    .IconButton__title {
      font-size: $text-size-m;
      font-weight: $text-weight-normal;
      white-space: nowrap;
    }
    .fa,
    .ETIcon {
      filter: saturate(20%) grayscale(100%) brightness(120%);
    }
    &.is-selected,
    &:active,
    &:focus,
    &:hover {
      .fa,
      .ETIcon {
        filter: saturate(100%) grayscale(0%);
      }
      .IconButton__title,
      .IconButton__subTitle {
        color: $tint-gray-800;
      }
    }
  }

  @media only screen and (max-width: 1079px) {
    .IconButton:not(.small) {
      .IconButton__text-wrap {
        width: 100%;
      }
      .IconButton__itemContainer {
        flex-direction: column;
        align-items: center;
        gap: 0.125rem;
        line-height: 1.2;
        margin: auto;
        width: 100%;
        .IconButton__title,
        .IconButton__subTitle {
          line-height: 0.98;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .BannerForm__Buttons {
      .IconButton,
      .CreativeUploader {
        min-width: 24%;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    .CollapsibleContainer__BodyRef {
      margin: 0;
    }
    .IconButton {
      min-width: 48%;
    }
  }

  @media only screen and (max-width: 428px) {
    .IconButton {
      min-width: 100%;
    }
  }
}

.MainAppContainer__landscape,
.MainAppContainer__tabletL,
.MainAppContainer__desktop {
  .IconButton.small {
    height: clamp(7.125rem, 11vh, 8.125rem);
  }
  .LibraryTableWrapper__folder-list {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 5.25), 1fr));
    grid-auto-rows: minmax(clamp(7.625rem, 13vh, 8.75rem), auto);
  }
}

.MainAppContainer__mobileL,
.MainAppContainer__tablet {
  .IconButton.small {
    height: 12.5vh;
    min-height: 6rem;
  }
  .LibraryTableWrapper__folder-list {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 3.25), 1fr));
    grid-auto-rows: minmax(15.5vh, auto);
  }
}

.MainAppContainer__mobile {
  .IconButton.small {
    height: 9.5vh;
  }
  .LibraryTableWrapper__folder-list {
    grid-template-columns: repeat(2, calc(100% / 2.25));
    grid-auto-rows: minmax(13.5vh, auto);
  }
}
