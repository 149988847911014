@import 'src/tokens';

.NewMover {
  max-width: 100%;
  &__step-info-container {
    display: flex;
    flex-direction: column;
    padding-block: 0.5rem;
    width: 100%;
  }
  &__step-text {
    font-size: $text-size-ml;
    font-weight: $text-weight-light;
  }
  &__step-header {
    font-weight: bold;
    font-size: $text-size-l;
    color: $tint-gray-800;
  }
}
