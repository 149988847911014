@import 'tokens';
@import 'mixins';

.SubJobBreakdown,
.SubJobBreakdown__running {
  @include animate-css(fadeIn);
}

.IconStack {
  grid-row: 1;
  grid-column: 1;
}
