.SearchPage {
  &.PageContent {
    .CollapseButton {
      margin: 0;
      padding: 0;
      padding-left: 0.5rem;
      background-color: transparent;
    }
  }
}
