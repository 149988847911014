@import 'src/tokens';

.CounterButton {
  width: 13rem;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: $tint-gray-700;
  background-color: $color-grey-050;
  padding: 1rem;
  border: 1px solid $color-primary-400;
  border-radius: $border-radius-s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0; // prevent cards from stretching full-height
  transition: background 0.3s, box-shadow 0.3s;
  &__selected {
    box-shadow: inset 0 0 10px $tint-gray-400, inset 0 0 3px $color-success;
    border-radius: $border-radius-s;
    background-color: $color-success-050 !important;
  }
  &:disabled,
  &:disabled:hover {
    opacity: 0.6;
    background: $tint-gray-050;
    color: $tint-gray-800;
    border-color: $tint-gray-800;
    cursor: default;
    box-shadow: none;
    transition: none;
  }
  &:hover {
    color: $tint-gray-700;
    box-shadow: $box-shadow-400-dark;
  }
  &:active {
    box-shadow: $box-shadow-100-focus;
  }
  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title {
    // truncate text not needed if width allowed to flex
    padding: 0.25rem 0;
    font-size: $text-size-m;
    font-weight: $text-weight-bold;
    line-height: 1;
  }
  &__subTitle {
    margin: 0;
    font-size: $text-size-xs;
  }
  &__counter {
    margin: 0;
    font-weight: bold;
    font-size: 4em;
  }
  &__footerText {
    margin: 0;
    font-size: $text-size-xs;
  }
  [class*='CounterButton__'] {
    min-width: 5rem;

    @media only screen and (max-width: 1366px) {
      flex-direction: column;
      align-items: center;
      line-height: 1;
      div:empty {
        display: none;
      }
    }
  }
}
