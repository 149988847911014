@use 'sass:math';

@import 'src/tokens';
@import 'src/mixins';

.Dashboard {
  &.PageContent {
    .TableCell:nth-child(3) {
      min-width: 14ch;
    }
    .TableCell {
      &.TableCell--right {
        min-width: 5ch;
      }
      &:first-child {
        .Box > .Box {
          @include truncate(15ch);
          flex: 1;
          div:empty {
            display: none;
          }
          .fa {
            display: inline-block;
            margin-right: 0.35rem;
          }
        }
      }
    }
  }
  &Page__view-container {
    width: 98%;
  }
  &Card {
    &__row,
    &__content {
      display: flex;
    }
    &__row {
      gap: 1vw;
      .Card {
        font-size: rem(14);
        margin: 0;
        max-height: 100%;
        min-height: 5rem;
        align-items: center;
        justify-content: center;
        .Dashboard & {
          padding: 0.5rem 0.75rem;
        }

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            animation-delay: 90ms * $i;
          }
        }
      }
    }
    &__content {
      align-items: center;
      flex-direction: row-reverse;
      gap: 1vw;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }
    &__icon {
      display: inline-block;
      align-self: center;
      min-height: 2.25rem;
      width: auto;
      .fa,
      svg {
        height: 100%;
        width: auto;
      }
      .fa {
        font-size: $text-size-4xl !important;
      }
      #tw & {
        svg.ETIcon {
          min-height: 2.9rem;
        }
      }
    }
    &__header {
      color: $color-grey-500;
      font-size: $text-size-xl;
      line-height: 0.9;
    }
    &__text-body {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &__text {
      font-size: $text-size-m;
      font-weight: $text-weight-light;
      line-height: 0.95;
      margin-top: rem(5);
    }
    .Card {
      min-width: 5.625rem;
    }
  }
  &.PageContent .OrderlineTable__empty-state {
    .Card {
      width: auto;
    }
  }

  @media screen and (min-width: 841px) {
    &.PageContent {
      .DashboardCard__row .Card {
        width: clamp(calc(100% / 6), calc(100% / 6.6), calc(100% / 5));
      }
    }
  }

  @media screen and (max-width: 840px) {
    &.PageContent {
      .DashboardCard__row {
        flex-wrap: wrap;
        .Card {
          max-height: 9.5rem;
          width: clamp(calc(100% / 4.3), calc(100% / 5), calc(100% / 6));
        }
      }
    }
  }

  @media screen and (max-width: 1490px) {
    &Card {
      &__row {
        gap: 1.5vw;
        .Card {
          // max-width made cards stack in 13-in screen
          min-height: rem(150);
        }
      }
      &__header {
        margin-top: rem(5);
      }
      &__content {
        flex-direction: column;
        padding-top: 0.5rem;
        text-align: center;
        justify-content: flex-start;
      }
    }
  }
}
