@import 'src/tokens';
@import 'src/GlobalStyles/placeholders';

.SideMenuOrgDetails {
  display: flex;
  justify-content: space-around;
  align-items: center;
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 6.5rem;
  }
  &__menu-button {
    font-size: 1.2rem;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    outline: none;
    width: calc(100% - 0.5rem);
    .fa {
      color: $color-primary;
    }
    p:not(:hover) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p:hover {
      text-overflow:unset;
      word-wrap:break-word;
    }
    &:active,
    &:focus,
    &:hover {
      .fa {
        background-color: $color-primary;
        color: $base-bg;
      }
    }
    &:focus {
      box-shadow: $focus-ring;
    }
    [data-tooltip='right']::after {
      top: 23px;
    }
  }
  &__menu {
    background: $base-bg;
    border-radius: $border-radius-s;
    row-gap: 0.125rem;
    width: 17rem;
    &.PointerTip::before,
    &.PointerTip::after {
      transform: rotate(180deg);
      left: -14px;
    }
    &.PointerTip::after {
      border-left: 14px solid #0003;
      top: 15px;
      z-index: -2;
    }
    &.PointerTip::before {
      border-left: 14px solid $color-primary-100;
      top: 13px;
    }
    > .Button,
    .OrgSelect__org-list > .Button {
      @extend %text-color-states;
      height: 100%;
      max-height: 100%;
    }
    .Button__icon-wrapper {
      min-width: 1.75rem;
      width: 1.75rem;
    }
    > .Button--text {
      display: flex;
      padding: 0;
      text-align: left;
      width: 100%;
      &.SideMenuOrgDetails__menu-item-org-change,
      &.SideMenuOrgDetails__menu-item-org-change .fa {
        color: $color-grey-800;
      }
      &.SideMenuOrgDetails__menu-item-org-change {
        max-height: 100%;
        padding-block: 0.75rem;
        padding-left: 1rem;
        .Button__icon-wrapper {
          min-width: 2rem;
        }
        &.ChangeOrgButton {
          padding-left: 1.45rem;
        }
        &.BackButton {
          .Button__icon-wrapper {
            margin-left: -0.75rem;
          }
        }
      }
      &:not(.SideMenuOrgDetails__menu-item-org-change) {
        margin-inline: 0.25rem;
        max-height: 100%;
        padding-block: 0.75rem;
        padding-inline: 0.75rem;
        row-gap: 0.125rem;
        width: calc(100% - 0.5rem);
        .Button__icon-wrapper:not(.IconCenter) {
          min-width: 2.75rem;
          padding-left: 0.5rem;
        }
        &:last-child {
          margin-bottom: 1.125rem;
        }
      }
    }
    &-item {
      border-radius: $border-radius-m;
    }
  }
}

.SideMenuOrgDetail__dropdown {
  margin-left: -0.209rem;
  margin-top: -3.38rem;
  .MainAppContainer__mobile &,
  .MainAppContainer__mobileL & {
    .Popover__body {
      bottom: unset !important;
      top: 0 !important;
    }
  }
  .MainAppContainer__mobileL &,
  .MainAppContainer__landscape & {
    .OrgSelect {
      max-height: 41vh !important;
      overflow-y: auto;
    }
  }
}

.Theme__darkBlue,
.Theme__dark {
  .SideMenuOrgDetails__menu {
    .SideMenuOrgDetails__menu-item-org-change,
    .fa,
    .Button__text,
    .OrgSelect,
    .Pagination .fa,
    .OrgSelect__search .fa {
      color: $tint-light-800;
    }
  }
  .SideMenuOrgDetails__menu-button:hover {
    .fa-ellipsis-v {
      background-color: $color-primary-100 !important;
    }
  }
}
