@import 'src/tokens';

.OrderlineStatePopover {
  max-height: 70vh;
  overflow-y: auto;
  font-size: $text-size-s;
  p {
    color: $tint-gray-700;
  }
  &__row {
    display: flex;
    align-items: center;
    padding: 0.25rem;
    &:nth-child(odd) {
      background-color: $color-grey-050;
    }
  }
  &__status-container {
    min-width: 9rem;
  }
}
