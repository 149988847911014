@import 'src/tokens';
@import 'src/GlobalStyles/placeholders';

.HighestPerformingCreative {
  .title-HighestPerformingCreative {
    font-weight: $text-weight-light;
    margin: 0.25rem 0;
    line-height: 0.95;
  }

  .container-HighestPerformingCreative {
    &.creative-container {
      .imgContainer {
        img,
        video {
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.basicData-info,
.CreativeStats {
  > * {
    @extend %StatsWithRules;
    font-size: $text-size-s;
  }
}
