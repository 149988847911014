@import 'src/tokens';
@import 'src/mixins';

.OrderlineStats {
  width: 100%;
  &__large-stat {
    #tw & {
      color: $tint-gray-800;
      font-size: clamp(1rem, 0.65rem + 1.1vw, 5rem);
      font-weight: $text-weight-semibold;
    }
  }
  &__loading {
    color: $color-grey-100;
    height: 2rem;
    i {
      font-size: 2.2rem;

      @include animate-css(spin, 0s, 2s, infinite);
    }
  }
  &__row {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(5.75rem, 1fr));
  }
  &__stat-container {
    border: 3px solid $tint-gray-050;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    outline: none;
    padding-bottom: 2rem;
    padding-left: 0.75rem;
    padding-right: 0.5rem;
    padding-top: 0.65rem;
    position: relative;
    transition: all 0.3s ease-in;
    .EditCircleIcon {
      background: transparent;
      bottom: 0.25rem;
      position: absolute;
      right: 0.25rem;
      transition: all 0.5s ease-in-out;
      .fa {
        color: $color-primary;
        transition: all 0.5s ease-in-out;
      }
    }
    &--inactive {
      opacity: 0.3;
      pointer-events: none;
    }
    &.active,
    &:active,
    &:focus {
      border-color: $color-primary;
      box-shadow: $box-shadow-300;
    }
    &:hover {
      background-color: $color-primary-050;
      border-color: $color-primary-050;
      box-shadow: $box-shadow-500;
      cursor: pointer;
      .EditCircleIcon {
        background-color: $color-primary;
        color: $base-text;
        .fa {
          color: $base-bg;
        }
      }
    }
  }
  &__drawer {
    @include animate-css(slideDown, $duration: 200ms, $delay: 40ms);
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
}
