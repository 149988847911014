@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

.PreviewPanel {
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    flex-direction: column;
  }
  &__name {
    height: 50px;
    overflow-wrap: break-word;
    margin-right: -0.5rem;
    margin-top: -0.25rem;
    padding-right: 0.75rem;
    overflow: clip;
    overflow-y: auto;
    .PreviewPanel__TextInput-wrap {
      margin-block: 1px;
      margin-inline: 1px;
      padding: 0.25rem;
    }
    .TextInput__input {
      padding: 0;
    }
  }
  &__name-inner {
    padding-bottom: 0.5rem;
  }
  &__section-header {
    font-size: $text-size-lg;
    font-weight: $text-weight-bold;
  }
  &__campaign-btn,
  &__edit-buttons .Button {
    padding: 0.25rem;
  }
  &__edit-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &__name,
  &__name .PreviewPanel__detail--button,
  &__name .TextInput__input {
    font-weight: $text-weight-bold;
    font-size: $text-size-ml;
  }
  &__name,
  &__name .PreviewPanel__detail--button {
    padding: 0;
  }
  &__TextInput-wrap {
    height: auto;
  }
  &__detail {
    font-weight: normal;
    font-size: $text-size-s;
    column-gap: 1vw;

    @extend %StatsWithRules;
    &--button {
      background-color: transparent;
      border: none;
      width: 100%;
      cursor: pointer;
      text-align: left;
      &:hover {
        color: $color-primary;
      }
    }
    .Tooltip__tip {
      max-width: 55%;
      min-width: 9rem;
      right: 100%;
      &.bottomLeft {
        top: -9px;
      }
    }
  }
  .Stats__detail {
    @extend %StatsWithRules;
    font-size: $text-size-s;
  }
  .UsedInOrderLineCounter .Button {
    color: inherit;
    margin-right: -3px;
  }
  .UsedInOrderLineCounter .Button:active,
  .UsedInOrderLineCounter .Button:focus,
  .UsedInOrderLineCounter .Button:hover {
    background-color: inherit;
    color: inherit;
    cursor: default;
  }
  &__section-inner {
    gap: 0.25rem;
    .Button--editor {
      #tw & {
        right: -5px;
        z-index: 10;
      }
    }
  }
  &__section-label {
    font-weight: $text-weight-bold;
  }
  &__text-area {
    width: 100%;
    min-height: 5rem;
    font-family: inherit;
    font-size: $text-size-s;
  }
  &__thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    gap: 1rem;
    padding-block: 0.25rem;
    width: 100%;
    [data-tooltip*='right'][aria-label]::before {
      bottom: -20px;
      transform: translateY(50%);
      left: 30px;
    }
  }
  .Button--editor {
    background-color: $base-bg;
    position: relative;
    height: 1.75rem;
    width: 1.75rem;
    z-index: 100;
  }
  .HorizontalRule {
    margin: 0.75rem 0;
  }
  .OrderLineCount__button-wrap {
    margin-right: -4px;
  }
  .FileDimensions {
    top: -0.125rem;
    z-index: 35;
  }
  #tw & {
    .Tooltip {
      &__AUDIENCE_TYPE_ADDRESSES,
      &__AUDIENCE_TYPE_B2C,
      &__AUDIENCE_TYPE_B2B {
        top: -37px;
      }
    }
  }
  .UsedInOrderLineCounter {
    span[aria-label]::before {
      display: none;
    }
    button:hover {
      color: $color-grey-700 !important;
      background: transparent !important;
    }
  }
  .HideAudience {
    @extend %StatsWithRules;
  }
}

#tw {
  .FolderActions {
    justify-content: center;
    .IconLabel {
      color: $color-primary;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .leading-none {
      display: inline-block;
    }
    .truncate {
      max-width: 14ch;
    }
    .StackBtn {
      flex: 1 auto;
      height: 100%;
      width: 24%;
      .Button {
        justify-content: flex-start;
        margin-block: 0.5rem 0;
        min-height: 7.125rem;
        height: 100%;
        padding-inline: 0.25rem;
        padding-top: 0.35rem;
        .IconCenter {
          border: transparent;
          transition: all 0.5s ease-in-out;
          width: 3.75rem;
        }
        .Button__text {
          margin-top: -0.25rem;
          white-space: unset;
        }
        &.active,
        &:active,
        &:focus,
        &:focus-within {
          @include focus-ring;
          outline-width: $border-width-default;
          background-color: $base-gray;
          border-radius: $border-radius-s;
          border-color: $color-primary;
          .fa {
            color: $color-primary;
          }
          .IconCenter {
            background: transparent;
          }
        }
      }
      &.text-danger-600 {
        * {
          color: $color-danger-600;
        }
        &:hover,
        &:focus {
          i.fa {
            color: $base-bg;
          }
          .IconCenter {
            background-color: $color-danger-600 !important;
          }
        }
      }
    }
    .FolderListButton__add-folder {
      &:only-child {
        flex-grow: unset;
        width: auto;
        .Button {
          padding-inline: 2rem;
          min-height: unset;
          height: auto;
        }
      }
    }
  }
}
//outside preview panel container
.PreviewPanel__close-button-wrap {
  position: relative;
}

.PreviewPanel__close-button {
  background-color: $base-bg;
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 5;
}

.MainWithSide > .PreviewPanel,
.PreviewPanel__inner-wrap {
  height: 100%;
}

.PreviewPanel__header[data-tooltip*='left'][aria-label]::before {
  right: 34px;
  top: 56px;
}

.Modal.PreviewPanelModal {
  margin-left: auto;
  justify-content: flex-end;
  .Modal__scrollable-content {
    padding-inline: 1.5rem;
    padding-top: 1.5rem;
    width: 100%;
  }
}
