@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

.SideMenuItem {
  @extend %text-color-states;
  &__label {
    display: inline-block;
    max-width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @include focus-ring-inset;
  &--is-active {
    .SideMenu & {
      background-color: $base-info-050;
      border-radius: $border-radius-2xl;
      color: $base-info;
      span {
        color: $base-info;
      }
      .fa {
        color: $base-info-400;
      }
    }
  }
  &--is-child {
    font-weight: normal;
  }
  &--is-not-clickable {
    pointer-events: none;
  }
  &--is-disabled {
    cursor: not-allowed;
    background-color: $color-grey-300 !important;
    opacity: 0.5;
    span {
      color: $color-grey-100 !important;
      text-decoration: line-through !important;
      .fa {
        color: $color-grey-100;
      }
    }
  }
}
