@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

.OrderlineContainer {
  &__details {
    font-size: $text-size-s;
    margin-bottom: 0.5rem;
    .OrderLine__campaign-name-id {
      > * {
        @extend %StatsWithRules;
      }
    }
  }
  &__not-started {
    align-items: center;
    display: flex;
    column-gap: 0.3vw;
    flex-wrap: wrap;
    flex: 1 0 auto;
    justify-content: center;
    margin-block: auto;
    padding: 1rem;
    row-gap: 0.5vh;
    color: $color-grey-500;
    font-size: $text-size-l;
    i {
      margin-right: 1rem;
      color: $color-grey-300;
    }
  }
  .OrderLine__Status-Pace {
    gap: 1vw;
    .ContinueEditButton,
    .FixButton .Button__text {
      min-width: 7.75rem;
    }
    .ContinueEditButton .FixButton {
      padding: 0 0.25rem;
    }
  }
  &__layout-wrapper {
    display: grid;
    grid-template-columns: 42% 58%;
    grid-template-areas: 'left display';
    column-gap: 1.5rem;
  }
  &__LeftColumn {
    grid-area: left;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .OrderlineCreativeDisplay {
    border-left: $border-width-thin dotted $tint-gray-300;
    grid-area: display;
    height: 100%;
    max-width: 97.825%;
    div:empty {
      display: none;
    }
    &__inner-container {
      height: 100%;
      &:has(.OrderlineCreativeDisplay__loading-empty-state) {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
  &_RightColumn {
    width: 40%;
  }
  .IndicatorRow .fa {
    color: $color-primary;
  }

  @media (min-width: 861px) and (max-width: 1028px) {
    .OrderLine__Status-Pace {
      flex-direction: column;
    }
    .ExpansionHeader {
      max-width: 93%;
    }
  }

  @media screen and (max-width: 860px) {
    .OrderlineContainer__not-started,
    .OrderlineStats__row {
      padding-inline: 0;
    }
    .OrderlineContainer__layout-wrapper {
      flex-direction: column;
    }
    .OrderlineCreativeDisplay {
      border: none;
    }
    .OrderlineCreativeDisplay__inner-container {
      margin: 0;
    }
    .OrderlineContainer__LeftColumn,
    .OrderlineCreativeDisplay {
      max-width: 100%;
      width: 100%;
    }
  }
}

#tw {
  .ContinueEditButton,
  .FixButton {
    border-color: transparent;
    color: $base-bg;
    .Button__text {
      color: $base-bg;
    }
  }
  .FixButton {
    background-color: $color-danger;
    &:hover {
      background-color: $color-danger-600;
      border-color: $color-danger-600;
    }
  }
  .ContinueEditButton {
    background-color: $color-primary;
    &:hover {
      background-color: $color-primary-600;
      border-color: $color-primary-600;
    }
  }
}

@media screen and (max-width: 1671px) {
  .OrderlineContainer {
    &__not-started {
      flex-direction: column;
      text-align: center;
      .ETIcon {
        margin-top: auto;
      }
      div.flex {
        margin-bottom: auto;
      }
    }
  }
}
