@import 'src/tokens';
@import 'src/mixins';

.OrderlineTable {
  &__loading {
    height: 50vh;
    overflow: hidden;
    padding-top: 3.75rem;
  }
  &__report-selection-menu {
    display: grid;
    grid-template-columns: repeat(auto-fill, 12rem);
    grid-gap: 1rem;
    padding: 0.15rem 3.75rem;
    background-color: $color-secondary-500;
    color: $text-on-color-secondary-500;
  }
  &__ol-selected-text {
    size: $text-size-m;
  }
  &__table-wrapper {
    table {
      td:last-of-type,
      th:last-of-type {
        min-width: 4rem;
      }
    }
    p,
    span {
      line-height: 0.95;
    }
    &:has(.OrderlineTable__empty-state) {
      display: grid;
      min-height: 25vh;
      overflow: visible;
      .fa {
        height: auto;
      }
    }
  }
  &__cell-list {
    display: flex;
    flex-direction: column;
    font-size: $text-size-s;
    line-height: 0.9rem;
  }
  &__ellipsis {
    line-height: 0.2rem;
    text-align: right;
    margin-top: 0.2rem;
  }
  &__cell-list-tooltip {
    display: flex;
    flex-direction: column;
    background-color: $base-bg;
    box-shadow: $box-shadow-200;
    border-radius: $border-radius-m;
    padding: 0.5rem;
    color: $color-grey-500;
    font-size: $text-size-s;
  }
  &__empty-state {
    padding: 2rem;
    display: flex;
    justify-content: center;
    font-size: $text-size-l;
    gap: 0.5rem;
    color: $color-grey-600;
    font-weight: bold;
    max-width: 40rem;
    margin: 0 auto;
  }
}

#tw td[class*='TableRow__expanded-cell'] {
  .shadow-inner,
  .OrderlineContainer {
    background-color: $base-bg;
    box-shadow: $box-inset-ol;
    padding-inline: 0.95rem;
    padding-top: 0.5rem;
  }
}
