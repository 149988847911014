@import 'src/tokens';

.DonutChartContainer,
.PieChart,
.PieChartSVG {
  overflow: visible;
  position: relative;
}
.DonutChartContainer {
  padding: 15px 0;
}
.PieChart,
.PieChartSVG {
  align-items: center;
  display: flex;
}
.PieChart {
  justify-content: flex-start;
  min-height: 140px;
  min-width: 180px;
}
.PieChartSVG {
  height: 100%;
  margin: 0;
  width: 100%;
  .PieChartSVG__text {
    font-weight: 700;
    -webkit-filter: drop-shadow(0 0.1px 0.1px $tint-dark-100);
    filter: drop-shadow(0.1px 0.1px 0.1px $tint-dark-100);
    min-width: 80px;
    width: 100%;
    &.remaining {
      fill: $base-text;
    }
    &.served {
      fill: $tint-light;
    }
  }
}

.PieChartSVG__circle {
  &.served {
    .isOnline & {
      animation: animServed ease-in-out 1.25s reverse;
    }
  }
}

.DonutChart__text--key.remaining .colorIcon-data,
.PieChartSVG__circle.remained {
  opacity: 0.4;
}

@keyframes animServed {
  to {
    stroke-dasharray: 0 100;
  }
}
