@import 'src/tokens';

.UploadStatusBar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-block: 1.25rem;
  padding-inline: 0.75rem;
  border-radius: $border-radius-s;
  box-shadow: $box-shadow-100;
  background-color: $base-bg;
  &__main {
    > .LoadingBar {
      width: 100% !important;
    }
  }
  &__file-info {
    display: flex;
    justify-content: space-between;
    padding: 0 0.25rem;
    flex-grow: 1;
  }
  &__percentage-text {
    text-align: center;
    min-width: 4rem;
  }
}
