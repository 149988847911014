@import 'src/mixins';

.IconButtons {
  @include animate-css(fadeInUp);
  &__header-container {
    height: 3.25rem;
    .OrderLines__Header & {
      margin-left: 0;
    }
    > .CollapseButton {
      #tw & {
        height: 3.25rem;
      }
    }
  }
}

#tw .SideMainContent__tablet {
  .IconButtons__list {
    .IconButton {
      min-width: 32.2%;
    }
  }
}
