@import 'src/tokens';

.ColorSwatches {
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3vw, 1fr));
    border-radius: $border-radius-m;
  }
}

.Swatch {
  $this: &;
  position: relative;
  margin: 0;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
  height: 50px;
  font-size: 0.75rem;
  transition: transform 0.2s ease;
  &:hover {
    #{$this}__button {
      display: block;
    }
  }
  &--main {
    height: 75px;
  }
  &__button {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
  }
}
