@import 'src/tokens';
@import 'src/mixins';

.ZipPreview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(2.5rem, 1fr));
  column-gap: 0.25rem;
  padding-block: 0.5rem;
  row-gap: 0.5rem;
  &__zip {
    padding-block: 0.35rem;
    padding-inline: 0.2rem;
    font-weight: normal;
    font-size: $text-size-xs;
    color: $base-text;
    background-color: $base-gray;
    border-radius: $border-radius-m;
  }
  &__header {
    font-size: $text-size-s;
    &-title {
      font-weight: bold;
    }
  }
  &__pagination-label {
    font-size: $text-size-xs;
  }
}
