@import 'src/tokens';

.CollapseButton {
  .fa {
    animation-iteration-count: 1;
    position: relative;
    transition: all 0.3s ease;
    transform: rotate(0deg);
  }
  &__icon {
    margin-left: auto;
    .CollapsedButton__text {
      visibility: hidden;
    }

    @media screen and (max-width: 860px) {
      padding-right: 1rem;
    }
  }
  &.CollapseButton__collapsed i.fa,
  &.CollapseButton__expanded:not(.icon-only) .fa-chevron-up {
    transform: rotate(180deg);
  }
  .CreateCampaignPage__steps & {
    .fa {
      display: none;
    }
  }
}
