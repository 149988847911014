@import 'src/tokens';

.RetargetingForm {
  .name {
    border: $border-width-thin solid $color-grey-400; // thick border detracts from the text, thinner is better
    font-size: $text-size-m;
    padding: rem(5) rem(12);
    height: 2.3rem;
    border-radius: $border-radius-s;
    min-width: 12ch;
    &.invalid {
      border: $border-width-thin solid $color-danger-500;
    }
  }
  .RetargetingFormLabel {
    min-width: 13.5%;
  }
}
