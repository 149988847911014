.PolygonMap {
  .mapbox {
    height: 68vh;
  }
}
.Theme__dark,
.Theme__darkBlue {
  .mapbox.leaflet-container {
    * {
      color: #000; // overrides white text from themes on map's white bg areas
    }
  }
}
