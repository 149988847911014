@import 'src/tokens';

.CreateCampaignHeader {
  &__details {
    margin-left: -0.45rem;
    min-width: 33.385rem;
  }
  &__Text-input {
    align-items: center;
    display: flex;
    min-width: 55%;
    min-height: 2.5rem;
    position: relative;
    max-width: 90%;
    span {
      display: inline-block;
      height: 100%;
      line-height: 2 !important;
      min-height: 2.25rem;
    }
    > .Button {
      position: absolute;
      right: 1.125rem;
      top: 0.625rem;
    }
    .Button.Button--has-icon-only {
      max-height: 2rem;
      max-width: 2rem;
    }
    &-view {
      justify-content: space-between;
      margin-top: 1.5rem;
    }
    .TextInput,
    .TextInput__input {
      min-height: 2.5rem;
    }
    // rounded on the right so the pencil/disk combo would look like it fit in the text input field
    .TextInput {
      background-color: $base-bg;
      border-radius: $border-radius-s 2rem 2rem $border-radius-s;
      padding-right: 2.5rem;
      margin: 0.25rem;
      min-height: 2.5rem;
      width: 100%;
    }
    .TextInput__input {
      font-size: $text-size-l;
      margin: 0;
      padding-left: 0.5rem;
    }
  }
  .Button__text {
    white-space: normal;
    span {
      line-height: 0.95;
    }
  }
  .Checkmark {
    position: relative;
    height: 2.25rem;
    margin-right: 0.3rem;
    width: 2.25rem;
  }
  .Button--m-size .Button__icon-wrapper {
    height: 2.5rem;
    width: 2.5rem;
  }
  &__editing__header {
    .DateTargetToggle__button--active {
      background-color: $color-primary-300;
    }
  }
}

.CreateCampaignHeader__details {
  filter: drop-shadow(1px 2px 4px #0005);
}

.PointerTip {
  top: 55px;
  &::before,
  &::after {
    border-color: transparent;
    border-style: solid;
    border-bottom-width: 11px;
    border-top-width: 11px;
    content: '';
    position: absolute;
    transform: rotate(-90deg);
    left: 1.865rem;
  }
  &::before {
    border-left: 10px solid $color-primary-050;
    top: -16px;
    z-index: 21;
  }
  &::after {
    top: -17px;
    border-left: 10px solid #0003;
    z-index: -2;
  }
}
