@import 'src/tokens';

.OrderLineImpressionsEdit {
  &__header {
    h2 {
      display: flex;
      align-items: center;
    }
    .TextHeader span.Text {
      color: $color-grey-300;
      padding-left: 0.5rem;
    }
  }
  &__name {
    color: $color-grey-500;
    padding-right: 0.5rem;
  }
  .Card {
    padding: 2rem;
  }
  &__body {
    margin: auto;
    .OrderLineImpressionsEdit {
      .Button {
        background-color: transparent;
        color: $base-text;
      }
    }
  }
  &__row {
    display: flex;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  &__amount,
  &__label {
    align-items: center;
    display: flex;
  }
  &__input,
  &__amount,
  .Button__text {
    font-family: 'Source Sans 3', sans-serif;
  }
  &__label .Button__text {
    display: block;
    padding: 0 0.25rem 0 0;
    width: 100%;
  }
  &__amount {
    justify-content: space-between;
    background: none;
    border-color: transparent;
    width: 11.25rem;
    button.Button {
      background: transparent;
      flex: 1;
      padding-inline: 0;
      height: 100%;
      min-width: auto;
      width: auto;
      .Button__text {
        width: 80%;
      }
      &:hover:not(:disabled) {
        background: transparent;
        color: $base-text;
      }
    }
    input,
    span {
      color: $base-info;
      border-color: transparent;
      padding: 0 0 0 0.5rem;
      min-width: 5.25rem;
    }
    .Button {
      .Button__icon-wrapper {
        border: 1px solid $color-primary-500;
        border-radius: 50%;
        color: $color-primary-500;
        height: 2rem;
        margin: 0.25rem 0 0.25rem 0.75rem;
        min-width: 2rem;
        padding: 0.125rem;
        transition: all 0.125s ease-in-out;
        width: 2rem;
      }
    }
    span.Button__text {
      color: $color-grey-400;
      font-size: 1.65rem;
      font-weight: 400;
      text-align: left;
    }
    .OrderLineImpressionsEdit__input {
      border-color: $color-secondary-500;
      font-size: 1.65rem;
      font-weight: 400;
      color: $color-secondary-500;
    }
  }
  &__label {
    text-align: right;
    .Button .Button__text {
      font-size: $text-size-ml;
    }
    .Button {
      color: $base-info;
      height: auto;
      padding-right: 0.125rem;
      text-align: right;
      width: 100%;
      &:active,
      &:focus,
      &:hover {
        &:not(:disabled) {
          background: transparent;
          color: $base-text;
        }
      }
    }
    &.is-editable {
      .Button {
        background: transparent;
        color: $base-text;
      }
    }
  }
  &__label__deployed {
    min-width: 71%;
  }
  &__label__review {
    min-width: 48%;
  }
}

.has-dollar-sign {
  .Button__text {
    position: relative;
    &::before {
      display: inline-block;
      position: relative;
      left: 0;
      content: '\0024';
      width: 1rem;
      height: 1rem;
    }
  }
}
