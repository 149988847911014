@import 'src/tokens';

// custom timing
$curve: cubic-bezier(0.65, 0, 0.45, 1);

.Checkmark {
  align-content: center;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  box-shadow: inset 0 0 0 $color-success-400;
  position: absolute;
  border-radius: 50%;
  display: flex;
  height: 55px;
  justify-content: center;
  stroke-width: 4;
  stroke: $color-white;
  stroke-miterlimit: 10;
  width: 55px;
  z-index: 20;
}

.Checkmark__circle {
  animation: stroke 0.6s $curve forwards;
  fill: none;
  stroke-dasharray: 165;
  stroke-dashoffset: 165;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: $color-success-400;
}

.Checkmark__check {
  animation: stroke 0.3s $curve 0.8s forwards;
  transform-origin: center;
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  to {
    box-shadow: inset 0 0 0 30px $color-success-400;
  }
}
