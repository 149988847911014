@import 'tokens.scss';

.Stats {
  &.ReportModal__report--online {
    display: flex;
    flex-direction: column;
    padding-block: 20px;
  }
  .flightDates-campaignInfo,
  .progressBar-flightDates {
    height: 35px;
    overflow: clip;
  }
  .stats-header {
    padding-bottom: 5px;
  }
  .campaignInfo-Stats {
    .status-campaignInfo {
      .today-Stats-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .indicator-status {
        border-radius: 50%;
        background: $color-grey-100;
        max-width: 15px;
        min-height: 15px;
        margin-left: 15px;
        &.draft {
          background: $color-secondary-600;
        }
        &.approved,
        &.deployed,
        &.serving {
          background: $color-success-600;
        }
        &.submitted,
        &.paused {
          background: $color-warning-600;
        }
        &.error {
          background: $color-danger-600;
        }
        &.finished,
        &.archived {
          background: $color-grey-100;
        }
      }
    }
    .flightDates-campaignInfo {
      grid-template-columns: minmax(min-content, 9%) minmax(700px, 1fr) minmax(
          min-content,
          9%
        );
      width: clamp(700px, 100%, 1060px);
      .FlightDate {
        padding-left: 8px;
      }
      .progressBar-flightDates {
        .daysRemaining-progressBar {
          bottom: 0;
          position: absolute;
          height: 38px;
          left: -0.8px;
          right: 0;
          top: 0;

          &::before {
            background-color: $tint-khaki-050;
            background-size: 5px 100%;
            background-image: repeating-linear-gradient(
              to right,
              $primer,
              $primer 1px,
              $tint-khaki-200 1px,
              $tint-khaki-100
            );
            content: '';
            height: 38px;
            max-width: 740px;
            min-width: 740px;
            opacity: 0.43;
            position: absolute;
            top: -3px;
            width: 100%;
          }
        }
        .daysRemaining-progressBar,
        .DaysRemain {
          max-width: 740px;
        }
        .DaysRemain {
          min-width: 125px;
          .Days {
            padding-inline: 8px;
          }
        }
      }
    }
  }
  .topLevel-Stats {
    grid-template-columns: repeat(auto-fit, minmax(240px, 29%));
    grid-auto-rows: minmax(80px, auto);
    padding-block: 15px;
  }
  .impressionsSpend-Stats {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  .dailyBreakdown-container-Stats {
    margin-top: 15px;
  }
  .highestServed-Stats {
    padding: 0 20px 15px 0;
    width: calc(100% - 73%);
  }
  .CampaignCreatives {
    width: 73%;
  }
  .TopLevelStat {
    max-width: 250px;
    min-width: 230px;
  }
  .TopLevelStat__icon,
  .details-TopLevelStat {
    height: 100%;
    justify-content: center;
    min-height: 60px;
    padding-block: 15px;
  }
  .TopLevelStat__icon {
    color: $color-tertiary;
    max-height: 60px;
    width: 60px;
    &.fa-home {
      margin-right: 10px;
      width: 50px;
    }
  }
  .topStatData,
  .stat-TopLevelStat {
    font-size: 30px;
  }
}

.loading {
  display: flex;
  padding: 48px;
  justify-content: center;
}
