@import 'src/tokens';
@import 'src/mixins';

.CreativesLibraryPage {
  height: 100%;
  padding-top: 0;
  &__Uploader {
    min-height: 15rem;
    background: $base-bg;
    width: auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-around;
    box-shadow: $box-shadow-300-dark;
    border-radius: $border-radius-m;
  }
  &__NativeBannerForm {
    min-height: 15rem;
    background: $base-bg;
    width: auto;
    display: flex;
    align-items: center;
    padding-left: 10px;
    justify-content: space-around;
    box-shadow: $box-shadow-300-dark;
    border-radius: $border-radius-m;
  }
  &__left-column {
    vertical-align: middle;
  }
  &__requirements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__right-column {
    vertical-align: middle;
  }
  &__status-container {
    flex: 1;
    max-width: 40rem;
    padding: 0 0.5rem;
    max-height: 20rem;
    margin-bottom: 1rem;
  }
  &__uploaded-files {
    max-height: 20rem;
    overflow-y: auto;
  }
  &__clear-button {
    color: $tint-gray-300;
  }
  &__not-found {
    background: $base-bg;
    color: $tint-gray-400;
    padding: 1rem;
    border-radius: $border-radius-m;
    box-shadow: $box-shadow-300-dark;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 30rem;
    margin: 2em auto;
  }
  &__text {
    @include text-body(1);
    text-align: center;
  }
  &__table-view {
    width: auto;
  }
}
