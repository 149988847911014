@import 'src/tokens';
@import 'src/mixins';

.ETIcon {
  // color for pathPrimary
  color: $color-primary;
  // color for pathSecondary
  fill: $color-tertiary;
  &:not(:only-child) + div {
    margin-left: 0.25rem;
  }
}
// flag colors from American Legion: https://www.legion.org/flag/questions-answers/91472/what-are-exact-shades-colors-blue-and-red#:~:text=The%20exact%20shades%20of%20blue,and%20%23BF0A30%20(red)
#political-use,
#advocacy-use {
  color: #bf0a30;
  fill: #002868;
}
