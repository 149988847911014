@import 'src/tokens';
@import 'src/mixins';

.AdTagViewer {
  $this: &;
  &__modal,
  .Modal__scrollable-content {
    display: flex;
    flex-direction: row;
    padding: 1.25rem;
    gap: rem(20);
    max-width: rem(950);
    overflow-x: auto;
    textarea {
      min-height: rem(290);
      min-width: rem(560);
    }
    &:hover {
      #{$this}__close-button {
        opacity: 1;
      }
    }
  }
  &__close-button {
    opacity: 0;
    display: flex;
    flex-direction: row-reverse;
    // make close button looks consistent with other component's close icon
    .Button {
      transition: all 0.2s ease;
      width: 1.75rem;
    }
  }
  &_buttons {
    .fa {
      color: $color-primary;
    }
  }
}
