@import 'src/tokens';

.AdSetFormButtonWrap[data-tooltip][aria-label] {
  &::after,
  &::before {
    display: none;
  }
}

.AdSetFormInputs__TextFields-buttons {
  min-width: 65%;
}

#tw {
  .BrandURL__checkbox {
    .Checkbox__wrapper-label {
      min-height: 2.5rem;
    }
  }
  .BrandURL {
    min-width: 20ch;
  }
  .BrandURL__text {
    display: block;
    max-width: 14ch;
  }

  @media screen and (min-width: 1401px) {
    .AdSetFormInputs__TextFields-buttons {
      flex-basis: 60%;
    }
    .AdSetFormInputs__CartButtons {
      flex-basis: 40%;
    }
  }

  @media screen and (min-width: 861px) and (max-width: 1400px) {
    .AdSetFormInputs__TextFields-buttons {
      min-width: 75%;
    }
    .AdSetFormInputs__CartButtons {
      flex: auto;
    }
    .AdSetFormButtonWrap .Button__text {
      display: none;
    }
    .ClickThroughWithUTM,
    .AdSetFormButtonWrap[data-tooltip][aria-label] {
      &::after,
      &::before {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}
