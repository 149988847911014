@import 'src/tokens';

.InfoPopover {
  &__popover-icon {
    cursor: pointer;
  }
  &__popover-container {
    background-color: $base-bg;
    box-shadow: $box-shadow-200;
    border-radius: $border-radius-m;
    color: $tint-gray-800;
    padding: 1rem;
  }
  .OrderLines__edit-ribbon & {
    .fa-info-circle {
      margin-left: -1.5rem;
    }
  }
}
