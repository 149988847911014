@import 'tokens';
@import 'mixins';
$typeFace: 'Source Sans 3', sans-serif;

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
}

.list-disc,
.list-decimal,
.list-square,
.list-roman {
  padding: 0.5rem 0.5rem 0.5rem 1.125rem;
}

// added style resets from Normalize.css
// https://github.com/necolas/normalize.css/blob/master/normalize.css

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

body {
  background-color: $base-bg;
  color: $base-text;
  font-family: $typeFace;
  height: 100%;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// add custom scrollbar styles to all elements
* {
  scrollbar-width: thin; // firefox only
  scrollbar-color: $tint-gray-200; // firefox only
  // chrome and safari
  &::-webkit-scrollbar {
    height: 0.45rem;
    width: 0.45rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $tint-gray-200;
    border-radius: 1.5rem;
    border: 0.125rem solid $tint-gray-050;
  }
}

/**
 * 1. Change the font styles in all browsers.
 */

button,
input,
optgroup,
select,
textarea {
  background: transparent;
  font-family: inherit; /* 1 */
  margin: 0;
  padding: 0;
}

/**
 * Show the overflow in Edge.
 */

button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  color: $base-info-500;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  &:hover {
    color: $base-info-700;
  }
}

.Theme__dark,
.Theme__darkBlue {
  a:not(.SideMenuItem) {
    color: $base-info-400;
    &:hover {
      color: $tint-light;
    }
  }
}

b,
strong {
  font-weight: $text-weight-bold;
}

svg {
  min-width: 1.5rem;
  height: auto;
  &.ETIcon:not(.ETIcon--full) {
    max-width: 2.35rem;
  }
}

.icons-wrap svg.ETIcon,
svg.ETIcon--full {
  max-width: 90%;
  padding: 0.75rem 0.5rem 0.25rem;
}

.App {
  height: 100vh;
  isolation: isolate;
  code,
  kbd,
  samp,
  pre,
  small {
    font-size: $text-size-sm;
    white-space: normal;
  }
}

body {
  .isSticky {
    padding-bottom: 10vh;
  }
  .StickyFooter,
  .StickyHeader {
    @include animate-css(slideDown);
    background-color: $base-bg;
    z-index: 50;
  }
  .StickyHeader {
    box-shadow: $box-shadow-200;
    position: sticky;
    top: 0;
  }
  .StickyFooter {
    box-shadow: $box-shadow-600;
    border-radius: 0 0 10px 10px;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 1rem;
    position: fixed;
    width: 99.9%;
  }
}

.ripples {
  @include ripples;
}

.child-stripe {
  &:nth-child(odd) {
    background-color: $color-grey-050;
  }
}

#tw .ButtonUrgent {
  background-color: $color-warning-600;
  border-color: $color-warning-600;
  color: $primer;
  &:active,
  &:focus,
  &:hover {
    background-color: $color-warning-400;
    border-color: $color-warning-400;
  }
}

body {
  .Tabs.TabSelected-venue-replay .Tab__nav {
    z-index: 1; // move the tab nav on vr map below the datepicker, so the month's scroll-button (chevron) can work
  }
  .react-datepicker-popper {
    &[data-placement^='bottom'] .react-datepicker__triangle {
      &::after,
      &::before {
        border-bottom-color: $color-primary-400;
      }
    }
    &[data-placement^='top'] .react-datepicker__triangle {
      &::after,
      &::before {
        border-top-color: $color-primary-400;
      }
    }
  }

  .OrderLineFlightDatesEdit__picker > .react-datepicker__navigation--next {
    right: 10px !important;
  }

  .react-datepicker {
    @include animate-css(fadeInUp);
    background-color: $base-bg;
    filter: drop-shadow(1px 3px 0.75rem $tint-dark-200);
    box-shadow: $box-shadow-400;
    border-color: $color-primary-400;
    color: $color-primary-400;
    font-family: $typeFace;
    font-size: $text-size-m;
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: $primer;
      color: $primer;
    }

    .react-datepicker__navigation {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      transition: all 0.5s ease-in-out;
      &.react-datepicker__navigation--previous {
        left: 7px;
        right: 0;
      }
      &.react-datepicker__navigation--next {
        right: 95px;
      }
      .react-datepicker__navigation-icon {
        &.react-datepicker__navigation-icon--previous {
          right: 0;
        }
        &.react-datepicker__navigation-icon--next {
          left: 2px;
        }
      }
      &:hover {
        background-color: $color-primary-700;
        .react-datepicker__navigation-icon::before {
          border-color: $tint-light;
          color: $primer;
        }
      }
    }

    li.react-datepicker__time-list-item {
      transition: all 0.5s ease-in-out;
    }
    li.react-datepicker__time-list-item--disabled {
      opacity: 0.5;
    }

    .react-datepicker__time-container {
      .react-datepicker__time {
        background-color: $primer;
      }
      .react-datepicker__time
        .react-datepicker__time-box
        ul.react-datepicker__time-list
        li.react-datepicker__time-list-item {
        height: 26px;
        padding-block: 0.5rem;
        &:hover,
        &-selected {
          background-color: $color-primary-400;
          color: $primer;
          font-weight: $text-weight-bold;
        }
      }
    }

    &__header {
      background-color: $color-primary-400;
      border-color: $color-primary-400;
      color: $color-primary-050;
      font-size: $text-size-s;
      padding: 0.125rem 0;
      &__month {
        padding: 1rem 0;
      }
      &__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;
        font-size: 1rem;
        padding: 0.2rem;
        transition: all 0.4s ease;
        &:hover {
          background-color: $color-primary;
        }
      }
    }
    .react-datepicker__time-container {
      border-color: $color-primary-200;
    }
    .react-datepicker__current-month,
    .react-datepicker__day-name {
      color: $tint-light;
    }
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $color-primary-700;
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      color: $color-primary-300;
    }
    .react-datepicker__current-month {
      height: 1.5rem;
      padding-top: 0.25rem;
    }
    .react-datepicker__day-names {
      margin: 0;
    }
    .react-datepicker__day {
      border-radius: 50%;
      height: 1.7rem;
      transition: all 0.4s ease;
      &.react-datepicker__day--today {
        border: $border-width-default solid $tint-khaki-300;
      }
      &--selected,
      &--keyboard-selected {
        background-color: $color-primary-400;
        color: $tint-light;
        font-weight: $text-weight-bold;
      }
      &:not(.react-datepicker__day--disabled):hover {
        background-color: $color-primary-500;
        color: $tint-light;
        cursor: pointer;
      }
    }
    .react-datepicker__day-name {
      line-height: 1;
    }
    .react-datepicker-time {
      &__header {
        color: $primer;
      }
      &-list-item {
        transition: all 0.4s ease;
        &:hover,
        &--selected {
          background-color: $color-primary-500;
        }
      }
    }
  }
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        border: $border-width-thin solid $tint-gray-300;
        border-radius: $border-radius-m;
        padding: 0.35rem 0.25rem;
        width: 100%;
      }
    }
  }
  .truncate {
    @include truncate(26ch);
  }
  .PermalinkButton {
    &.tooltip-success {
      &::before {
        min-width: 0;
        text-transform: uppercase;
      }
    }
  }
  .fa-warning {
    color: $color-warning-500;
  }
  .fa-danger {
    color: $color-danger-500;
  }
  .fa-sort-down {
    position: relative;
    top: -5px;
  }
  .fa-sort-up {
    position: relative;
    top: 5px;
  }
  .zebra-striped {
    border: $border-width-thin solid $tint-gray-200;
    border-right: none;
    > div {
      border-right: $border-width-thin solid $tint-gray-200;
    }
    &.grid-cols-4 {
      .HeaderCell,
      > div:nth-child(-n + 6) {
        background-color: $tint-khaki-100;
      }
      > div:nth-child(8n + 1),
      > div:nth-child(8n + 2),
      > div:nth-child(8n + 7),
      > div:nth-child(8n + 8) {
        background-color: $base-gray;
      }
    }
  }
  .MainAppContainer__mobile {
    .zebra-striped.grid-cols-4 {
      grid-template-columns: minmax(9.5rem, 20%) 0.5fr 0.5fr 0.5fr;
      overflow-x: auto;
    }
  }
  .ribbon {
    --f: 0.5rem; /* control the folded part */
    --r: 0.8rem; /* control the ribbon shape */
    align-items: center;
    background: $color-primary;
    border-right: var(--r) solid #0000;
    font-size: 12px;
    color: $primer;
    display: flex;
    position: absolute;
    justify-content: center;
    left: calc(-1 * var(--f));
    padding-inline: 0.25em;
    line-height: 1.1;
    &.top-left {
      top: 5px;
      border-bottom: var(--f) solid #0007;
      clip-path: polygon(
        calc(100% - var(--r)) 0,
        0 0,
        0 calc(100% - var(--f)),
        var(--f) 100%,
        var(--f) calc(100% - var(--f)),
        calc(100% - var(--r)) calc(100% - var(--f)),
        100% calc(50% - var(--f) / 2)
      );
    }
    &.bottom-left {
      bottom: 5px;
      border-top: var(--f) solid #0007;
      clip-path: polygon(
        calc(100% - var(--r)) 100%,
        0 100%,
        0 var(--f),
        var(--f) 0,
        var(--f) var(--f),
        calc(100% - var(--r)) var(--f),
        100% calc(50% + var(--f) / 2)
      );
    }
  }

  @media screen and (max-width: 1024px) {
    .truncate {
      max-width: 20ch;
    }
  }

  @media screen and (min-width: 1366px) {
    .truncate {
      margin-right: 0.5rem;
      max-width: 45ch;
    }
  }
}
