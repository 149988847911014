@import 'src/tokens';

$notify-dropdown-width: 19.625rem;

.badge {
  padding-inline: 9px;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.HeaderNotification {
  &__wrap {
    .HeaderDropdown__options {
      max-width: $notify-dropdown-width;
      min-width: $notify-dropdown-width;
    }

    .NotificationItem__list-group-li {
      &:nth-child(odd) {
        background-color: $tint-gray-050;
      }
    }
    .MainHeader__alert-indicator {
      right: 0.5rem;
      top: 0.65rem;
      transform: scale(0.75);
    }
    button.HeaderItem {
      position: relative;
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
  &.dropdown-menu {
    position: relative;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    list-style: none;
    background-clip: padding-box;
    &.Extended-dropdown {
      flex-direction: column;
      padding: 0;
      width: 100%;
      max-width: 27rem;
      min-width: $notify-dropdown-width;
    }
    .NotificationItem__body {
      align-items: center;
      font-size: $text-size-l;
      font-weight: $text-weight-normal;
      display: flex;
      gap: 0.5rem;
      position: relative;
      > .fa {
        transform: scale(1.25);
      }
    }
    .NotificationItem__time {
      font-size: $text-size-xs;
      flex: 1 auto;
      margin-right: 1rem;
      .time {
        font-weight: $text-weight-light;
        margin-left: auto;
      }
    }
    .Extended-dropdown__section-footer {
      color: $color-grey-800;
    }
    .status {
      font-size: $text-size-s;
      font-weight: $text-weight-normal;
      text-transform: uppercase;
      &.new {
        color: $base-info-300;
      }
    }
    .NotificationItem__body .fa:not(.fa-ellipsis-v),
    .NotificationItem__text {
      position: absolute;
    }

    .NotificationItem__menu-button,
    .ReadMessage,
    .time,
    .NotificationItem__text {
      color: $color-grey-700;
      .Theme__dark &,
      .Theme__darkBlue & {
        color: $tint-light-700;
      }
    }
    .ReadMessage {
      font-size: $text-size-ml;
      line-height: 1.2;
      padding-block: 0.5rem;
      padding-inline: 0.125rem;
      p {
        padding-top: 0.5rem;
      }
    }
    .NotificationItem__text {
      flex: 1 auto;
      line-height: 1;
      padding-right: 2rem;
      pointer-events: none;
      &:nth-child(2) {
        padding-left: 2.125rem;
      }
    }
    .NotificationItem__menu-button {
      flex: 1 100%;
      width: 100%;
      .fa-ellipsis-v {
        height: 2.5rem;
        width: 1.25rem;
      }
      &:disabled {
        &:active,
        &:focus,
        &:hover {
          .fa {
            background: transparent;
          }
        }
        .fa {
          &::before {
            display: none;
          }
        }
      }
    }
    .Extended-dropdown__section {
      display: flex;
      flex: 1 auto;
      .Extended-dropdown__section-header {
        color: $color-grey-500;
        font-size: $text-size-xxl;
        font-weight: $text-weight-semibold;
        padding-block: 0.5rem;
        padding-left: 0.25rem;
      }
    }
    .Extended-dropdown__section--list {
      flex: 1 1 auto;
      max-height: 40vh;
      padding: 0;

      .NotificationItem__list-group {
        display: flex;
        flex-direction: column;
        flex: 1 100%;
        gap: 0.125rem;
        padding-left: 0;
        margin-bottom: 0;
      }
      .NotificationItem {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
      .NotificationItem__menu-button {
        padding-block: 0.25rem;
      }
      .NotificationItem__list-group-li {
        border-bottom: 1px solid $tint-gray-200;
        position: relative;
        display: block;
        padding: 0 1rem 0.5rem 1rem;
        width: 100%;
        text-align: inherit;
        .fa-stack {
          position: relative;
          display: inline-block;
          width: 2em;
          height: 2.5em;
          line-height: 2em;
          vertical-align: middle;
        }
      }
    }
  }
}
