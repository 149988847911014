@import 'tokens.scss';

.DailyBreakdown {
  &.isOnline {
    min-height: 12vh; // make sure the height is tall to force scroll/dropdown on modal
  }
  .header-DailyBreakdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .content-DailyBreakdown {
    display: flex;
    align-items: center;

    .directionBtn-content {
      padding: 0;
      border: none;
      &.is-disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
    .week-content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, minmax(75px, 1fr));
      gap: 5px;
    }
  }
}

.DayContainer {
  display: flex;
  flex-direction: column;
  .flexContainer-DayContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .container-flexContainer {
      transition: all 0.2s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px;
      background: $color-secondary-500;
      border-radius: 5px;
      .upperStats-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .grouping-upperStats {
          &:last-of-type {
            text-align: right;
          }
        }
      }
      .lowerStat-container {
        text-align: center;
        margin-block: 5px;
      }
    }
  }
  .value-grouping {
    font-size: $text-size-m;
  }
  .label-grouping {
    font-size: $text-size-xs;
  }
  .lowerStat-container {
    line-height: 1.1;
    .value-grouping {
      font-size: $text-size-xxl;
    }
    .label-grouping {
      font-size: $text-size-m;
    }
  }
  p {
    line-height: 1;
  }
  .dashboard-title-header,
  .value-grouping,
  .day-date {
    font-weight: $text-weight-bold;
  }
  .date-DayContainer {
    margin-top: 4px;
    .day-date,
    .date-date {
      text-align: center;
    }
  }
}
