@import 'src/tokens';
@import 'src/mixins';
@import 'src/GlobalStyles/placeholders';

#tw .CampaignSelect {
  display: flex;
  flex-direction: column;
  width: 17rem;
  max-height: 80vh;
  &__camp-list {
    overflow-y: auto;
    max-height: 100%;
    margin-top: 0.2rem;
    .Pagination {
      background-color: $color-primary-100;
      margin-top: 1rem;
      padding-block: 0.65rem 1rem;
      .Button--disabled {
        visibility: hidden;
      }
    }
    > button {
      @extend %text-color-states;
      align-items: center;
      display: flex;
      margin-inline: 0.25rem;
      padding-block: 0.45rem;
      transition: all ease-in 0.2s;
    }
  }
  &__loading {
    background-color: rgba(255, 255, 255, 0.75);
  }
}
