// @use "sass:math";
// @import './Assets/mixins/animate.scss';
@import 'src/tokens';
@import 'src/mixins';

// color pattern for menu elements, hovers
%text-color-hover {
  background-color: $base-info-050;
  border-color: transparent;
  border-radius: $border-radius-2xl;
  color: $base-info;
  font-size: $text-size-ml;
  font-weight: $text-weight-bold;
  margin-top: 0.125rem;
  padding-block: 0.5rem;
  transition: all 0.3s ease-out;
  * {
    color: $base-info;
    font-weight: $text-weight-bold;
  }
}
%text-color-states {
  align-items: center;
  border: $border-width-thin solid transparent;
  border-radius: 0;
  color: $tint-gray-700;
  display: flex;
  margin-top: 0.125rem;
  padding: 0.5rem;
  gap: 0.5rem;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  * {
    font-size: $text-size-ml;
    transition: all 0.3s ease-out;
  }
  .fa {
    color: $color-grey-400;
    display: flex;
    justify-content: center;
    min-width: 1.125rem;
    width: 1.125rem;
  }
  .Button__text {
    color: $tint-gray-700;
    line-height: 0.95;
    text-transform: none;
  }
  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: $base-info-050;
    border-color: transparent;
    border-radius: $border-radius-2xl;
    color: $base-info;
    font-weight: $text-weight-bold;
    .Button__text,
    span {
      color: $base-info;
      font-weight: $text-weight-bold;
    }
    .fa {
      color: $base-info-400;
    }
  }
}

.Theme__dark,
.Theme__darkBlue {
  %text-color-states {
    color: $tint-light-700;
    .fa {
      color: $tint-light-700;
    }
    &:active,
    &.active,
    &:focus,
    &:hover {
      background-color: $base-info-050;
      color: $base-info-300;
      .fa,
      span {
        color: $base-info-300;
      }
    }
  }
}

%StatsWithRules {
  display: flex;
  justify-content: space-between;
  padding-block: 0.35rem 0.37rem;
  &:not(:last-child) {
    border-bottom: $border-width-thin dotted $tint-gray-300;
  }
  > span:last-child {
    line-height: 0.9;
    text-align: right;
    word-break: break-all;
  }
}
.Stats__rules {
  @extend %StatsWithRules;
}
