@import 'src/tokens';
@import 'src/mixins';

.BannerForm {
  &__Buttons {
    .CreativeUploader,
    .IconButton {
      min-height: 10.25rem;
    }

    @media screen and (max-width: 1024px) {
      .CreativeUploader__Title,
      .CreativeUploader__ContentTitle,
      .CreativeUploader__Requirements__UploadText {
        line-height: 1;
        padding-bottom: 0.5rem;
        text-align: center;
      }
    }
  }
  + div {
    @include animate-css(fadeInUp);
  }
}
