.TermsOfServicePage {
  &__agree-checkbox {
    .Checkbox {
      width: 100% !important;
    }
    .Checkbox__wrapper-label {
      width: 100% !important;
      max-width: 500px;
      align-items: flex-start !important;
    }
    .LabelMain {
      padding: 0 !important;
    }
  }
}
