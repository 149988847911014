@import 'tokens';
@import 'mixins';

$max: 31rem;
$right: 28%;
$left: calc(100% - (#{$right} + 3.75rem));

.BillingPage,
.OrgSettingsPage {
  .DetailItem {
    color: $base-text;
    display: flex;
    line-height: 1.1;
  }
  .CCListItem__remove-wrap {
    z-index: 105;
  }
  .OrgSettingsPage__product-label {
    justify-content: flex-start;
    min-height: 4rem;
    min-width: 9rem;
  }
  .NotEnabledTag {
    .ribbon {
      background-color: $color-warning-600;
      bottom: -5px;
      left: -20px;
      .fa {
        transform: scale(0.6);
      }
    }
  }
  &__main-col {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    gap: 1.25rem;

    @media screen and (max-width: 1024px) {
      max-width: 100%;
      min-width: 100%;
    }

    @media screen and (min-width: 1025px) {
      max-width: calc(100% - #{$max});
      min-width: $left;
    }
  }
  &__right-col {
    display: flex;
    flex: 1 auto;
    flex-direction: column;
    gap: 1.25rem;
    max-width: $max;

    @media screen and (max-width: 1024px) {
      gap: 1rem;
      max-width: 100%;
      min-width: 100%;
      > div {
        column-gap: 1.5rem;
        .MainAppContainer__tablet & {
          flex: 1 49%;
        }
        .MainAppContainer__mobile & {
          flex-direction: column;
          flex: 1 100%;
        }
        .OrgSettingsPage__contact {
          border: none;
          margin: 0;
          padding: 1rem 0 0 0;
        }
      }
    }

    @media screen and (min-width: 1025px) {
      min-width: clamp(12.5rem, 14.5vw + 6rem, 17rem);
      width: $right;
    }
  }
  .Table {
    .Button {
      max-width: 9.25rem;
    }
    .break-all .TableCell__inner-container {
      font-size: $text-size-s;
      min-width: 8rem;
    }
  }
  .Select .Select__select {
    border-radius: 1rem;
    padding-inline: 0.65rem 0.35rem;
    white-space: nowrap;
  }
  .DetailItem {
    &:first-child {
      &:nth-last-child(2),
      &:nth-last-child(2) ~ .DetailItem {
        width: 49%;
      }
    }
    &:first-child {
      &:nth-last-child(3),
      &:nth-last-child(3) ~ .DetailItem {
        width: 30%;
      }
    }
    &:first-child {
      &:nth-last-child(4),
      &:nth-last-child(4) ~ .DetailItem {
        flex-direction: column;
        width: 25%;
        .DetailSubheader {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.fa.fa-info-circle {
  color: $base-info-200;
}

.DetailSubheader,
.RightVerticalPipe {
  border-right: $border-width-thin solid $tint-gray-400;
  margin-right: 0.75rem;
  padding-right: 0.75rem;
}

.child-org > .RightVerticalPipe {
  border: none;
}

.DetailSubheader {
  font-weight: $text-weight-bold;
}

.OrgSettingsPage {
  .OrgSettingsPage__logo {
    min-height: 8rem;
  }
  &__header {
    @media screen and (min-width: 1025px) {
      width: $left;
    }
  }
  &__contact {
    border-bottom: $border-width-thin solid $tint-gray-200;
    margin-bottom: 0.5rem;
    padding-bottom: 1.5rem;
  }
  &__org-users {
    .TableHeader__cell:nth-child(5) {
      width: 13rem;
    }
    .TableCell__flex-container .Button {
      margin: 0;
    }
  }
  &__right-col {
    @media screen and (min-width: 1025px) {
      margin-top: -5.25vh;
    }
    .LogoBlock__wLogo {
      .LogoImageWrap {
        margin-bottom: -1rem;
      }
    }
  }
}

#tw {
  .OrgSettingsPage .DetailItem {
    &:first-child,
    &:first-child + .DetailItem,
    &:last-child {
      max-width: 15%;
    }
    &:first-child {
      &:nth-last-child(5),
      &:nth-last-child(5) ~ .DetailItem {
        flex-direction: column;
        width: 20%;
        .DetailSubheader {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      min-width: 24%;
    }
  }
}

.ContactForm {
  @include animate-css(fadeIn);
}

.BillingInfoInput,
.PaymentInfoInput {
  .FormItem__label {
    &[for='firstName'],
    &[for='lastName'],
    &[for='address'],
    &[for='address2'],
    &[for='country'],
    &[for='city'],
    &[for='cardNumber'],
    &[for='cardNickname'] {
      justify-self: flex-end;
      text-align: right;
      width: 5.75rem;
    }
  }
  .FormItem__textinput-message input {
    padding-inline: 0.45rem;
    height: 2.5rem;
  }
}

.EditableSection__label {
  padding-block: 0.75rem 0.5rem;
}

.EditableSection__body,
.EditableSection__body > .FormFields {
  display: flex;
}

.EditableSection__body {
  flex-direction: column;
  > .FormFields {
    align-items: center;
    justify-content: flex-start;
    padding-block: 1.25rem;
    padding-inline: 0.5rem;
    &:nth-child(odd) {
      background-color: $base-gray;
    }
  }
  .ContactBlock__label {
    flex: 1 auto;
    text-align: right;
    width: 30%;
  }
  .ContactBlock__value {
    font-weight: $text-weight-bold;
    text-align: left;
    width: calc(100% - 2.35rem);
    word-break: break-word;
  }
}

@media screen and (max-width: 1320px) {
  body {
    .OrgHierarchy__card-content {
      align-items: flex-start;
      flex-direction: column;
      > .RightVerticalPipe {
        border: none;
      }
    }
  }
}

#tw .MainAppContainer__mobile {
  .CreditCard__list-wrap {
    overflow: auto;
  }
  .CCListItem__credit-card--wrap {
    width: 130%;
    .Default-label {
      left: -57px;
      top: -16px;
    }
  }
  .CCListItem__remove-wrap {
    top: -8px;
  }
  .BillingPage__details,
  .DetailsSection {
    flex-wrap: wrap;
    padding: 0;
    .DetailItem {
      border: none;
      flex: 1 50%;
      max-width: 49.9%;
      margin: 0;
      min-width: 49.9%;
      padding: 1rem 0;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        border-bottom: $border-width-default solid $base-gray;
      }
      &:nth-child(1),
      &:nth-child(3) {
        border-right: $border-width-default solid $base-gray;
      }
    }
  }
  .BillingPage__details {
    .DetailItem {
      padding: 0.75rem 0;
      flex: 1 100%;
      max-width: 100%;
      margin: 0;
      min-width: 100%;
    }
  }
  .BillingPage,
  .OrgSettingsPage {
    .Button {
      margin-block: 0.5rem;
      margin-inline: auto;
      padding-inline: 0.75rem;
    }
  }
  .OrgSettingsPage {
    .OrgSettingsPage__header,
    .OrgSettingsPage__label,
    .OrgSettingsPage__label .flex,
    .OrgSettings_cpm-toggle,
    .OrgHierarchy .OrgHierarchy__current-org--wrap {
      flex-direction: column;
      justify-content: center;
    }
    .OrgSettingsPage__header,
    .OrgSettingsPage__label,
    .OrgSettingsPage__label .flex,
    .OrgSettings_cpm-toggle {
      span {
        text-align: center;
      }
    }
    .OrgCard {
      padding-inline: 0.25rem;
    }
    .ChildOrg__link-text {
      @include truncate(22ch);
    }
    .ChildOrgID__label {
      display: none;
    }
    .RightVerticalPipe {
      border: none;
      margin: 0.25rem 0;
      padding: 0;
    }
    .OrgSettingsPage__label {
      gap: 0.5rem;
      .flex {
        gap: 0;
      }
    }
    .OrgSettingsPage__product-label {
      min-width: 6rem;
    }
  }
}

.TOSList__table {
  td:nth-child(2),
  th:nth-child(2) {
    max-width: 4.2rem;
    .TableCell__inner-container {
      min-width: unset !important;
    }
  }
  td:nth-child(3),
  th:nth-child(3) {
    max-width: 4.8rem;
    .TableCell__inner-container {
      min-width: unset !important;
    }
  }
}