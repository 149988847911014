@import 'src/tokens';

.InvoiceStatusIndicator {
  display: flex;
  align-items: center;
  &__icon {
    color: $color-grey-600;
    min-width: 1rem;
    text-align: center;
    &--yellow {
      color: #e2bb37 !important;
    }
    &--green {
      color: $color-success-400 !important;
    }
    &--red {
      color: $color-danger-500 !important;
    }
    &--blue {
      color: $color-secondary-500 !important;
    }
  }
}
