@import 'src/tokens';

#tw .OrderlineIndicator {
  &__icon {
    min-width: 1.45rem;
    text-align: center;
    &--orange {
      color: $color-warning-400;
    }
    &--green {
      color: $color-success-400;
    }
    &--red {
      color: $color-danger-500;
    }
    &--blue {
      color: $color-secondary-500;
    }
    &.fa-list-alt {
      margin-left: -1px;
      transform: scale(0.85);
    }
  }
}
