@import 'src/tokens';

.AudienceColumnSelector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: $tint-gray-700;
  position: relative;
  overflow-x: auto;
  max-width: 100%;
  &__file-info-msg {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: $border-radius-m;
    &--error {
      background-color: $color-danger-050;
    }
    &--success {
      background-color: $color-success-050;
    }
  }
  &__table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    &--has-header {
      tbody {
        tr:first-of-type {
          background-color: $color-grey-100;
          border-bottom: 2px solid $tint-gray-400;
        }
      }
    }
    .TableHeader__cell {
      padding: 0;
    }
  }
  &__select-wrapper {
    padding: 1rem 0.4rem 1rem 0;
    flex-grow: 1;
    &--required-column {
      * {
        color: inherit;
      }
      // TODO: Update Select to allow something like this
      .Select__select {
        border-color: $color-success-600;
        background-color: $color-success-050;
      }
    }
  }
  &__btn-container {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0 1.5rem 0;
  }
  &__uploading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $tint-dark-100;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4rem;
    .UploadStatusBar {
      flex-grow: 1;
    }
    .Theme__dark &,
    .Theme__darkBlue & {
      background-color: $tint-dark-700;
    }
  }
}
