.Error500 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--text-on-grey-050);
  &__main {
    font-weight: bold;
  }
}
