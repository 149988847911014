@import 'src/tokens';
@import 'src/mixins';

$min-height: rem(900);

.PageContent {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-block: 1vh 0;
  padding-inline: clamp(10px, calc(0.625rem + ((1vw - 1.4px) * 2.381)), 40px);
  position: relative;
  right: 0;
  top: 0;
  > [class*='__header'] {
    padding-bottom: 0.75rem;
  }
  .PageContent {
    background: none;
    padding: 0;
    min-height: inherit;
    width: 100%;
  }
  &:not(.Dashboard) {
    .PageContent {
      padding: 0;
      > *:first-child {
        padding-top: 0;
      }
    }
  }
  .headerSub {
    align-items: center;
    padding-bottom: 1.25rem;
    #tw & {
      .CollapseButton,
      .CollapseButton__icon {
        flex: 1 auto;
      }
      .CollapseButton {
        padding-bottom: 0;
        .CollapseButton__back-link {
          margin: 0 auto 0 0;
        }
      }
    }
  }

  @media screen and (min-width: 480px) {
    width: clamp(
      calc(100vw - 12.5rem),
      calc(100vw - 15rem),
      calc(100vw - 16rem)
    );
  }

  @media screen and (min-width: 1640px) {
    min-width: calc(100% - 1.5vw);
    #tw & {
      .MainWithSide__main {
        min-width: calc(100% - 17.5vw);
      }
    }
  }
}
