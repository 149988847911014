@import 'src/tokens';
@import 'src/mixins';

.AlertsPage {
  overflow-x: clip;
  overflow-y: auto;
  .Alerts__breadcrumbs {
    .Breadcrumb {
      font-size: $text-size-xxl;
      font-weight: $text-weight-bold;
      text-transform: uppercase;
    }
  }
  .TableCell--right {
    padding: 0;
    .TableCell__flex-container {
      position: relative;
    }
  }
  .FormItem__textinput-message {
    position: relative;
  }
  .AttrTags-allowed {
    display: flex;
    flex-wrap: wrap;
    font-size: $text-size-sm;
    gap: 0.25rem;

    code {
      flex-basis: 100%;
      font-size: $text-size-s;
    }
    i:not(.fa) {
      font-style: italic;
    }
  }
  #tw & {
    margin: auto;
    width: 90%;
    .Button:not(.Alert__close),
    .AlertsPage__add-alert-btn {
      text-align: center;
      max-width: 8rem;
    }

    .Button.Alert__show-alert {
      &:active,
      &:focus,
      &:hover {
        &:not(:disabled) {
          background: transparent;
          color: $tint-khaki-300;
        }
      }
    }
    .AlertsList__action-dropdown {
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      min-height: 4.5rem;
      min-width: 9.5rem;
      position: relative;
      .Button__text {
        color: $base-info;
        font-size: $text-size-m;
        text-transform: initial;
      }
      .Button.Dropdown_link {
        background: transparent;
        min-height: 1rem;
        min-width: 7rem;
        padding-block: 0;
        padding-inline: 0.75rem;
        text-align: left;
        transition: all 0.5s ease-in-out;
        width: 95%;
        &:active,
        &:focus,
        &:hover {
          background-color: $base-info-050;
          .Button__text {
            color: $base-info;
            font-weight: $text-weight-bold;
          }
        }
      }
      .AlertDropDown__list {
        padding: 0.25rem;
      }
      .AlertDropDownMenu {
        position: absolute;
        left: 0;
      }
      .fa {
        display: block;
        height: max-content;
        visibility: visible;
      }
      .Button__icon-wrapper {
        height: max-content;
      }
    }
    .AlertsList-action-button {
      margin-left: auto;
      min-height: 3.125rem;
      width: 1.5rem;
    }
    .AlertsPage__add-alert-btn {
      margin-left: auto;
    }
    .TableCell__flex-container > .Button {
      max-height: 3.25rem;
      min-height: 3.25rem;
    }

    .AlertType__form-item .FormItem__wrap {
      flex-direction: row;
      gap: 3rem;
    }
    .AlertTextInputField {
      background-color: $primer;
      padding: 0.65rem;
    }
    textarea,
    .TextInput__input {
      border: $border-width-thin solid $tint-gray-300;
      font-size: $text-size-lg;
      width: 100%;
    }
    .AlertType__radio-group {
      margin-top: -0.125rem;
      padding-top: 0;
      .RadioButton__wrapper-label {
        column-gap: 0.5rem;
        margin: 0;
        padding: 0.45rem;
        padding-right: 0.75rem;
      }
    }
    .AlertType__radio-group .LabelMain,
    .FormItem__label {
      font-size: $text-size-xl;
    }
  }
  th .TableHeader__label-container {
    font-size: $text-size-l;
    padding-block: 0.25rem;
  }
  .AlertDropDown__list {
    border-radius: 0.25rem;
    position: relative;
    z-index: 5;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border: 0 solid var(--base-bg);
      border-left-width: 0.375rem;
      border-top-width: 0.375rem;
      border-top-color: #0000;
      border-bottom-width: 0.375rem;
      border-bottom-color: #0000;
      bottom: 50%;
      right: -5px;
      transform: translateY(50%);
      pointer-events: none;
      position: absolute;
      z-index: 20;
    }
  }
  .AlertPageForm__add-alert,
  .AlertPageForm__edit-alert {
    width: 100%;
  }

  .FormItem__label {
    font-weight: $text-weight-bold;
    padding-block: 0.5rem;
    z-index: 30;
  }
  .TextInput,
  .TextInput__input {
    border: transparent;
    height: 100%;
    min-height: 3rem;
  }

  .Alerts__table {
    .TableHeader__cell,
    .TableCell {
      border-right: $border-width-thin solid $tint-gray-300;
      margin-right: 0.5rem;
      padding-inline: 1rem;

      &:last-child {
        border-width: 0;
        padding-inline: 0 1.25rem;
      }
    }

    .TableHeader__cell {
      font-weight: $text-weight-bold;
      text-transform: uppercase;
    }
    .TableRow .TableCell__flex-container {
      min-height: 6rem;
    }
    .TableCell--right {
      max-width: 5rem;
    }
  }
  .Alert__detail-content {
    label,
    span,
    strong {
      font-size: $text-size-l;
    }
    .DetailItem {
      .DetailSlug {
        width: 8rem;
      }
      span {
        width: calc(100% - 8rem);
      }
    }
  }
  .AlertMessage__form-item,
  .AlertTitle__form-item {
    .FormItem__wrap {
      display: flex;
      flex-direction: column;
    }
  }
}
