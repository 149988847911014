@import 'src/tokens';

.AudienceUpload {
  &__header-text {
    font-size: $text-size-l;
    pointer-events: none;
    z-index: 20;
    .SVG-wrap {
      margin-left: -0.125rem;
      padding-right: 0.125rem;
      .fa {
        max-width: 2.5rem;
      }
    }
  }
  &__iconInnerContainer {
    .IconButton {
      &__title {
        font-size: $text-size-m;
      }
      &__subTitle {
        font-size: $text-size-xs;
      }
      &__ComingSoon {
        font-size: $text-size-l;
      }
      .IconButton__icon-wrap svg {
        max-width: 3.25rem;
      }
    }
  }
  .CollapsibleContainer__BodyRef {
    position: relative;
    z-index: 21;
  }
  .fa-chevron-up {
    display: none;
  }
}

.UploaderContainerHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  &__title {
    font-size: x-large;
    font-weight: bold;
  }
  &__subtitle {
    color: $color-grey-500;
    font-size: large;
  }
}

.UploaderWindowTitle {
  margin: 0.5rem 0;
  color: $color-grey-500;
  font-size: large;
}

#tw {
  @media screen and (min-width: 721px) and (max-width: 792px) {
    .AudienceUpload {
      &__iconInnerContainer {
        .IconButton {
          max-width: 18.5%;
        }
      }
    }
  }
}
