@import 'src/tokens';
@import 'src/mixins';

.CampaignSettingsForm {
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: bold;
    font-size: $text-size-xxl;
    color: $color-grey-600;
    margin: 1rem;
  }
  &__subTitle {
    font-size: $text-size-xl;
    color: $color-grey-500;
    margin-top: 1.5rem;
  }
}

.OrderLines {
  @include animate-css(fadeInUp);
}
