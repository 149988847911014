@import 'src/tokens';
@import 'src/mixins';

.PlaceholderPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__comingSoon {
    color: $color-grey-600;
    font-size: $text-size-xxxl;
    font-weight: bold;
  }
  &__logo {
    @include animate-css(fadeInUp);
    height: 40vh;
    width: 40vw;
    min-height: 300px;
    min-width: 300px;
    max-width: 500px;
    max-height: 500px;
    padding: 1rem;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
