@import 'src/tokens';
@import 'src/mixins';

.AudienceCard {
  display: flex;
  background-color: $color-grey-050;
  padding: 1rem;
  .Checkbox {
    height: unset;
    max-width: 100%;
    &__wrapper-label {
      align-items: unset;
    }
    &__input-wrapper {
      margin-top: 0.4rem;
    }
  }

  @media only screen and (max-width: 1024px) {
    div:empty {
      display: none;
    }
    flex-direction: column;
    [class$='__icon'] {
      align-items: center;
    }
    [class$='remove-btn'] {
      display: flex;
      align-items: flex-end;
      .Button {
        margin-left: auto;
      }
    }
  }
  &__icon {
    color: $color-grey-300;
  }
  &__remove-btn {
    margin: -0.5rem 0 0 -0.6rem;
  }
  &__errored {
    display: flex;
    align-items: center;
    i {
      color: $color-danger;
    }
    span {
      font-size: $text-size-s;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
  }
  &__name-wrapper {
    .Text {
      @include truncate(100%);
    }
  }
}
