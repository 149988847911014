$base-bg: var(--base-bg); // background for app
$base-text: var(--base-text); // base-text -- body text at body
$base-gray: var(--base-gray); // base-gray for mainAppContainer, so it can be changed per theme
$base-info: var(--base-info-500); // blue shades for light/dark themes for info bg
$base-info-050: var(--base-info-050); // light/darkish blue for light/dark themes for info bg
$base-info-100: var(--base-info-100);
$base-info-200: var(--base-info-200);
$base-info-300: var(--base-info-300);
$base-info-400: var(--base-info-400);
$base-info-500: var(--base-info-500); // light/dark blue for themes for info text
$base-info-600: var(--base-info-600);
$base-info-700: var(--base-info-700);
$base-info-800: var(--base-info-800);
$base-info-900: var(--base-info-900);
$highlight-bg: var(--highlight-bg); // background to highlight elements, e,g, header bg
$primer: var(--primer); // almost-black/solid-white backgrounds for dark/light themes

$tint-khaki: var(--tint-khaki); // same color as ET Gold
$tint-khaki-050: var(--tint-khaki-050); // tints for ET Gold - 10% in brand guide
$tint-khaki-100: var(--tint-khaki-100);
$tint-khaki-200: var(--tint-khaki-200);
$tint-khaki-300: var(--tint-khaki-300);
$tint-khaki-400: var(--tint-khaki-400);
$tint-khaki-500: var(--tint-khaki-500);
$tint-khaki-600: var(--tint-khaki-600);
$tint-khaki-700: var(--tint-khaki-700);
$tint-khaki-800: var(--tint-khaki-800);
$tint-khaki-900: var(--tint-khaki-900);
$text-on-tint-khaki: var(--text-on-tint-khaki);

$tint-gray: var(--tint-gray); // basic solid gray
$tint-gray-050: var(--tint-gray-050); // tints of gray to lighten/darken text/bg
$tint-gray-100: var(--tint-gray-100);
$tint-gray-200: var(--tint-gray-200);
$tint-gray-300: var(--tint-gray-300);
$tint-gray-400: var(--tint-gray-400);
$tint-gray-500: var(--tint-gray-500);
$tint-gray-600: var(--tint-gray-600);
$tint-gray-700: var(--tint-gray-700);
$tint-gray-800: var(--tint-gray-800);
$tint-gray-900: var(--tint-gray-900);
$text-on-tint-dark: var(--text-on-tint-dark);

$tint-light: var(--tint-light); // similar to white
$tint-light-900: var(--tint-light-900); // tints of whites to lighten backgrounds in themes
$tint-light-800: var(--tint-light-800);
$tint-light-700: var(--tint-light-700);
$tint-light-600: var(--tint-light-600);
$tint-light-500: var(--tint-light-500);
$tint-light-400: var(--tint-light-400);
$tint-light-300: var(--tint-light-300);
$tint-light-200: var(--tint-light-200);
$tint-light-100: var(--tint-light-100);
$tint-light-050: var(--tint-light-050);
$text-on-tint-light: var(--text-on-tint-light);

$tint-dark: var(--tint-dark); // basic solid dark gray
$tint-dark-900: var(--tint-dark-900); // tints of whites to darken backgrounds in themes
$tint-dark-800: var(--tint-dark-800);
$tint-dark-700: var(--tint-dark-700);
$tint-dark-600: var(--tint-dark-600);
$tint-dark-500: var(--tint-dark-500);
$tint-dark-400: var(--tint-dark-400);
$tint-dark-300: var(--tint-dark-300);
$tint-dark-200: var(--tint-dark-200);
$tint-dark-100: var(--tint-dark-100);
$tint-dark-050: var(--tint-dark-050);
$text-on-tint-dark: var(--text-on-tint-dark);

$color-white: var(--color-white); // similar to #fff

$color-grey-050: var(--color-grey-050); // solid shades of grays different in each theme
$color-grey-100: var(--color-grey-100);
$color-grey-200: var(--color-grey-200);
$color-grey-300: var(--color-grey-300);
$color-grey-400: var(--color-grey-400);
$color-grey-500: var(--color-grey-500);
$color-grey-600: var(--color-grey-600);
$color-grey-700: var(--color-grey-700);
$color-grey-800: var(--color-grey-800);
$color-grey-900: var(--color-grey-900);

$color-primary-050: var(--color-primary-050);
$color-primary-100: var(--color-primary-100);
$color-primary-200: var(--color-primary-200);
$color-primary-300: var(--color-primary-300);
$color-primary-400: var(--color-primary-400);
$color-primary-500: var(--color-primary-500);
$color-primary-600: var(--color-primary-600);
$color-primary-700: var(--color-primary-700);
$color-primary-800: var(--color-primary-800);
$color-primary-900: var(--color-primary-900);

$color-secondary-050: var(--color-secondary-050);
$color-secondary-100: var(--color-secondary-100);
$color-secondary-200: var(--color-secondary-200);
$color-secondary-300: var(--color-secondary-300);
$color-secondary-400: var(--color-secondary-400);
$color-secondary-500: var(--color-secondary-500);
$color-secondary-600: var(--color-secondary-600);
$color-secondary-700: var(--color-secondary-700);
$color-secondary-800: var(--color-secondary-800);
$color-secondary-900: var(--color-secondary-900);

$color-tertiary-050: var(--color-tertiary-050);
$color-tertiary-100: var(--color-tertiary-100);
$color-tertiary-200: var(--color-tertiary-200);
$color-tertiary-300: var(--color-tertiary-300);
$color-tertiary-400: var(--color-tertiary-400);
$color-tertiary-500: var(--color-tertiary-500);
$color-tertiary-600: var(--color-tertiary-600);
$color-tertiary-700: var(--color-tertiary-700);
$color-tertiary-800: var(--color-tertiary-800);
$color-tertiary-900: var(--color-tertiary-900);

$color-success-050: var(--color-success-050);
$color-success-100: var(--color-success-100);
$color-success-200: var(--color-success-200);
$color-success-300: var(--color-success-300);
$color-success-400: var(--color-success-400);
$color-success-500: var(--color-success-500);
$color-success-600: var(--color-success-600);
$color-success-700: var(--color-success-700);
$color-success-800: var(--color-success-800);
$color-success-900: var(--color-success-900);

$color-warning-050: var(--color-warning-050);
$color-warning-100: var(--color-warning-100);
$color-warning-200: var(--color-warning-200);
$color-warning-300: var(--color-warning-300);
$color-warning-400: var(--color-warning-400);
$color-warning-500: var(--color-warning-500);
$color-warning-600: var(--color-warning-600);
$color-warning-700: var(--color-warning-700);
$color-warning-800: var(--color-warning-800);
$color-warning-900: var(--color-warning-900);

$color-danger-050: var(--color-danger-050);
$color-danger-100: var(--color-danger-100);
$color-danger-200: var(--color-danger-200);
$color-danger-300: var(--color-danger-300);
$color-danger-400: var(--color-danger-400);
$color-danger-500: var(--color-danger-500);
$color-danger-600: var(--color-danger-600);
$color-danger-700: var(--color-danger-700);
$color-danger-800: var(--color-danger-800);
$color-danger-900: var(--color-danger-900);

$color-teal: var(--color-teal);

$text-on-color-primary-050: var(--text-on-color-primary-050);
$text-on-color-primary-050--subdued: var(--text-on-color-primary-050--subdued);
$text-on-color-primary-050--vivid: var(--text-on-color-primary-050--vivid);
$text-on-color-primary-050--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-050--vivid-subdued--UNSAFE
);

$text-on-color-primary-100: var(--text-on-color-primary-100);
$text-on-color-primary-100--subdued: var(--text-on-color-primary-100--subdued);
$text-on-color-primary-100--vivid: var(--text-on-color-primary-100--vivid);
$text-on-color-primary-100--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-100--vivid-subdued--UNSAFE
);

$text-on-color-primary-200: var(--text-on-color-primary-200);
$text-on-color-primary-200--subdued: var(--text-on-color-primary-200--subdued);
$text-on-color-primary-200--vivid: var(--text-on-color-primary-200--vivid);
$text-on-color-primary-200--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-200--vivid-subdued--UNSAFE
);

$text-on-color-primary-300: var(--text-on-color-primary-300);
$text-on-color-primary-300--subdued--UNSAFE: var(
  --text-on-color-primary-300--subdued--UNSAFE
);
$text-on-color-primary-300--vivid: var(--text-on-color-primary-300--vivid);
$text-on-color-primary-300--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-300--vivid-subdued--UNSAFE
);

$text-on-color-primary-400: var(--text-on-color-primary-400);
$text-on-color-primary-400--subdued--UNSAFE: var(
  --text-on-color-primary-400--subdued--UNSAFE
);
$text-on-color-primary-400--vivid: var(--text-on-color-primary-400--vivid);
$text-on-color-primary-400--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-400--vivid-subdued--UNSAFE
);

$text-on-color-primary-500: var(--text-on-color-primary-500);
$text-on-color-primary-500--subdued--UNSAFE: var(
  --text-on-color-primary-500--subdued--UNSAFE
);
$text-on-color-primary-500--vivid: var(--text-on-color-primary-500--vivid);
$text-on-color-primary-500--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-500--vivid-subdued--UNSAFE
);

$text-on-color-primary-600: var(--text-on-color-primary-600);
$text-on-color-primary-600--subdued--UNSAFE: var(
  --text-on-color-primary-600--subdued--UNSAFE
);
$text-on-color-primary-600--vivid: var(--text-on-color-primary-600--vivid);
$text-on-color-primary-600--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-600--vivid-subdued--UNSAFE
);

$text-on-color-primary-700: var(--text-on-color-primary-700);
$text-on-color-primary-700--subdued: var(--text-on-color-primary-700--subdued);
$text-on-color-primary-700--vivid: var(--text-on-color-primary-700--vivid);
$text-on-color-primary-700--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-700--vivid-subdued--UNSAFE
);

$text-on-color-primary-800: var(--text-on-color-primary-800);
$text-on-color-primary-800--subdued: var(--text-on-color-primary-800--subdued);
$text-on-color-primary-800--vivid: var(--text-on-color-primary-800--vivid);
$text-on-color-primary-800--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-800--vivid-subdued--UNSAFE
);

$text-on-color-primary-900: var(--text-on-color-primary-900);
$text-on-color-primary-900--subdued: var(--text-on-color-primary-900--subdued);
$text-on-color-primary-900--vivid: var(--text-on-color-primary-900--vivid);
$text-on-color-primary-900--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-900--vivid-subdued--UNSAFE
);

$text-on-color-secondary-050: var(--text-on-color-secondary-050);
$text-on-color-secondary-050--subdued: var(--text-on-color-secondary-050--subdued);
$text-on-color-secondary-050--vivid: var(--text-on-color-secondary-050--vivid);
$text-on-color-secondary-050--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-050--vivid-subdued--UNSAFE
);

$text-on-color-secondary-100: var(--text-on-color-secondary-100);
$text-on-color-secondary-100--subdued: var(--text-on-color-secondary-100--subdued);
$text-on-color-secondary-100--vivid: var(--text-on-color-secondary-100--vivid);
$text-on-color-secondary-100--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-100--vivid-subdued--UNSAFE
);

$text-on-color-secondary-200: var(--text-on-color-secondary-200);
$text-on-color-secondary-200--subdued: var(--text-on-color-secondary-200--subdued);
$text-on-color-secondary-200--vivid: var(--text-on-color-secondary-200--vivid);
$text-on-color-secondary-200--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-200--vivid-subdued--UNSAFE
);

$text-on-color-secondary-300: var(--text-on-color-secondary-300);
$text-on-color-secondary-300--subdued--UNSAFE: var(
  --text-on-color-secondary-300--subdued--UNSAFE
);
$text-on-color-secondary-300--vivid: var(--text-on-color-secondary-300--vivid);
$text-on-color-secondary-300--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-300--vivid-subdued--UNSAFE
);

$text-on-color-secondary-400: var(--text-on-color-secondary-400);
$text-on-color-secondary-400--subdued--UNSAFE: var(
  --text-on-color-secondary-400--subdued--UNSAFE
);
$text-on-color-secondary-400--vivid: var(--text-on-color-secondary-400--vivid);
$text-on-color-secondary-400--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-400--vivid-subdued--UNSAFE
);

$text-on-color-secondary-500: var(--text-on-color-secondary-500);
$text-on-color-secondary-500--subdued--UNSAFE: var(
  --text-on-color-secondary-500--subdued--UNSAFE
);
$text-on-color-secondary-500--vivid: var(--text-on-color-secondary-500--vivid);
$text-on-color-secondary-500--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-500--vivid-subdued--UNSAFE
);

$text-on-color-secondary-600: var(--text-on-color-secondary-600);
$text-on-color-secondary-600--subdued--UNSAFE: var(
  --text-on-color-secondary-600--subdued--UNSAFE
);
$text-on-color-secondary-600--vivid: var(--text-on-color-secondary-600--vivid);
$text-on-color-secondary-600--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-600--vivid-subdued--UNSAFE
);

$text-on-color-secondary-700: var(--text-on-color-secondary-700);
$text-on-color-secondary-700--subdued: var(--text-on-color-secondary-700--subdued);
$text-on-color-secondary-700--vivid: var(--text-on-color-secondary-700--vivid);
$text-on-color-secondary-700--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-700--vivid-subdued--UNSAFE
);

$text-on-color-secondary-800: var(--text-on-color-secondary-800);
$text-on-color-secondary-800--subdued: var(--text-on-color-secondary-800--subdued);
$text-on-color-secondary-800--vivid: var(--text-on-color-secondary-800--vivid);
$text-on-color-secondary-800--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-800--vivid-subdued--UNSAFE
);

$text-on-color-secondary-900: var(--text-on-color-secondary-900);
$text-on-color-secondary-900--subdued: var(--text-on-color-secondary-900--subdued);
$text-on-color-secondary-900--vivid: var(--text-on-color-secondary-900--vivid);
$text-on-color-secondary-900--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-900--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-050: var(--text-on-color-tertiary-050);
$text-on-color-tertiary-050--subdued: var(--text-on-color-tertiary-050--subdued);
$text-on-color-tertiary-050--vivid: var(--text-on-color-tertiary-050--vivid);
$text-on-color-tertiary-050--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-050--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-100: var(--text-on-color-tertiary-100);
$text-on-color-tertiary-100--subdued: var(--text-on-color-tertiary-100--subdued);
$text-on-color-tertiary-100--vivid: var(--text-on-color-tertiary-100--vivid);
$text-on-color-tertiary-100--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-100--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-200: var(--text-on-color-tertiary-200);
$text-on-color-tertiary-200--subdued: var(--text-on-color-tertiary-200--subdued);
$text-on-color-tertiary-200--vivid: var(--text-on-color-tertiary-200--vivid);
$text-on-color-tertiary-200--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-200--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-300: var(--text-on-color-tertiary-300);
$text-on-color-tertiary-300--subdued--UNSAFE: var(
  --text-on-color-tertiary-300--subdued--UNSAFE
);
$text-on-color-tertiary-300--vivid: var(--text-on-color-tertiary-300--vivid);
$text-on-color-tertiary-300--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-300--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-400: var(--text-on-color-tertiary-400);
$text-on-color-tertiary-400--subdued--UNSAFE: var(
  --text-on-color-tertiary-400--subdued--UNSAFE
);
$text-on-color-tertiary-400--vivid: var(--text-on-color-tertiary-400--vivid);
$text-on-color-tertiary-400--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-400--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-500: var(--text-on-color-tertiary-500);
$text-on-color-tertiary-500--subdued--UNSAFE: var(
  --text-on-color-tertiary-500--subdued--UNSAFE
);
$text-on-color-tertiary-500--vivid: var(--text-on-color-tertiary-500--vivid);
$text-on-color-tertiary-500--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-500--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-600: var(--text-on-color-tertiary-600);
$text-on-color-tertiary-600--subdued--UNSAFE: var(
  --text-on-color-tertiary-600--subdued--UNSAFE
);
$text-on-color-tertiary-600--vivid: var(--text-on-color-tertiary-600--vivid);
$text-on-color-tertiary-600--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-600--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-700: var(--text-on-color-tertiary-700);
$text-on-color-tertiary-700--subdued: var(--text-on-color-tertiary-700--subdued);
$text-on-color-tertiary-700--vivid: var(--text-on-color-tertiary-700--vivid);
$text-on-color-tertiary-700--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-700--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-800: var(--text-on-color-tertiary-800);
$text-on-color-tertiary-800--subdued: var(--text-on-color-tertiary-800--subdued);
$text-on-color-tertiary-800--vivid: var(--text-on-color-tertiary-800--vivid);
$text-on-color-tertiary-800--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-800--vivid-subdued--UNSAFE
);

$text-on-color-tertiary-900: var(--text-on-color-tertiary-900);
$text-on-color-tertiary-900--subdued: var(--text-on-color-tertiary-900--subdued);
$text-on-color-tertiary-900--vivid: var(--text-on-color-tertiary-900--vivid);
$text-on-color-tertiary-900--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-900--vivid-subdued--UNSAFE
);

$text-on-color-success-050: var(--text-on-color-success-050);
$text-on-color-success-050--subdued: var(--text-on-color-success-050--subdued);
$text-on-color-success-050--vivid: var(--text-on-color-success-050--vivid);
$text-on-color-success-050--vivid-subdued--UNSAFE: var(
  --text-on-color-success-050--vivid-subdued--UNSAFE
);

$text-on-color-success-100: var(--text-on-color-success-100);
$text-on-color-success-100--subdued: var(--text-on-color-success-100--subdued);
$text-on-color-success-100--vivid: var(--text-on-color-success-100--vivid);
$text-on-color-success-100--vivid-subdued--UNSAFE: var(
  --text-on-color-success-100--vivid-subdued--UNSAFE
);

$text-on-color-success-200: var(--text-on-color-success-200);
$text-on-color-success-200--subdued: var(--text-on-color-success-200--subdued);
$text-on-color-success-200--vivid: var(--text-on-color-success-200--vivid);
$text-on-color-success-200--vivid-subdued--UNSAFE: var(
  --text-on-color-success-200--vivid-subdued--UNSAFE
);

$text-on-color-success-300: var(--text-on-color-success-300);
$text-on-color-success-300--subdued--UNSAFE: var(
  --text-on-color-success-300--subdued--UNSAFE
);
$text-on-color-success-300--vivid: var(--text-on-color-success-300--vivid);
$text-on-color-success-300--vivid-subdued--UNSAFE: var(
  --text-on-color-success-300--vivid-subdued--UNSAFE
);

$text-on-color-success-400: var(--text-on-color-success-400);
$text-on-color-success-400--subdued--UNSAFE: var(
  --text-on-color-success-400--subdued--UNSAFE
);
$text-on-color-success-400--vivid: var(--text-on-color-success-400--vivid);
$text-on-color-success-400--vivid-subdued--UNSAFE: var(
  --text-on-color-success-400--vivid-subdued--UNSAFE
);

$text-on-color-success-500: var(--text-on-color-success-500);
$text-on-color-success-500--subdued--UNSAFE: var(
  --text-on-color-success-500--subdued--UNSAFE
);
$text-on-color-success-500--vivid: var(--text-on-color-success-500--vivid);
$text-on-color-success-500--vivid-subdued--UNSAFE: var(
  --text-on-color-success-500--vivid-subdued--UNSAFE
);

$text-on-color-success-600: var(--text-on-color-success-600);
$text-on-color-success-600--subdued--UNSAFE: var(
  --text-on-color-success-600--subdued--UNSAFE
);
$text-on-color-success-600--vivid: var(--text-on-color-success-600--vivid);
$text-on-color-success-600--vivid-subdued--UNSAFE: var(
  --text-on-color-success-600--vivid-subdued--UNSAFE
);

$text-on-color-success-700: var(--text-on-color-success-700);
$text-on-color-success-700--subdued: var(--text-on-color-success-700--subdued);
$text-on-color-success-700--vivid: var(--text-on-color-success-700--vivid);
$text-on-color-success-700--vivid-subdued--UNSAFE: var(
  --text-on-color-success-700--vivid-subdued--UNSAFE
);

$text-on-color-success-800: var(--text-on-color-success-800);
$text-on-color-success-800--subdued: var(--text-on-color-success-800--subdued);
$text-on-color-success-800--vivid: var(--text-on-color-success-800--vivid);
$text-on-color-success-800--vivid-subdued--UNSAFE: var(
  --text-on-color-success-800--vivid-subdued--UNSAFE
);

$text-on-color-success-900: var(--text-on-color-success-900);
$text-on-color-success-900--subdued: var(--text-on-color-success-900--subdued);
$text-on-color-success-900--vivid: var(--text-on-color-success-900--vivid);
$text-on-color-success-900--vivid-subdued--UNSAFE: var(
  --text-on-color-success-900--vivid-subdued--UNSAFE
);

$text-on-color-warning-050: var(--text-on-color-warning-050);
$text-on-color-warning-050--subdued: var(--text-on-color-warning-050--subdued);
$text-on-color-warning-050--vivid: var(--text-on-color-warning-050--vivid);
$text-on-color-warning-050--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-050--vivid-subdued--UNSAFE
);

$text-on-color-warning-100: var(--text-on-color-warning-100);
$text-on-color-warning-100--subdued: var(--text-on-color-warning-100--subdued);
$text-on-color-warning-100--vivid: var(--text-on-color-warning-100--vivid);
$text-on-color-warning-100--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-100--vivid-subdued--UNSAFE
);

$text-on-color-warning-200: var(--text-on-color-warning-200);
$text-on-color-warning-200--subdued: var(--text-on-color-warning-200--subdued);
$text-on-color-warning-200--vivid: var(--text-on-color-warning-200--vivid);
$text-on-color-warning-200--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-200--vivid-subdued--UNSAFE
);

$text-on-color-warning-300: var(--text-on-color-warning-300);
$text-on-color-warning-300--subdued--UNSAFE: var(
  --text-on-color-warning-300--subdued--UNSAFE
);
$text-on-color-warning-300--vivid: var(--text-on-color-warning-300--vivid);
$text-on-color-warning-300--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-300--vivid-subdued--UNSAFE
);

$text-on-color-warning-400: var(--text-on-color-warning-400);
$text-on-color-warning-400--subdued--UNSAFE: var(
  --text-on-color-warning-400--subdued--UNSAFE
);
$text-on-color-warning-400--vivid: var(--text-on-color-warning-400--vivid);
$text-on-color-warning-400--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-400--vivid-subdued--UNSAFE
);

$text-on-color-warning-500: var(--text-on-color-warning-500);
$text-on-color-warning-500--subdued--UNSAFE: var(
  --text-on-color-warning-500--subdued--UNSAFE
);
$text-on-color-warning-500--vivid: var(--text-on-color-warning-500--vivid);
$text-on-color-warning-500--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-500--vivid-subdued--UNSAFE
);

$text-on-color-warning-600: var(--text-on-color-warning-600);
$text-on-color-warning-600--subdued--UNSAFE: var(
  --text-on-color-warning-600--subdued--UNSAFE
);
$text-on-color-warning-600--vivid: var(--text-on-color-warning-600--vivid);
$text-on-color-warning-600--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-600--vivid-subdued--UNSAFE
);

$text-on-color-warning-700: var(--text-on-color-warning-700);
$text-on-color-warning-700--subdued: var(--text-on-color-warning-700--subdued);
$text-on-color-warning-700--vivid: var(--text-on-color-warning-700--vivid);
$text-on-color-warning-700--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-700--vivid-subdued--UNSAFE
);

$text-on-color-warning-800: var(--text-on-color-warning-800);
$text-on-color-warning-800--subdued: var(--text-on-color-warning-800--subdued);
$text-on-color-warning-800--vivid: var(--text-on-color-warning-800--vivid);
$text-on-color-warning-800--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-800--vivid-subdued--UNSAFE
);

$text-on-color-warning-900: var(--text-on-color-warning-900);
$text-on-color-warning-900--subdued: var(--text-on-color-warning-900--subdued);
$text-on-color-warning-900--vivid: var(--text-on-color-warning-900--vivid);
$text-on-color-warning-900--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-900--vivid-subdued--UNSAFE
);

$text-on-color-danger-050: var(--text-on-color-danger-050);
$text-on-color-danger-050--subdued: var(--text-on-color-danger-050--subdued);
$text-on-color-danger-050--vivid: var(--text-on-color-danger-050--vivid);
$text-on-color-danger-050--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-050--vivid-subdued--UNSAFE
);

$text-on-color-danger-100: var(--text-on-color-danger-100);
$text-on-color-danger-100--subdued: var(--text-on-color-danger-100--subdued);
$text-on-color-danger-100--vivid: var(--text-on-color-danger-100--vivid);
$text-on-color-danger-100--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-100--vivid-subdued--UNSAFE
);

$text-on-color-danger-200: var(--text-on-color-danger-200);
$text-on-color-danger-200--subdued: var(--text-on-color-danger-200--subdued);
$text-on-color-danger-200--vivid: var(--text-on-color-danger-200--vivid);
$text-on-color-danger-200--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-200--vivid-subdued--UNSAFE
);

$text-on-color-danger-300: var(--text-on-color-danger-300);
$text-on-color-danger-300--subdued--UNSAFE: var(
  --text-on-color-danger-300--subdued--UNSAFE
);
$text-on-color-danger-300--vivid: var(--text-on-color-danger-300--vivid);
$text-on-color-danger-300--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-300--vivid-subdued--UNSAFE
);

$text-on-color-danger-400: var(--text-on-color-danger-400);
$text-on-color-danger-400--subdued--UNSAFE: var(
  --text-on-color-danger-400--subdued--UNSAFE
);
$text-on-color-danger-400--vivid: var(--text-on-color-danger-400--vivid);
$text-on-color-danger-400--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-400--vivid-subdued--UNSAFE
);

$text-on-color-danger-500: var(--text-on-color-danger-500);
$text-on-color-danger-500--subdued--UNSAFE: var(
  --text-on-color-danger-500--subdued--UNSAFE
);
$text-on-color-danger-500--vivid: var(--text-on-color-danger-500--vivid);
$text-on-color-danger-500--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-500--vivid-subdued--UNSAFE
);

$text-on-color-danger-600: var(--text-on-color-danger-600);
$text-on-color-danger-600--subdued--UNSAFE: var(
  --text-on-color-danger-600--subdued--UNSAFE
);
$text-on-color-danger-600--vivid: var(--text-on-color-danger-600--vivid);
$text-on-color-danger-600--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-600--vivid-subdued--UNSAFE
);

$text-on-color-danger-700: var(--text-on-color-danger-700);
$text-on-color-danger-700--subdued: var(--text-on-color-danger-700--subdued);
$text-on-color-danger-700--vivid: var(--text-on-color-danger-700--vivid);
$text-on-color-danger-700--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-700--vivid-subdued--UNSAFE
);

$text-on-color-danger-800: var(--text-on-color-danger-800);
$text-on-color-danger-800--subdued: var(--text-on-color-danger-800--subdued);
$text-on-color-danger-800--vivid: var(--text-on-color-danger-800--vivid);
$text-on-color-danger-800--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-800--vivid-subdued--UNSAFE
);

$text-on-color-danger-900: var(--text-on-color-danger-900);
$text-on-color-danger-900--subdued: var(--text-on-color-danger-900--subdued);
$text-on-color-danger-900--vivid: var(--text-on-color-danger-900--vivid);
$text-on-color-danger-900--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-900--vivid-subdued--UNSAFE
);

$text-on-color-white: var(--text-on-color-white);
$text-on-color-white--subdued: var(--text-on-color-white--subdued);
$text-on-color-white--primary: var(--text-on-color-white--primary);
$text-on-color-white--primary-subdued--UNSAFE: var(
  --text-on-color-white--primary-subdued--UNSAFE
);
$text-on-color-white--secondary: var(--text-on-color-white--secondary);
$text-on-color-white--secondary-subdued--UNSAFE: var(
  --text-on-color-white--secondary-subdued--UNSAFE
);
$text-on-color-white--tertiary: var(--text-on-color-white--tertiary);
$text-on-color-white--tertiary-subdued--UNSAFE: var(
  --text-on-color-white--tertiary-subdued--UNSAFE
);
$text-on-color-white--success: var(--text-on-color-white--success);
$text-on-color-white--success-subdued--UNSAFE: var(
  --text-on-color-white--success-subdued--UNSAFE
);
$text-on-color-white--warning: var(--text-on-color-white--warning);
$text-on-color-white--warning-subdued--UNSAFE: var(
  --text-on-color-white--warning-subdued--UNSAFE
);
$text-on-color-white--danger: var(--text-on-color-white--danger);
$text-on-color-white--danger-subdued--UNSAFE: var(
  --text-on-color-white--danger-subdued--UNSAFE
);

$text-on-color-grey-050: var(--text-on-color-grey-050);
$text-on-color-grey-050--subdued: var(--text-on-color-grey-050--subdued);
$text-on-color-grey-050--primary: var(--text-on-color-grey-050--primary);
$text-on-color-grey-050--primary-subdued--UNSAFE: var(
  --text-on-color-grey-050--primary-subdued--UNSAFE
);
$text-on-color-grey-050--secondary: var(--text-on-color-grey-050--secondary);
$text-on-color-grey-050--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-050--secondary-subdued--UNSAFE
);
$text-on-color-grey-050--tertiary: var(--text-on-color-grey-050--tertiary);
$text-on-color-grey-050--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-050--tertiary-subdued--UNSAFE
);
$text-on-color-grey-050--success: var(--text-on-color-grey-050--success);
$text-on-color-grey-050--success-subdued--UNSAFE: var(
  --text-on-color-grey-050--success-subdued--UNSAFE
);
$text-on-color-grey-050--warning: var(--text-on-color-grey-050--warning);
$text-on-color-grey-050--warning-subdued--UNSAFE: var(
  --text-on-color-grey-050--warning-subdued--UNSAFE
);
$text-on-color-grey-050--danger: var(--text-on-color-grey-050--danger);
$text-on-color-grey-050--danger-subdued--UNSAFE: var(
  --text-on-color-grey-050--danger-subdued--UNSAFE
);

$text-on-color-grey-100: var(--text-on-color-grey-100);
$text-on-color-grey-100--subdued: var(--text-on-color-grey-100--subdued);
$text-on-color-grey-100--primary: var(--text-on-color-grey-100--primary);
$text-on-color-grey-100--primary-subdued--UNSAFE: var(
  --text-on-color-grey-100--primary-subdued--UNSAFE
);
$text-on-color-grey-100--secondary: var(--text-on-color-grey-100--secondary);
$text-on-color-grey-100--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-100--secondary-subdued--UNSAFE
);
$text-on-color-grey-100--tertiary: var(--text-on-color-grey-100--tertiary);
$text-on-color-grey-100--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-100--tertiary-subdued--UNSAFE
);
$text-on-color-grey-100--success: var(--text-on-color-grey-100--success);
$text-on-color-grey-100--success-subdued--UNSAFE: var(
  --text-on-color-grey-100--success-subdued--UNSAFE
);
$text-on-color-grey-100--warning: var(--text-on-color-grey-100--warning);
$text-on-color-grey-100--warning-subdued--UNSAFE: var(
  --text-on-color-grey-100--warning-subdued--UNSAFE
);
$text-on-color-grey-100--danger: var(--text-on-color-grey-100--danger);
$text-on-color-grey-100--danger-subdued--UNSAFE: var(
  --text-on-color-grey-100--danger-subdued--UNSAFE
);

$text-on-color-grey-200: var(--text-on-color-grey-200);
$text-on-color-grey-200--subdued: var(--text-on-color-grey-200--subdued);
$text-on-color-grey-200--primary: var(--text-on-color-grey-200--primary);
$text-on-color-grey-200--primary-subdued--UNSAFE: var(
  --text-on-color-grey-200--primary-subdued--UNSAFE
);
$text-on-color-grey-200--secondary: var(--text-on-color-grey-200--secondary);
$text-on-color-grey-200--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-200--secondary-subdued--UNSAFE
);
$text-on-color-grey-200--tertiary: var(--text-on-color-grey-200--tertiary);
$text-on-color-grey-200--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-200--tertiary-subdued--UNSAFE
);
$text-on-color-grey-200--success: var(--text-on-color-grey-200--success);
$text-on-color-grey-200--success-subdued--UNSAFE: var(
  --text-on-color-grey-200--success-subdued--UNSAFE
);
$text-on-color-grey-200--warning: var(--text-on-color-grey-200--warning);
$text-on-color-grey-200--warning-subdued--UNSAFE: var(
  --text-on-color-grey-200--warning-subdued--UNSAFE
);
$text-on-color-grey-200--danger: var(--text-on-color-grey-200--danger);
$text-on-color-grey-200--danger-subdued--UNSAFE: var(
  --text-on-color-grey-200--danger-subdued--UNSAFE
);

$text-on-color-grey-300: var(--text-on-color-grey-300);
$text-on-color-grey-300--subdued--UNSAFE: var(--text-on-color-grey-300--subdued--UNSAFE);
$text-on-color-grey-300--primary: var(--text-on-color-grey-300--primary);
$text-on-color-grey-300--primary-subdued--UNSAFE: var(
  --text-on-color-grey-300--primary-subdued--UNSAFE
);
$text-on-color-grey-300--secondary: var(--text-on-color-grey-300--secondary);
$text-on-color-grey-300--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-300--secondary-subdued--UNSAFE
);
$text-on-color-grey-300--tertiary: var(--text-on-color-grey-300--tertiary);
$text-on-color-grey-300--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-300--tertiary-subdued--UNSAFE
);
$text-on-color-grey-300--success: var(--text-on-color-grey-300--success);
$text-on-color-grey-300--success-subdued--UNSAFE: var(
  --text-on-color-grey-300--success-subdued--UNSAFE
);
$text-on-color-grey-300--warning: var(--text-on-color-grey-300--warning);
$text-on-color-grey-300--warning-subdued--UNSAFE: var(
  --text-on-color-grey-300--warning-subdued--UNSAFE
);
$text-on-color-grey-300--danger: var(--text-on-color-grey-300--danger);
$text-on-color-grey-300--danger-subdued--UNSAFE: var(
  --text-on-color-grey-300--danger-subdued--UNSAFE
);

$text-on-color-grey-400: var(--text-on-color-grey-400);
$text-on-color-grey-400--subdued--UNSAFE: var(--text-on-color-grey-400--subdued--UNSAFE);
$text-on-color-grey-400--primary: var(--text-on-color-grey-400--primary);
$text-on-color-grey-400--primary-subdued--UNSAFE: var(
  --text-on-color-grey-400--primary-subdued--UNSAFE
);
$text-on-color-grey-400--secondary: var(--text-on-color-grey-400--secondary);
$text-on-color-grey-400--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-400--secondary-subdued--UNSAFE
);
$text-on-color-grey-400--tertiary: var(--text-on-color-grey-400--tertiary);
$text-on-color-grey-400--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-400--tertiary-subdued--UNSAFE
);
$text-on-color-grey-400--success: var(--text-on-color-grey-400--success);
$text-on-color-grey-400--success-subdued--UNSAFE: var(
  --text-on-color-grey-400--success-subdued--UNSAFE
);
$text-on-color-grey-400--warning: var(--text-on-color-grey-400--warning);
$text-on-color-grey-400--warning-subdued--UNSAFE: var(
  --text-on-color-grey-400--warning-subdued--UNSAFE
);
$text-on-color-grey-400--danger: var(--text-on-color-grey-400--danger);
$text-on-color-grey-400--danger-subdued--UNSAFE: var(
  --text-on-color-grey-400--danger-subdued--UNSAFE
);

$text-on-color-grey-500: var(--text-on-color-grey-500);
$text-on-color-grey-500--subdued--UNSAFE: var(--text-on-color-grey-500--subdued--UNSAFE);
$text-on-color-grey-500--primary: var(--text-on-color-grey-500--primary);
$text-on-color-grey-500--primary-subdued--UNSAFE: var(
  --text-on-color-grey-500--primary-subdued--UNSAFE
);
$text-on-color-grey-500--secondary: var(--text-on-color-grey-500--secondary);
$text-on-color-grey-500--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-500--secondary-subdued--UNSAFE
);
$text-on-color-grey-500--tertiary: var(--text-on-color-grey-500--tertiary);
$text-on-color-grey-500--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-500--tertiary-subdued--UNSAFE
);
$text-on-color-grey-500--success: var(--text-on-color-grey-500--success);
$text-on-color-grey-500--success-subdued--UNSAFE: var(
  --text-on-color-grey-500--success-subdued--UNSAFE
);
$text-on-color-grey-500--warning: var(--text-on-color-grey-500--warning);
$text-on-color-grey-500--warning-subdued--UNSAFE: var(
  --text-on-color-grey-500--warning-subdued--UNSAFE
);
$text-on-color-grey-500--danger: var(--text-on-color-grey-500--danger);
$text-on-color-grey-500--danger-subdued--UNSAFE: var(
  --text-on-color-grey-500--danger-subdued--UNSAFE
);

$text-on-color-grey-600: var(--text-on-color-grey-600);
$text-on-color-grey-600--subdued--UNSAFE: var(--text-on-color-grey-600--subdued--UNSAFE);
$text-on-color-grey-600--primary: var(--text-on-color-grey-600--primary);
$text-on-color-grey-600--primary-subdued--UNSAFE: var(
  --text-on-color-grey-600--primary-subdued--UNSAFE
);
$text-on-color-grey-600--secondary: var(--text-on-color-grey-600--secondary);
$text-on-color-grey-600--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-600--secondary-subdued--UNSAFE
);
$text-on-color-grey-600--tertiary: var(--text-on-color-grey-600--tertiary);
$text-on-color-grey-600--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-600--tertiary-subdued--UNSAFE
);
$text-on-color-grey-600--success: var(--text-on-color-grey-600--success);
$text-on-color-grey-600--success-subdued--UNSAFE: var(
  --text-on-color-grey-600--success-subdued--UNSAFE
);
$text-on-color-grey-600--warning: var(--text-on-color-grey-600--warning);
$text-on-color-grey-600--warning-subdued--UNSAFE: var(
  --text-on-color-grey-600--warning-subdued--UNSAFE
);
$text-on-color-grey-600--danger: var(--text-on-color-grey-600--danger);
$text-on-color-grey-600--danger-subdued--UNSAFE: var(
  --text-on-color-grey-600--danger-subdued--UNSAFE
);

$text-on-color-grey-700: var(--text-on-color-grey-700);
$text-on-color-grey-700--subdued: var(--text-on-color-grey-700--subdued);
$text-on-color-grey-700--primary: var(--text-on-color-grey-700--primary);
$text-on-color-grey-700--primary-subdued--UNSAFE: var(
  --text-on-color-grey-700--primary-subdued--UNSAFE
);
$text-on-color-grey-700--secondary: var(--text-on-color-grey-700--secondary);
$text-on-color-grey-700--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-700--secondary-subdued--UNSAFE
);
$text-on-color-grey-700--tertiary: var(--text-on-color-grey-700--tertiary);
$text-on-color-grey-700--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-700--tertiary-subdued--UNSAFE
);
$text-on-color-grey-700--success: var(--text-on-color-grey-700--success);
$text-on-color-grey-700--success-subdued--UNSAFE: var(
  --text-on-color-grey-700--success-subdued--UNSAFE
);
$text-on-color-grey-700--warning: var(--text-on-color-grey-700--warning);
$text-on-color-grey-700--warning-subdued--UNSAFE: var(
  --text-on-color-grey-700--warning-subdued--UNSAFE
);
$text-on-color-grey-700--danger: var(--text-on-color-grey-700--danger);
$text-on-color-grey-700--danger-subdued--UNSAFE: var(
  --text-on-color-grey-700--danger-subdued--UNSAFE
);

$text-on-color-grey-800: var(--text-on-color-grey-800);
$text-on-color-grey-800--subdued: var(--text-on-color-grey-800--subdued);
$text-on-color-grey-800--primary: var(--text-on-color-grey-800--primary);
$text-on-color-grey-800--primary-subdued--UNSAFE: var(
  --text-on-color-grey-800--primary-subdued--UNSAFE
);
$text-on-color-grey-800--secondary: var(--text-on-color-grey-800--secondary);
$text-on-color-grey-800--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-800--secondary-subdued--UNSAFE
);
$text-on-color-grey-800--tertiary: var(--text-on-color-grey-800--tertiary);
$text-on-color-grey-800--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-800--tertiary-subdued--UNSAFE
);
$text-on-color-grey-800--success: var(--text-on-color-grey-800--success);
$text-on-color-grey-800--success-subdued--UNSAFE: var(
  --text-on-color-grey-800--success-subdued--UNSAFE
);
$text-on-color-grey-800--warning: var(--text-on-color-grey-800--warning);
$text-on-color-grey-800--warning-subdued--UNSAFE: var(
  --text-on-color-grey-800--warning-subdued--UNSAFE
);
$text-on-color-grey-800--danger: var(--text-on-color-grey-800--danger);
$text-on-color-grey-800--danger-subdued--UNSAFE: var(
  --text-on-color-grey-800--danger-subdued--UNSAFE
);

$text-on-color-grey-900: var(--text-on-color-grey-900);
$text-on-color-grey-900--subdued: var(--text-on-color-grey-900--subdued);
$text-on-color-grey-900--primary: var(--text-on-color-grey-900--primary);
$text-on-color-grey-900--primary-subdued--UNSAFE: var(
  --text-on-color-grey-900--primary-subdued--UNSAFE
);
$text-on-color-grey-900--secondary: var(--text-on-color-grey-900--secondary);
$text-on-color-grey-900--secondary-subdued--UNSAFE: var(
  --text-on-color-grey-900--secondary-subdued--UNSAFE
);
$text-on-color-grey-900--tertiary: var(--text-on-color-grey-900--tertiary);
$text-on-color-grey-900--tertiary-subdued--UNSAFE: var(
  --text-on-color-grey-900--tertiary-subdued--UNSAFE
);
$text-on-color-grey-900--success: var(--text-on-color-grey-900--success);
$text-on-color-grey-900--success-subdued--UNSAFE: var(
  --text-on-color-grey-900--success-subdued--UNSAFE
);
$text-on-color-grey-900--warning: var(--text-on-color-grey-900--warning);
$text-on-color-grey-900--warning-subdued--UNSAFE: var(
  --text-on-color-grey-900--warning-subdued--UNSAFE
);
$text-on-color-grey-900--danger: var(--text-on-color-grey-900--danger);
$text-on-color-grey-900--danger-subdued--UNSAFE: var(
  --text-on-color-grey-900--danger-subdued--UNSAFE
);

$color-primary-lighter: var(--color-primary-lighter);
$color-primary: var(--color-primary);
$color-primary-darker: var(--color-primary-darker);
$text-on-color-primary-lighter: var(--text-on-color-primary-lighter);
$text-on-color-primary-lighter--subdued--UNSAFE: var(
  --text-on-color-primary-lighter--subdued--UNSAFE
);
$text-on-color-primary-lighter--vivid: var(--text-on-color-primary-lighter--vivid);
$text-on-color-primary-lighter--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-lighter--vivid-subdued--UNSAFE
);
$text-on-color-primary: var(--text-on-color-primary);
$text-on-color-primary--subdued--UNSAFE: var(--text-on-color-primary--subdued--UNSAFE);
$text-on-color-primary--vivid: var(--text-on-color-primary--vivid);
$text-on-color-primary--vivid-subdued--UNSAFE: var(
  --text-on-color-primary--vivid-subdued--UNSAFE
);
$text-on-color-primary-darker: var(--text-on-color-primary-darker);
$text-on-color-primary-darker--subdued--UNSAFE: var(
  --text-on-color-primary-darker--subdued--UNSAFE
);
$text-on-color-primary-darker--vivid: var(--text-on-color-primary-darker--vivid);
$text-on-color-primary-darker--vivid-subdued--UNSAFE: var(
  --text-on-color-primary-darker--vivid-subdued--UNSAFE
);

$color-secondary-lighter: var(--color-secondary-lighter);
$color-secondary: var(--color-secondary);
$color-secondary-darker: var(--color-secondary-darker);
$text-on-color-secondary-lighter: var(--text-on-color-secondary-lighter);
$text-on-color-secondary-lighter--subdued--UNSAFE: var(
  --text-on-color-secondary-lighter--subdued--UNSAFE
);
$text-on-color-secondary-lighter--vivid: var(--text-on-color-secondary-lighter--vivid);
$text-on-color-secondary-lighter--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-lighter--vivid-subdued--UNSAFE
);

$text-on-color-secondary: var(--text-on-color-secondary);
$text-on-color-secondary--subdued--UNSAFE: var(--text-on-color-secondary--subdued--UNSAFE);
$text-on-color-secondary--vivid: var(--text-on-color-secondary--vivid);
$text-on-color-secondary--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary--vivid-subdued--UNSAFE
);
$text-on-color-secondary-darker: var(--text-on-color-secondary-darker);
$text-on-color-secondary-darker--subdued--UNSAFE: var(
  --text-on-color-secondary-darker--subdued--UNSAFE
);
$text-on-color-secondary-darker--vivid: var(--text-on-color-secondary-darker--vivid);
$text-on-color-secondary-darker--vivid-subdued--UNSAFE: var(
  --text-on-color-secondary-darker--vivid-subdued--UNSAFE
);

$color-tertiary-lighter: var(--color-tertiary-lighter);
$color-tertiary: var(--color-tertiary);
$color-tertiary-darker: var(--color-tertiary-darker);
$text-on-color-tertiary-lighter: var(--text-on-color-tertiary-lighter);
$text-on-color-tertiary-lighter--subdued--UNSAFE: var(
  --text-on-color-tertiary-lighter--subdued--UNSAFE
);
$text-on-color-tertiary-lighter--vivid: var(--text-on-color-tertiary-lighter--vivid);
$text-on-color-tertiary-lighter--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-lighter--vivid-subdued--UNSAFE
);
$text-on-color-tertiary: var(--text-on-color-tertiary);
$text-on-color-tertiary--subdued--UNSAFE: var(--text-on-color-tertiary--subdued--UNSAFE);
$text-on-color-tertiary--vivid: var(--text-on-color-tertiary--vivid);
$text-on-color-tertiary--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary--vivid-subdued--UNSAFE
);
$text-on-color-tertiary-darker: var(--text-on-color-tertiary-darker);
$text-on-color-tertiary-darker--subdued--UNSAFE: var(
  --text-on-color-tertiary-darker--subdued--UNSAFE
);
$text-on-color-tertiary-darker--vivid: var(--text-on-color-tertiary-darker--vivid);
$text-on-color-tertiary-darker--vivid-subdued--UNSAFE: var(
  --text-on-color-tertiary-darker--vivid-subdued--UNSAFE
);

$color-success-lighter: var(--color-success-lighter);
$color-success: var(--color-success);
$color-success-darker: var(--color-success-darker);
$text-on-color-success-lighter: var(--text-on-color-success-lighter);
$text-on-color-success-lighter--subdued--UNSAFE: var(
  --text-on-color-success-lighter--subdued--UNSAFE
);
$text-on-color-success-lighter--vivid: var(--text-on-color-success-lighter--vivid);
$text-on-color-success-lighter--vivid-subdued--UNSAFE: var(
  --text-on-color-success-lighter--vivid-subdued--UNSAFE
);
$text-on-color-success: var(--text-on-color-success);
$text-on-color-success--subdued--UNSAFE: var(--text-on-color-success--subdued--UNSAFE);
$text-on-color-success--vivid: var(--text-on-color-success--vivid);
$text-on-color-success--vivid-subdued--UNSAFE: var(
  --text-on-color-success--vivid-subdued--UNSAFE
);
$text-on-color-success-darker: var(--text-on-color-success-darker);
$text-on-color-success-darker--subdued--UNSAFE: var(
  --text-on-color-success-darker--subdued--UNSAFE
);
$text-on-color-success-darker--vivid: var(--text-on-color-success-darker--vivid);
$text-on-color-success-darker--vivid-subdued--UNSAFE: var(
  --text-on-color-success-darker--vivid-subdued--UNSAFE
);

$color-warning-lighter: var(--color-warning-lighter);
$color-warning: var(--color-warning);
$color-warning-darker: var(--color-warning-darker);
$text-on-color-warning-lighter: var(--text-on-color-warning-lighter);
$text-on-color-warning-lighter--subdued--UNSAFE: var(
  --text-on-color-warning-lighter--subdued--UNSAFE
);
$text-on-color-warning-lighter--vivid: var(--text-on-color-warning-lighter--vivid);
$text-on-color-warning-lighter--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-lighter--vivid-subdued--UNSAFE
);
$text-on-color-warning: var(--text-on-color-warning);
$text-on-color-warning--subdued--UNSAFE: var(--text-on-color-warning--subdued--UNSAFE);
$text-on-color-warning--vivid: var(--text-on-color-warning--vivid);
$text-on-color-warning--vivid-subdued--UNSAFE: var(
  --text-on-color-warning--vivid-subdued--UNSAFE
);
$text-on-color-warning-darker: var(--text-on-color-warning-darker);
$text-on-color-warning-darker--subdued--UNSAFE: var(
  --text-on-color-warning-darker--subdued--UNSAFE
);
$text-on-color-warning-darker--vivid: var(--text-on-color-warning-darker--vivid);
$text-on-color-warning-darker--vivid-subdued--UNSAFE: var(
  --text-on-color-warning-darker--vivid-subdued--UNSAFE
);

$color-danger-lighter: var(--color-danger-lighter);
$color-danger: var(--color-danger);
$color-danger-darker: var(--color-danger-darker);
$text-on-color-danger-lighter: var(--text-on-color-danger-lighter);
$text-on-color-danger-lighter--subdued--UNSAFE: var(
  --text-on-color-danger-lighter--subdued--UNSAFE
);
$text-on-color-danger-lighter--vivid: var(--text-on-color-danger-lighter--vivid);
$text-on-color-danger-lighter--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-lighter--vivid-subdued--UNSAFE
);
$text-on-color-danger: var(--text-on-color-danger);
$text-on-color-danger--subdued--UNSAFE: var(--text-on-color-danger--subdued--UNSAFE);
$text-on-color-danger--vivid: var(--text-on-color-danger--vivid);
$text-on-color-danger--vivid-subdued--UNSAFE: var(
  --text-on-color-danger--vivid-subdued--UNSAFE
);
$text-on-color-danger-darker: var(--text-on-color-danger-darker);
$text-on-color-danger-darker--subdued--UNSAFE: var(
  --text-on-color-danger-darker--subdued--UNSAFE
);
$text-on-color-danger-darker--vivid: var(--text-on-color-danger-darker--vivid);
$text-on-color-danger-darker--vivid-subdued--UNSAFE: var(
  --text-on-color-danger-darker--vivid-subdued--UNSAFE
);

$pixels-1: var(--pixels-1);
$pixels-2: var(--pixels-2);
$pixels-3: var(--pixels-3);
$pixels-4: var(--pixels-4);
$pixels-5: var(--pixels-5);
$pixels-6: var(--pixels-6);
$pixels-7: var(--pixels-7);
$pixels-8: var(--pixels-8);

$text-size-xs: var(--text-size-xs);
$text-size-vs: var(--text-size-vs);
$text-size-s: var(--text-size-s);
$text-size-sm: var(--text-size-sm);
$text-size-m: var(--text-size-m);
$text-size-ml: var(--text-size-ml);
$text-size-l: var(--text-size-l);
$text-size-lg: var(--text-size-lg);
$text-size-xl: var(--text-size-xl);
$text-size-xxl: var(--text-size-xxl);
$text-size-xxxl: var(--text-size-xxxl);
$text-size-3xl: var(--text-size-3xl);
$text-size-4xl: var(--text-size-4xl);
$text-size-5xl: var(--text-size-5xl);

$line-height-xs: var(--line-height-xs);
$line-height-s: var(--line-height-s);
$line-height-m: var(--line-height-m);
$line-height-l: var(--line-height-l);
$line-height-xl: var(--line-height-xl);
$line-height-xxl: var(--line-height-xxl);
$line-height-xxxl: var(--line-height-xxxl);

$border-radius-xs: var(--border-radius-xs);
$border-radius-s: var(--border-radius-s);
$border-radius-m: var(--border-radius-m);
$border-radius-l: var(--border-radius-l);
$border-radius-xl: var(--border-radius-xl);
$border-radius-2xl: var(--border-radius-2xl);

$text-weight-bold: var(--text-weight-bold);
$text-weight-semibold: var(--text-weight-semibold);
$text-weight-medium: var(--text-weight-medium);
$text-weight-normal: var(--text-weight-normal);
$text-weight-light: var(--text-weight-light);

$transition-150: var(--transition-150);
$transition-300: var(--transition-300);

$focus-ring: var(--focus-ring);
$focus-ring-inset: var(--focus-ring-inset);
$focus-ring-warning: var(--focus-ring-warning);
$focus-ring-inset-warning: var(--focus-ring-inset-warning);
$focus-ring-success: var(--focus-ring-success);
$focus-ring-inset-success: var(--focus-ring-inset-success);

$box-shadow-100: var(--box-shadow-100);
$box-shadow-100-dark: var(--box-shadow-100-dark);
$box-shadow-100-focus: var(--box-shadow-100-focus);
$box-shadow-200: var(--box-shadow-200);
$box-shadow-200-dark: var(--box-shadow-200-dark);
$box-shadow-200-focus: var(--box-shadow-200-focus);
$box-shadow-300: var(--box-shadow-300);
$box-shadow-300-dark: var(--box-shadow-300-dark);
$box-shadow-300-focus: var(--box-shadow-300-focus);
$box-shadow-400: var(--box-shadow-400);
$box-shadow-400-dark: var(--box-shadow-400-dark);
$box-shadow-400-focus: var(--box-shadow-400-focus);
$box-shadow-500: var(--box-shadow-500);
$box-shadow-500-dark: var(--box-shadow-500-dark);
$box-shadow-500-focus: var(--box-shadow-500-focus);
$box-shadow-600: var(--box-shadow-600);
$box-shadow-600-dark: var(--box-shadow-600-dark);
$box-shadow-600-focus: var(--box-shadow-600-focus);
$box-shadow-100-border: var(--box-shadow-100-border);
$box-shadow-500-shift-up: var(--box-shadow-500-shift-up);
$box-shadow-long: var(--box-shadow-long);
$box-shadow-tooltip: var(--box-shadow-tooltip);
$box-inset-ol: var(--box-inset-ol);

$border-width-thin: var(--border-width-thin);
$border-width-default: var(--border-width-default);
