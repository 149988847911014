@import 'src/tokens';
@import 'src/mixins';

.CCModal {
  width: 60%;
  &__stepper {
    max-height: 13rem;
    position: relative;
    &-button {
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      border: $border-width-thin solid $color-grey-100;
      height: 100%;
      left: 39%;
      top: 0;
      z-index: -1;
    }
  }
}
