@import 'src/tokens';
@import 'src/mixins';

.ExpansionHeader {
  &__content {
    left: 0;
  }
  #tw & {
    .ExpansionHeader__content {
      right: 3.25rem;
    }
    .fa-2x {
      font-size: $text-size-4xl;
      margin-right: 0.35rem;
    }
    .OrderLine__name-icon,
    .OrderLine__name-icon > div {
      min-width: 45ch;
    }
    .truncate {
      max-width: 33ch;
      min-width: 26ch;
    }

    @media (min-width: 861px) and (max-width: 1028px) {
      .OrderLine__name-icon,
      .OrderLine__name-icon > div {
        min-width: 30ch;
      }
      .truncate {
        max-width: 22ch;
        min-width: 11ch;
      }
    }
  }
  .OrderLine__icon-group {
    max-width: 78%;
    .Button.Button--m-size {
      gap: 0;
      padding: 0 0.95rem 0 0.65rem;
      height: auto;
    }
  }
  .OrderLine__icons {
    display: flex;
    &-no-text {
      display: flex;
      gap: 0.5rem;
      #tw & {
        > div,
        > button {
          max-height: 1.85rem;
          max-width: 1.85rem;
          min-height: 1.85rem;
          min-width: 1.85rem;
          padding-inline: 0;
        }
      }
    }
  }
  .OrderLine__name-icon {
    flex: 1;
    min-width: 20%;
    h4 {
      flex: 1;
      max-width: 40ch;
      min-width: auto;
    }
  }
  .CollapseButton {
    margin-right: 0;
    min-height: 3.55rem;
    padding-right: 0.65rem;
    width: 100%;
  }
  .OrderLine__icons-with-text {
    [data-tooltip][aria-label]::after,
    [data-tooltip][aria-label]::before {
      display: none;
    }
  }
  .CollapseButton__icon {
    right: -5px;
    top: 0;
  }
  #tw & {
    .fa {
      padding: 0;
    }
  }

  @media screen and (max-width: 860px) {
    .CollapseButton {
      min-height: 5.25rem;
    }
    .CollapseButton__icon {
      top: 1.5rem;
    }
    #tw & .ExpansionHeader__content {
      gap: 0.5rem;
    }
  }

  @media screen and (max-width: 1280px) {
    .OrderLine__icons-with-text {
      [data-tooltip][aria-label]::after,
      [data-tooltip][aria-label]::before {
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .Button__text {
        display: none;
      }
      #tw & {
        column-gap: 1vw;
      }
    }
  }
}

#tw {
  .TableCell .CollapseButton__expanded {
    padding: 0;
    justify-content: center;
  }
  .CollapseButton__expanded .CollapseButton__icon {
    right: 0.5rem;
    .fa:not(.fa-xmark) {
      color: $color-secondary-400;
    }
    &.isCollapsed[aria-label] {
      &::after {
        right: 1.67rem;
      }
      &::before {
        left: -5.8rem;
        min-width: 5rem;
        bottom: 12px;
        width: 3rem;
      }
    }
    &.isExpanded[aria-label] {
      right: 0.725rem;
      .fa {
        color: $color-primary;
      }
      &::before {
        min-width: 7rem;
        width: 7rem;
      }
    }
  }
}

@media screen and (min-width: 861px) {
  #tw .CampaignContainer .ExpansionHeader__content .truncate {
    max-width: 42ch;
  }
}
